import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { AGE_CENSORS } from '../../api/api-constants'

class Poster extends React.Component {
    constructor() {
        super();
        this.state = { showFullScreenPoster: false }
        this.toggleFullScreen = this.toggleFullScreen.bind(this);
    }

    toggleFullScreen() {
        if (this.state.showFullScreenPoster) this.setState({ showFullScreenPoster: false });
        else this.setState({ showFullScreenPoster: true });
    }

    render() {
        const { movie, config } = this.props;
        const { showFullScreenPoster } = this.state;
        const posters = movie.posters;
        const posterBanners = config.posterBanners;

        const threeDBanner = !showFullScreenPoster && posterBanners.show3D && movie.is3D ? <div className="poster-banner threeD-banner">3D</div> : null;
        const atmosBanner = !showFullScreenPoster && posterBanners.showAtmos && movie.isAtmos ? <div className="poster-banner atmos-banner">ATMOS</div> : null;
        const ageCensor = movie.ageCensoring ? _.find(config.ageCensorIcons, (icon) => { return movie.ageCensoring.includes(icon.id) }) : null;
        const ageBanner = !showFullScreenPoster && ageCensor ? <div className="poster-banner age-banner"><img className={AGE_CENSORS[ageCensor.id]} src={ageCensor.image} /></div> : null;

        const poster = posters.large || posters.small;
        const fullScreenPoster = posters.hd || posters.large;
        const image = poster ? <img src={fullScreenPoster && showFullScreenPoster ? posters.hd : poster} onClick={this.toggleFullScreen} /> : null;
        const posterClasses = fullScreenPoster && showFullScreenPoster ? 'Poster fullscreen' : 'Poster';

        return (
            <div className={posterClasses} onClick={this.toggleFullScreen}>
                {image}
                {threeDBanner}
                {atmosBanner}
                {ageBanner}
            </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;

    return {
        config
    }
}

export default connect(mapStateToProps)(Poster)