import './topbar.scss'
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Poster from '../../movie/Poster'
import { ORGANIZER_TYPES } from '../../../api/api-constants'
import CONSTANTS from '../../movielists/movielist-constants'
import LOCALE from './TopBarLocale'
import ShowtimeType from '../../showtime/ShowtimeType'

class TopBar extends React.Component {

    generateDuration() {
        const { movie, showtime, appLocale } = this.props;
        const from = moment(showtime.dateTime);
        const duration = movie.type == CONSTANTS.types.special ? moment.duration(movie.length) : moment.duration(showtime.duration);
        const to = moment(from).add(duration);
        return <div className="info duration">
            <label>{LOCALE[appLocale].duration}:</label><div className="label">{from.format('HH:mm')} {LOCALE[appLocale].durationTo} {to.format('HH:mm')}</div>
        </div>
    }

    render() {
        const { organizer, movie, showtime, clubCardEnabled, pageHeaderEnabled, appLocale } = this.props;
        const poster = movie.posters ? <Poster movie={movie} /> : null;
        const duration = showtime.duration ? this.generateDuration() : null;
        const age = movie.ageCensoring ? <div className="info age"><label>{LOCALE[appLocale].age}:</label><div className="label">{movie.ageCensoring}</div> </div> : null;
        const dateTime = moment(showtime.dateTime);

        let topBarClasses = 'TopBar';
        if (clubCardEnabled) topBarClasses += ' club-enabled'
        if (pageHeaderEnabled) topBarClasses += ' header-enabled';

        const movieNameLocale = movie.names[appLocale] ? movie.names[appLocale] : movie.names['da'];
        const typeName = showtime.type ? <h2><ShowtimeType type={showtime.type} includeName={true} /></h2> : null;

        return (
            <div className={topBarClasses}>
                {poster}
                <div className="TicketInfo">
                    <h1>{movieNameLocale}</h1>
                    <h2>{_.capitalize(dateTime.format(LOCALE[appLocale].longDateTimeFormat))}</h2>
                    <div className="info"><label>{organizer.configuration.type == ORGANIZER_TYPES.CIRCUS ? LOCALE[appLocale].circus : LOCALE[appLocale].cinema}:</label><div className="label">{`${organizer.name}, ${showtime.locationName}`}</div></div>
                    {age}
                    {duration}

                    {typeName}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        showtimeTypes: state.movielist.showtimeTypes
    }
}

export default connect(mapStateToProps)(TopBar)