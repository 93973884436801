import './datepicker.scss'
import './daypicker.scss'

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { SITE_TYPES } from '../../api/api-constants'
import LanguagePicker from '../languagepicker/LanguagePicker';
import DayNavigator from './DayNavigator'
import WeekDayPicker from './WeekDayPicker'
import Calendar from './Calendar'

class DatePickerContainer extends React.Component {
    constructor() {
        super();
        this.state = { showCalendar: false }
        this.toggleCalendar = this.toggleCalendar.bind(this);
        this.changeDate = this.changeDate.bind(this);
    }

    toggleCalendar() {
        const showCalendar = this.state.showCalendar ? false : true;
        this.setState({ showCalendar });
    }

    changeDate(e) {
        const date = e.currentTarget.id;
        const { showCalendar } = this.state;
        const { changeDate } = this.props;
        if (showCalendar) this.toggleCalendar();
        changeDate(date);
    }

    render() {
        const { showAsWeekButtons, hasListHeader, isAllMovies, datesWithShowtimes, selectedDate, initialDate, appLocale, availableLocales, changeDate, calendarText } = this.props;
        const { showCalendar } = this.state;

        let datePickerClasses = showCalendar ? 'DatePicker calendar-open' : 'DatePicker';
        if (showAsWeekButtons) datePickerClasses += ' show-as-week-buttons';
        if (hasListHeader) datePickerClasses += ' list-header-active';

        return (
            <div className={datePickerClasses}>
                {!showAsWeekButtons && availableLocales.length > 1 ? <span className="icons-placeholder"></span> : null}

                {!showAsWeekButtons ?
                    <DayNavigator
                        appLocale={appLocale}
                        initialDate={initialDate}
                        selectedDate={selectedDate}
                        datesWithShowtimes={datesWithShowtimes}
                        showCalendar={showCalendar}
                        toggleCalendar={this.toggleCalendar}
                        changeDate={changeDate}
                    /> : null}

                {showAsWeekButtons ? <WeekDayPicker
                    appLocale={appLocale}
                    datesWithShowtimes={datesWithShowtimes}
                    selectedDate={selectedDate}
                    changeDate={this.changeDate}
                    isAllMovies={isAllMovies}
                /> : null}

                {availableLocales.length > 1 ? <LanguagePicker /> : null}

                <div className="more-dates">
                    {showAsWeekButtons ? <div className="day" onClick={this.toggleCalendar}><span className="icon-calendar"></span> Flere datoer</div> : null}

                    <Calendar
                        appLocale={appLocale}
                        initialDate={initialDate}
                        selectedDate={selectedDate}
                        datesWithShowtimes={datesWithShowtimes}
                        showCalendar={showCalendar}
                        toggleCalendar={this.toggleCalendar}
                        changeDate={changeDate}
                        calendarText={calendarText}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;
    const siteType = state.app.siteType;
    return {
        organizerId: state.organizer.id,
        initialDate: state.movielist.initialDate,
        selectedDate: state.movielist.selectedDate,
        datesWithShowtimes: state.movielist.datesWithShowtimes,
        appLocale: state.app.locale,
        availableLocales: config.availableLocales,
        calendarText: config.calendarText,
        siteType,
        showAsWeekButtons: config.movielists.date.showAsWeekButtons || ownProps.isOneColumn && siteType == SITE_TYPES.UMBRACO
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, {}), dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatePickerContainer))