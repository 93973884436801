export default {
    da: {
        pageHeader: 'Dine Nyhedsbreve',
        info: 'Tilmeld eller afmeld ',
        email: 'Email',
        find: 'Søg',
        invalidInput: 'Ugyldig email',
        subscribed: 'Tilmeldt',
        unsubscribed: 'Frameldt',
    },

    en: {
        pageHeader: 'Your Newsletters',
        info: 'Subscribe or unsubscribe ',
        email: 'Email',
        find: 'Search',
        invalidInput: 'Invalid email'
    }
}