import OrderFlowLocale from '../OrderFlowLocale'
import SummaryLocale from '../summary/SummaryLocale'

export default {
    da: {
        ...OrderFlowLocale.da,
        ...SummaryLocale.da,
        title: 'Gavekort',
        info: 'Du kan indtaste flere kuponnumre, når du har tastet det første og klikket indløs. <br /> Hvis du indløser et saldo gavekort og der er et restbeløb tilbage, vil du kunne bruge dette senere, med samme gavekort nummer.'
    },

    en: {
        ...OrderFlowLocale.en,
        ...SummaryLocale.en,
        title: 'Gift Card',
        info: 'You can add more cupon numbers after you have input and submitted the first one. <br /> If you submit a gift card and there is any remaining balance, you will be able to use this later with the same gift card number.'
    }
}