import './order.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../orderflow-constants'
import { ROUTES } from '../../navigation/route-constants'
import { APP_MODES } from '../../../api/api-constants'
import * as orderActions from '../../../actions/order-actions'
import * as gaActions from '../../../actions/ga-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import AvailableTickets from './ticketselection/AvailableTickets'
import SeatSelection from './seatselection/SeatSelection'
import LOCALE from './OrderLocale'
import Info from '../../overlays/Info'

class OrderContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            canReserveSelected: true,
            canBuySelected: true,
            showInfoBox: false
        };
        props.actions.calculateTotalPrice();
        this.ticketQuantityChanged = this.ticketQuantityChanged.bind(this);
        this.submitDiscountCode = this.submitDiscountCode.bind(this);
        this.navBack = this.navBack.bind(this)
        this.navNext = this.navNext.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;        

        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);

        this.checkAndUpdateReserveAndBuy();
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.order.isFetching && this.props.selected.totalTicketQuantity != null)
            this.checkAndUpdateReserveAndBuy();

        if (this.props.showInfoBox != nextProps.showInfoBox) {
            this.setState({ showInfoBox: nextProps.showInfoBox });
        }
    }

    ticketQuantityChanged(id, quantity) {
        this.setError('');
        const { actions, showtime, selected } = this.props;
        let selectedTickets = [...selected.tickets];
        const existingTicket = _.find(selectedTickets, (ticket) => { return id === ticket.id; });
        if (existingTicket) {
            if (quantity > 0) existingTicket.quantity = quantity;
            else _.remove(selectedTickets, (ticket) => { return ticket.id === existingTicket.id });
        } else selectedTickets.push({ id, quantity });

        this.checkAndUpdateReserveAndBuy();
        actions.ticketQuantityChanged(showtime.id, selected.transactionId, selectedTickets, selected.vouchers);
    }

    submitDiscountCode(discountCode) {
        const { showtime, clubCardId, actions } = this.props;
        actions.getAvailableTicketsAndExtraPurchases(showtime.id, clubCardId, discountCode);
    }

    setError(message) {
        this.setState({ error: message });
    }

    navBack(e) {
        const { organizerId, history, actions } = this.props;
        actions.resetOrder();
        const page = e.target.dataset.navTo;
        history.push(`${page}?org=${organizerId}`);
    }

    navNext(e) {
        const { organizerId, showtime, order, selected, actions, history, appLocale, bioKlubDkAvailable } = this.props;
        const { canReserveSelected, canBuySelected } = this.state;
        if (selected.tickets.length > 0) {
            const orderType = e.target.id;

            if (orderType === CONSTANTS.orderTypes.reserve && (!this.canReserveNow() || !canReserveSelected))
                order.clubCard ? this.setError(LOCALE[appLocale].noReserveClubCard) : this.setError(LOCALE[appLocale].noReserve);
            else if (orderType === CONSTANTS.orderTypes.buy && (!this.canBuyNow() || !canBuySelected))
                this.setError(LOCALE[appLocale].noBuy);
            else {
                actions.gaTrackCheckoutStep(1);
                actions.orderTypeChanged(orderType);
                if (e.target.dataset.buyBioKlubDk && bioKlubDkAvailable) history.push(`${ROUTES.ORDERFLOW.BIOKLUB_DK}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
                else history.push(`${ROUTES.ORDERFLOW.EXTRA_PURCHASES}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
            }
        } else this.setError(LOCALE[appLocale].chooseTickets);
    }

    canReserveNow() {
        const { showtime } = this.props;
        const datetimeNow = moment();
        const isInsidePeriod = datetimeNow >= moment(showtime.reserveInfo.start) && datetimeNow <= moment(showtime.reserveInfo.end);
        return showtime.reserveInfo.enabled && isInsidePeriod;
    }

    canBuyNow() {
        const { showtime } = this.props;
        const datetimeNow = moment();
        const isInsidePeriod = datetimeNow >= moment(showtime.buyInfo.start) && datetimeNow <= moment(showtime.buyInfo.end);
        return showtime.buyInfo.enabled && isInsidePeriod;
    }

    checkAndUpdateReserveAndBuy() {
        const { order } = this.props;
        const canReserveTotalQuantity = order.selected.totalTicketQuantity <= order.reserveMax;
        const canBuyTotalQuantity = order.selected.totalTicketQuantity <= order.buyMax;
        let canReserveTicketQuantity = true, canBuyTicketQuantity = true;
        _.some(order.selected.tickets, function (selectedTicket) {
            const availableTicket = _.find(order.availableTickets, (available) => { return selectedTicket.id == available.id; });
            if (availableTicket) {
                canReserveTicketQuantity = availableTicket.canReserve !== false && selectedTicket.quantity <= availableTicket.reserveMax;
                canBuyTicketQuantity = availableTicket.canBuy !== false && selectedTicket.quantity <= availableTicket.buyMax;
            }
        });

        this.setState({
            canReserveSelected: canReserveTotalQuantity && canReserveTicketQuantity && !order.clubCard && order.canReserve,
            canBuySelected: canBuyTotalQuantity && canBuyTicketQuantity
        });
    }

    generateReserveButton(selected) {
        const { bioDkActive, appLocale, configuration, canReserve } = this.props;
        const { canReserveSelected } = this.state;
        let reserveClasses = canReserve && selected.tickets.length > 0 && canReserveSelected ? 'next green fade-in' : 'next green dim';
        if (configuration.openOrderInNewWindow) reserveClasses += ' no-top-margin';
        return !bioDkActive && this.canReserveNow() ? <input type="button" id="reserve" className={reserveClasses} onClick={this.navNext} value={LOCALE[appLocale].nextReserve} /> : null;
    }

    generateBuyButton(selected) {
        const { canBuySelected } = this.state;
        const { appLocale, configuration } = this.props;
        let buyClasses = selected.tickets.length > 0 && canBuySelected ? 'next green fade-in' : 'next green dim';
        if (configuration.openOrderInNewWindow) buyClasses += ' no-top-margin';
        return this.canBuyNow() ? <input type="button" id="buy" className={buyClasses} onClick={this.navNext} value={LOCALE[appLocale].nextBuy} /> : null;
    }

    generateBioKlubDkButton(selected) {
        const { canBuySelected } = this.state;
        const { appLocale, configuration } = this.props;
        let buyClasses = selected.tickets.length > 0 && canBuySelected ? 'next green fade-in' : 'next green dim';
        if (configuration.openOrderInNewWindow) buyClasses += ' no-top-margin';
        return this.canBuyNow() ? <input type="button" id="buy" data-buy-bio-klub-dk="buy-bio-klub-dk" className={buyClasses} onClick={this.navNext} value={LOCALE[appLocale].nextBuyBioKlubDk} /> : null;
    }

    generateBackButtons(configuration, showtime) {
        const { backTo, appLocale } = this.props;
        let backText = LOCALE[appLocale].backProgram;
        switch (backTo) {
            case ROUTES.PROGRAM:
                backText = LOCALE[appLocale].backProgram;
                break;
            case ROUTES.ALL_MOVIES:
                backText = LOCALE[appLocale].backAll;
                break;
            case ROUTES.FUTURE_MOVIES:
                backText = LOCALE[appLocale].backFuture;
                break;
        }

        const programButton = configuration.appMode !== APP_MODES.ORDERFLOW && configuration.appMode !== APP_MODES.MOVIE ?
            <input type="button" data-nav-to={`${backTo}`} className="back gray" onClick={this.navBack} value={backText} /> : null;

        const movieButton = configuration.appMode !== APP_MODES.ORDERFLOW ? <input type="button" data-nav-to={`${ROUTES.MOVIE}/${showtime.movieId}`} className="back gray" onClick={this.navBack} value={LOCALE[appLocale].backMovie} /> : null;

        return <div className="back-buttons">{programButton}{movieButton}</div>;
    }

    render() {
        const { showInfoBox } = this.state;
        const { showtime, order, selected, configuration, actions, bioDkActive, initialFetchDone, appLocale, discountEnabled, discountActivated, bioKlubDkAvailable } = this.props;
        const backButtons = !configuration.openOrderInNewWindow ? this.generateBackButtons(configuration, showtime) : null;
        const reserveButton = showtime ? this.generateReserveButton(selected) : null;
        const buyButton = showtime ? this.generateBuyButton(selected) : null;
        const buyBioKlubDkButton = showtime && bioKlubDkAvailable ? this.generateBioKlubDkButton(selected) : null;
        const disabled = !reserveButton && !buyButton ? <span className="buy-and-reserve-disabled">{LOCALE[appLocale].noOnlineOrder}</span> : null;
        const navButtonClasses = configuration.bottomBarAllwaysVisible ? 'nav-buttons fixed' : 'nav-buttons';

        const seatSelection = showtime ? <SeatSelection appLocale={appLocale}
            showtime={showtime}
            seatsImage={order.seatsImage}
            selected={selected}
            selectedSeatsChanged={this.selectedSeatsChanged}
            moveSeats={actions.moveSeats}
            configuration={configuration}
            setError={this.setError}
            error={this.state.error}
        /> : null;

        let infoText = '';
        if (showtime.info) infoText = showtime.info;
        else if (showInfoBox) infoText = LOCALE[appLocale].extraPurchasesReset;

        return (
            <div className="OrderContainer">
                {infoText ? <Info canClose={true} text={infoText} buttons={<input type="button" className="next" onClick={() => this.setState({ showInfoBox: false })} value="OK" />} /> : null}

                <div className="table-container">
                    <AvailableTickets appLocale={appLocale}
                        order={order}
                        selected={selected}
                        ticketQuantityChanged={this.ticketQuantityChanged}
                        bioDkActive={bioDkActive}
                        freeSeats={showtime.freeSeats}
                        initialFetchDone={initialFetchDone}
                        discountEnabled={discountEnabled}
                        discountActivated={discountActivated}
                        submitDiscountCode={this.submitDiscountCode}
                        configuration={configuration}
                        appHeightChanged={actions.appHeightChanged}
                        locationId={showtime.locationId}
                    />
                    {seatSelection}
                </div>
                <div className={configuration.openOrderInNewWindow ? 'nav-buttons window' : navButtonClasses}>
                    {backButtons}
                    <div className="next-buttons">
                        {buyButton}
                        {buyBioKlubDkButton}
                        {reserveButton}
                    </div>
                    {disabled}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentPage: ownProps.currentPage,
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        order: state.order,
        selected: state.order.selected,
        configuration: state.organizer.configuration,
        bioKlubDkAvailable: state.order.bioKlubDk.available,
        bioDkActive: state.order.selected.vouchers.active != '',
        backTo: state.movielist.backTo,
        initialFetchDone: state.order.initialFetchDone,
        appLocale: state.app.locale,
        discountEnabled: state.order.discountEnabled,
        discountActivated: state.order.discountActivated,
        clubCardId: state.user.clubCardId,
        canReserve: state.order.canReserve,
        showInfoBox: state.order.showInfoBox,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer)