import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../movielist-constants'
import LOCALE from '../MovieListLocale'
import { setItemClasses } from '../shared'
import SpecialEventListItem from './SpecialEventsListItem'

export default class SpecialEventsList extends React.Component {
    generateMovieListItems(movies) {
        const { posterBanners, appLocale, movieClicked, showtimeClicked, organizerIds, columns } = this.props;
        let sortedMovies = this.sortMovies(movies);
        let movieItems = [];
        let counter = 0;

        _.forEach(sortedMovies, (movie) => {
            let rowClasses = setItemClasses(counter, columns);

            movieItems.push(<SpecialEventListItem key={movie.id}
                appLocale={appLocale}
                rowClasses={rowClasses}
                movie={movie}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                showtimeClicked={showtimeClicked}
                organizerIds={organizerIds}
            />);

            counter++;
        });

        return movieItems;
    }

    sortMovies(movies) {
        const { sortBy, showtimes, selectedMonth } = this.props;

        let sortedMovies = [];
        _.forEach(movies, (movie) => {
            movie.validShowtimes = _.filter(showtimes, (showtime) => {
                if (showtime.movieId == movie.id) {
                    const datetime = moment(showtime.dateTime);
                    const isInFuture = datetime > moment();
                    if (showtime.showInfo.enabled !== true) return false;
                    else {
                        const month = datetime.month();
                        const isInMonth = month === selectedMonth;
                        return (sortBy == CONSTANTS.sort.alphabet || sortBy == CONSTANTS.sort.time && isInFuture) && isInMonth;
                    }
                }
            });

            if (movie.validShowtimes.length > 0) {
                movie.validShowtimes = _.sortBy(movie.validShowtimes, ['dateTime']);
                sortedMovies.push(movie);
            }
        });

        if (sortBy === CONSTANTS.sort.alphabet) sortedMovies = _.sortBy(sortedMovies, ['name']);
        else if (sortBy === CONSTANTS.sort.time)
            sortedMovies.sort((a, b) => { return moment(a.validShowtimes[0].dateTime) - moment(b.validShowtimes[0].dateTime); });

        return sortedMovies;
    }

    render() {
        const { movies, appLocale } = this.props;
        const movieListItems = this.generateMovieListItems(movies);

        return (
            <div className="MovieList">
                {movieListItems.length > 0 ? movieListItems : <h3 className="no-events">{LOCALE[appLocale].noEventsText}</h3>}
            </div>)
    }
}