export default {
    da: {
        shortDateTimeFormat: 'ddd [d.] DD/MM [kl.] LT',
        loginName: 'Telefonnummer eller e-mail',
        loginPassword: 'Kode',
        findTickets: 'Find Billetter',
        noUserTickets: 'Der er ingen billetter med disse loginoplysninger',
        forgotPassword: 'Har du glemt din kode? Nulstil <a href="https://www.ebillet.dk/system/resetpassword.aspx" target="_blank">her</a>',

        ticket: 'billet',
        tickets: 'billetter',
        reservation: 'reservation',
        paid: 'Betalt',
        reserved: 'Reserveret',
        deleted: 'Slettet',
        close: 'LUK',
        getReceiptError: 'Kvitteringen kunne ikke hentes.'
    },

    en: {
        shortDateTimeFormat: 'ddd, DD/MM [at] LT',
        loginName: 'Telephone number or e-mail',
        loginPassword: 'Password',
        findTickets: 'Find Tickets',
        noUserTickets: 'There are no tickets for this login',
        forgotPassword: 'Did you forget your password? Reset <a href="https://www.ebillet.dk/system/resetpassword.aspx" target="_blank">here</a>',

        ticket: 'ticket',
        tickets: 'tickets',
        reserved: 'Reserved',
        paid: 'Paid',
        reservation: 'reservation',
        deleted: 'Deleted', 
        close: 'CLOSE',
        getReceiptError: 'We couldn\'t get your receipt.'
    }
}