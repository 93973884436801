import _ from 'lodash'
import api from '../api/orderflow-api'
import moment from 'moment'
import { getParams } from '../utilities/location-utilities'
import fetchItems from '../api/export-api'
import { getOrganizer } from './organizer-actions'
import { ROUTES } from '../components/navigation/route-constants'

export default {
    getMovieList,
    getFutureMovies
}

export const MOVIELIST_REQUEST = 'MOVIELIST_REQUEST'
export const MOVIELIST_SUCCESS = 'MOVIELIST_SUCCESS'
export const MOVIELIST_FAILED = 'MOVIELIST_FAILED'

export function getMovieList(organizerIds) {
    return (dispatch, getState) => {
        const config = getState().organizer.configuration;
        dispatch(movielistRequest());

        return api.fetchMovieList(organizerIds, config.daysBack)
            .then(json => {
                json.filterId = getParams().filter || null;
                return dispatch(movielistSuccess(json, organizerIds, config));
            }, error => dispatch(movielistFailed(error)));
    }
}

const movielistRequest = () => {
    return {
        type: MOVIELIST_REQUEST,
        requestedAt: Date.now()
    }
}

export const movielistSuccess = (json, organizerIds, config) => {
    const dateSort = config.movielists.date.sort;
    const monthSort = config.movielists.special.sort;
    const queryParameters = getParams(window.location.href);
    const selectedDate = queryParameters.date ? queryParameters.date : null;

    const datesWithShowtimes = []
    _.forEach(json.showtimes, (showtime) => {
        if (showtime.showInfo.enabled === true) {
            const date = moment(showtime.dateTime).format('YYYY-MM-DD');
            if (!_.includes(datesWithShowtimes, date)) datesWithShowtimes.push(date);
        }
    });

    //Setting the backTo path if coming from a list
    let backTo = ROUTES.PROGRAM;
    const path = window.location.pathname;
    if (path == ROUTES.ALL_FUTURE_MOVIES || path == ROUTES.ALL_MOVIES || path == ROUTES.CIRCUS || path == ROUTES.FUTURE_MOVIES
        || path == ROUTES.MULTI_ALL_MOVIES || path == ROUTES.MULTI_FUTURE_MOVIES || path == ROUTES.PROGRAM || path == ROUTES.PROGRAM_AND_ALL_FUTURE_MOVIES) {
        backTo = path;
    }
    //--

    return {
        type: MOVIELIST_SUCCESS,
        succeededAt: Date.now(),
        organizerIds,
        organizers: json.organizers,
        movies: _.sortBy(json.movies, ['name']),
        movieBases: _.sortBy(json.movieBases, ['name']),
        showtimes: json.showtimes,
        showtimeTypes: json.showtimeTypes,
        datesWithShowtimes,
        sort: dateSort,
        monthSort,
        showBackInTime: config.daysBack > 0,
        selectedDate,
        backTo,
        filterId: parseInt(json.filterId)
    }
}

const movielistFailed = (error) => {
    return {
        type: MOVIELIST_FAILED,
        failedAt: Date.now(),
        error: {
            ...error,
            message: 'Der var ingen data at hente. Prøv igen senere.'
        }
    }
}

export const FUTURE_MOVIES_REQUEST = 'FUTURE_MOVIES_REQUEST'
export const FUTURE_MOVIES_SUCCESS = 'FUTURE_MOVIES_SUCCESS'
export const FUTURE_MOVIES_FAILED = 'FUTURE_MOVIES_FAILED'

export function getFutureMovies(templateIds, organizerIds) {
    return dispatch => {
        const isMultipleTemplates = _.includes(templateIds, ',');
        const templates = isMultipleTemplates ? _.split(templateIds, ',') : templateIds;

        if (isMultipleTemplates) {
            const promises = [];
            _.forEach(templates, (id) => {
                promises.push(new Promise((resolve, reject) => {
                    dispatch(futureMoviesRequest());
                    resolve(fetchItems(id));
                }));
            });

            Promise.all(promises).then(result => {
                const items = _.flatten(result);
                const merged = [];
                _.forEach(items, (item) => {
                    const exists = _.find(merged, { id: item.id });
                    if (exists) {
                        if (Array.isArray(exists.organizerId)) exists.organizerId.push(item.organizerId);
                        else exists.organizerId = [exists.organizerId, item.organizerId];
                    } else {
                        item.organizerId = [item.organizerId];
                        merged.push(item);
                    }
                });

                return dispatch(getMovieList(organizerIds)).then(() => dispatch(futureMoviesSuccess(merged)));
            }, error => dispatch(futureMoviesFailed(error)));
        } else {
            dispatch(futureMoviesRequest(templateIds));
            return dispatch(getMovieList(organizerIds)).then(() => fetchItems(templateIds)).then(items => dispatch(futureMoviesSuccess(items)), error => dispatch(futureMoviesFailed(error)));
        }
    }
}

const futureMoviesRequest = (templateId) => {
    return {
        type: FUTURE_MOVIES_REQUEST,
        requestedAt: Date.now(),
        templateId
    }
}

const futureMoviesSuccess = (futureMovies) => {
    return {
        type: FUTURE_MOVIES_SUCCESS,
        succeededAt: Date.now(),
        futureMovies
    }
}

const futureMoviesFailed = (error) => {
    return {
        type: FUTURE_MOVIES_FAILED,
        failedAt: Date.now(),
        error: {
            ...error,
            message: 'Der var ingen data at hente. Prøv igen senere.',
        }
    }
}

// CHANGE HANDLERS
export function changeSelectedShowtime(newShowtime, backTo) {
    return (dispatch, getState) => {
        const isInFuture = moment(newShowtime.dateTime) > moment();
        if (isInFuture) {
            const organizers = getState().movielist.organizers;
            const isMultipleOrgs = organizers.length > 1;
            if (isMultipleOrgs) {
                const selectedOrganizer = _.find(organizers, { 'id': newShowtime.organizerId });
                const siteType = getState().app.siteType;
                dispatch(getOrganizer(selectedOrganizer.id, siteType));
            }
            return new Promise((resolve) => {
                return dispatch(selectedMovieChanged(newShowtime.movieId, backTo))
                    .then(() => resolve(dispatch(selectedShowtimeChanged(newShowtime))));
            });
        }
    }
}

export const SELECTED_MOVIE_CHANGED = 'SELECTED_MOVIE_CHANGED'
export function selectedMovieChanged(newMovieId, backTo) {
    return (dispatch, getState) => {
        let movie = _.find(getState().movielist.movies, (movie) => { return movie.id == newMovieId }) ||
            _.find(getState().movielist.futureMovies, (movie) => { return movie.id == newMovieId });

        const organizerIds = getState().movielist.organizerIds;
        if (!movie && organizerIds) {
            return api.fetchMovie(newMovieId, organizerIds).then((result) => dispatch(selectedMovieUpdate(result.movies[0], backTo)));
        } else {
            return new Promise((resolve) => {
                return resolve(dispatch(selectedMovieUpdate(movie, backTo)));
            });
        }
    }
}

const selectedMovieUpdate = (newMovie, backTo) => {
    return {
        type: SELECTED_MOVIE_CHANGED,
        movie: newMovie,
        backTo
    }
}


export const SELECTED_SHOWTIME_CHANGED = 'SELECTED_SHOWTIME_CHANGED'
export const selectedShowtimeChanged = (newShowtime) => {
    return {
        type: SELECTED_SHOWTIME_CHANGED,
        selectedShowtime: newShowtime
    }
}

export const TOGGLE_SORT = 'TOGGLE_SORT'
export const toggleSort = (newSort) => {
    return {
        type: TOGGLE_SORT,
        sort: newSort
    }
}

export const CHANGE_DATE = 'CHANGE_DATE'
export const changeDate = (newDate) => {
    return {
        type: CHANGE_DATE,
        selectedDate: newDate
    }
}

export const CHANGE_MONTH = 'CHANGE_MONTH'
export const changeMonth = (newMonth) => {
    return {
        type: CHANGE_MONTH,
        selectedMonth: newMonth
    }
}