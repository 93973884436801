export default {
    da: {
        longDateTimeFormat: 'dddd [d.] LL [kl.] LT',
        shortDateTimeFormat: 'ddd [d.] DD/MM [kl.] LT',
        noMoviesText: 'Der er ingen film',
        noMoviesTodayText: 'Der er ingen forestillinger i dag',
        noMoreMoviesTodayText: 'Der er ikke flere forestillinger i dag',
        noMovieTypesTodayText: 'Der er ingen programlagte INSERT_TYPE forestillinger',
        noEventsText: 'Der er ingen arrangementer',
        readMore: 'Læs mere...',
        moreInfo: 'Mere info',
        moreShowtimes: 'Flere tider  ❯',
        readMoreShowtimes: 'Læs mere og se alle tider  ❯',
        buyTickets: 'Køb Billetter',

        movieInfo: 'Filminfo',
        allShowtimes: 'Alle spilletider',

        searchCircus: 'Søg dato eller by',
        today: 'I dag',
        shownFrom: 'Vises fra '
    },

    en: {
        longDateTimeFormat: 'dddd LL [at] LT',
        shortDateTimeFormat: 'ddd, DD/MM [at] LT',
        noMoviesText: 'There are no movies',
        noEventsText: 'There are no events',
        readMore: "Read more...",
        moreInfo: 'More info',
        moreShowtimes: "More showtimes  ❯",
        readMoreShowtimes: "Read more and all showtimes  ❯",
        buyTickets: 'Buy Tickets',

        movieInfo: 'Movie info',
        allShowtimes: 'All showtimes',

        searchCircus: 'Search date or city',
        today: 'Today',
        shownFrom: 'Shown from '
    }
}