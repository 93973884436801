import React from 'react'
import _ from 'lodash'
import Ticket from './Ticket'
import VoucherInput from '../../vouchers/VoucherInput'
import LOCALE from '../OrderLocale'
import { VOUCHER_TYPES } from '../../../../api/api-constants';

export default class AvailableTickets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalTicketQuantity: props.order.selected.totalTicketQuantity || 0,
            selectedPoints: props.order.selected.clubCardPoints || 0
        }

        this.canIncrementTotalQuantity = this.canIncrementTotalQuantity.bind(this);
        this.canIncrementTotalPoints = this.canIncrementTotalPoints.bind(this);
        this.incrementTotalQuantity = this.incrementTotalQuantity.bind(this);
        this.decrementTotalQuantity = this.decrementTotalQuantity.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { order } = this.props;
        const selected = order.selected;
        const nextSelected = nextProps.order.selected;
        const { totalTicketQuantity, selectedPoints } = this.state;

        if (selected.totalTicketQuantity && totalTicketQuantity != nextSelected.totalTicketQuantity)
            this.setState({ totalTicketQuantity: nextSelected.totalTicketQuantity });
        if (selected.clubCardPoints && selectedPoints != nextSelected.clubCardPoints)
            this.setState({ selectedPoints: nextSelected.clubCardPoints });
    }

    canIncrementTotalQuantity() {
        let { totalTicketQuantity } = this.state;
        const { order } = this.props;
        const newTotal = totalTicketQuantity + 1;
        return newTotal <= Math.max(order.buyMax, order.reserveMax);
    }

    incrementTotalQuantity() {
        let { totalTicketQuantity } = this.state;
        this.setState({ totalTicketQuantity: ++totalTicketQuantity })
    }

    decrementTotalQuantity() {
        let { totalTicketQuantity } = this.state;
        if (totalTicketQuantity > 0) {
            this.setState({ totalTicketQuantity: --totalTicketQuantity })
        }
    }

    canIncrementTotalPoints(incrementBy) {
        const { selectedPoints } = this.state;
        const { order } = this.props;
        const newTotal = selectedPoints + incrementBy;
        const canIncrement = newTotal <= order.clubCard.points;
        if (canIncrement) this.setState({ selectedPoints: newTotal });;
        return canIncrement;
    }

    generateTickets() {
        const { order, ticketQuantityChanged, bioDkActive, freeSeats, appLocale, discountEnabled } = this.props;
        const selected = order.selected;
        let selectedIdsHandled = [];
        let tickets = [];

        _.forEach(order.availableTickets, (available) => {
            const selectedTicket = _.find(selected.tickets, (ticket) => { return available.id === ticket.id; });
            if (selectedTicket != null) selectedIdsHandled.push(selectedTicket.id);

            tickets.push(<Ticket key={available.id}
                appLocale={appLocale}
                availableTicket={available}
                selectedTicket={selectedTicket}
                order={order}
                canIncrementTotalQuantity={this.canIncrementTotalQuantity}
                incrementTotalQuantity={this.incrementTotalQuantity}
                decrementTotalQuantity={this.decrementTotalQuantity}
                ticketQuantityChanged={ticketQuantityChanged}
                bioDkActive={bioDkActive}
                canIncrementTotalPoints={this.canIncrementTotalPoints}
                freeSeats={freeSeats}
                discountEnabled={discountEnabled}
            />);
        });

        //Checking if there have been added other selected tickets that are not in available tickets, such as biografklub dk
        if (bioDkActive && selectedIdsHandled.length < selected.tickets.length) {
            _.forEach(selected.tickets, (ticket) => {
                const existing = _.find(selectedIdsHandled, (id) => { return id === ticket.id });
                if (!existing) {
                    tickets.push(<div key={ticket.id} className="Ticket">
                        <h4>{ticket.name}</h4>
                        <div className="quantity">{ticket.quantity}</div>
                    </div>)
                }
            });
        }

        return tickets;
    }

    getVoucherText() {
        const { configuration, order, appLocale } = this.props;
        if (!configuration.giftCardsEnabled && order.bioKlubDk.available)
            return <span>{LOCALE[appLocale].discountTextBioKlubDk}</span>;
        else if (configuration.giftCardsEnabled && !order.bioKlubDk.available)
            return <span>{LOCALE[appLocale].discountTextGiftCard}</span>;
        else if (configuration.giftCardsEnabled && order.bioKlubDk.available)
            return <span>{LOCALE[appLocale].discountTextBoth}</span>;
        else return null;
    }

    getLocationText() {
        const { configuration, locationId } = this.props;
        const locationText = _.find(configuration.locationTexts, (text) => { return text.id == locationId });
        return locationText ? locationText.text : null;
    }

    render() {
        const { initialFetchDone, discountEnabled, discountActivated, appLocale, submitDiscountCode, appHeightChanged } = this.props;
        const tickets = initialFetchDone ? this.generateTickets() : null;
        const voucherText = this.getVoucherText();
        const locationText = this.getLocationText();

        return (
            <div className="AvailableTickets">
                {locationText ? <div className="location-text" dangerouslySetInnerHTML={{ __html: locationText }} /> : null}
                {tickets}
                {!discountActivated && discountEnabled ? <div className="discount">
                    <VoucherInput type={VOUCHER_TYPES.DISCOUNT}
                        appLocale={appLocale}
                        vouchersChanged={submitDiscountCode}
                        appHeightChanged={appHeightChanged}
                    /> {voucherText}</div> : null}
            </div>
        )
    }
}