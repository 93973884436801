import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Switch, Route } from 'react-router-dom'
import _ from 'lodash'
import { getParams } from '../../utilities/location-utilities'
import * as orderActions from '../../actions/order-actions'
import * as movieListActions from '../../actions/movielist-actions'
import TopBar from './topbar/TopBar'
import OrderContainer from './order/OrderContainer'
import CustomerContainer from './customer/CustomerContainer'
import DibsContainer from './payment/DibsContainer'
import ReepayContainer from './payment/ReepayContainer'
import ReceiptContainer from './receipt/ReceiptContainer'
import { ROUTES } from '../navigation/route-constants'
import CONSTANTS from './orderflow-constants'
import { appHeightChanged } from '../../actions/app-actions'
import Error from '../overlays/Error'
import Info from '../overlays/Info'
import ClubCardContainer from './clubcard/ClubCardContainer';
import ExtraPurchasesContainer from './extrapurchases/ExtraPurchaseContainer'
import BioKlubDkContainer from './biografklubdanmark/BioKlubDkContainer'
import GiftCardContainer from './giftcards/GiftCardContainer'
import PaymentContainer from './payment/PaymentContainer'
import Summary from './summary/SummaryContainer'

class OrderFlowContainer extends React.Component {
    timeout = 1800000; //30 minutes
    timer = null;

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    constructor(props) {
        super(props);
        this.state = { hasTimedOut: false };
        const { currentPage, match, history } = props;

        if (currentPage !== ROUTES.ORDERFLOW.RESERVATION && currentPage !== ROUTES.ORDERFLOW.TICKETS) //entry points into the flow
            history.replace(`${ROUTES.ORDERFLOW.TICKETS}/${match.params.movieId}/${match.params.showtimeId}${history.location.search}`);

        this.handleTimeout = this.handleTimeout.bind(this);
        this.timer = setTimeout(this.handleTimeout, this.timeout);
    }

    componentDidMount() {
        const { actions, movies, movieId, showtimeId, organizerIds, receipt, backTo, iframeId } = this.props;
        if (!receipt.transactionId) {
            if (movies.length == 0 && movieId && showtimeId) {
                actions.getMovieList(organizerIds)
                    .then((movielist) => {
                        const showtime = movielist.showtimes ? _.find(movielist.showtimes, (showtime) => { return showtime.id == showtimeId }) : null;
                        return actions.selectedMovieChanged(movieId, backTo).then(() => actions.selectedShowtimeChanged(showtime)).then(() => actions.startOrder(showtime.id));
                    });
            } else actions.startOrder(showtimeId);
        }

        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.props.actions.resetOrder();
    }

    resetTimeout() {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.handleTimeout, this.timeout);
    }

    handleTimeout() {
        clearTimeout(this.timer);
        this.setState({ hasTimedOut: true });
    }

    render() {
        const { hasTimedOut } = this.state;
        const { currentPage, organizer, movie, showtime, posterBanners, orderType, error, clubCardEnabled, pageHeader, appLocale } = this.props;
        const info = hasTimedOut && currentPage !== ROUTES.ORDERFLOW.RECEIPT ? <Info canClose={false} text={'Du har været inaktiv for længe'} buttons={<input type="button" className="next" value="Start Forfra" onClick={() => window.location.reload()} />} /> : null;

        const params = '/:movieId/:showtimeId/';
        const clubcard = clubCardEnabled && orderType != CONSTANTS.orderTypes.buyReservation && orderType != CONSTANTS.orderTypes.reserve ? <ClubCardContainer /> : null;
        const header = pageHeader ? <div className="page-header orderflow" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;

        const orderFlow = !_.isEmpty(movie) && !_.isEmpty(showtime) ? <div className="OrderFlowContainer">
            {clubcard}
            {header}
            <TopBar appLocale={appLocale}
                organizer={organizer}
                movie={movie}
                showtime={showtime}
                posterBanners={posterBanners}
                clubCardEnabled={clubCardEnabled}
                pageHeaderEnabled={pageHeader != null}
            />

            <Switch>
                <Route path={ROUTES.ORDERFLOW.TICKETS + params} component={OrderContainer} />
                <Route path={ROUTES.ORDERFLOW.EXTRA_PURCHASES + params} component={ExtraPurchasesContainer} />
                <Route path={ROUTES.ORDERFLOW.CUSTOMER + params} component={CustomerContainer} />
                <Route path={ROUTES.ORDERFLOW.BIOKLUB_DK + params} component={BioKlubDkContainer} />
                <Route path={ROUTES.ORDERFLOW.PAYMENT + params} component={PaymentContainer} />
                <Route path={ROUTES.ORDERFLOW.GIFTCARDS + params} component={GiftCardContainer} />
                <Route path={ROUTES.ORDERFLOW.DIBS + params} component={DibsContainer} />
                <Route path={ROUTES.ORDERFLOW.REEPAY + params} component={ReepayContainer} />
                <Route path={ROUTES.ORDERFLOW.RECEIPT + params} component={ReceiptContainer} />
                <Route path={ROUTES.ORDERFLOW.RESERVATION + '/:reservationId'} component={ReceiptContainer} />
            </Switch>

            <Summary currentPage={currentPage} />
            <Error />
            {info}
        </div> : null;

        return (
            orderFlow
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const currentPage = '/' + ownProps.match.params.page;
    const showReceiptData = state.receipt.transactionId != 0 && (currentPage === ROUTES.ORDERFLOW.RECEIPT || currentPage === ROUTES.ORDERFLOW.RESERVATION);

    return {
        currentPage,
        showReceiptData,
        organizerIds: state.movielist.organizerIds || getParams().org,
        organizer: state.organizer,
        movieId: ownProps.match.params.movieId,
        movie: state.movielist.selectedMovie || {},
        movies: state.movielist.movies || [],
        showtimeId: ownProps.match.params.showtimeId,
        showtime: state.movielist.selectedShowtime || {},
        selected: state.order.selected,
        orderType: state.order.orderType,
        posterBanners: state.organizer.configuration.posterBanners,
        receipt: state.receipt,
        clubCardEnabled: state.organizer.configuration.clubCard != null,
        pageHeader: state.organizer.configuration.pageHeaders.orderflow,
        appLocale: state.app.locale,
        backTo: state.movielist.backTo,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { appHeightChanged }, movieListActions, orderActions), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFlowContainer)