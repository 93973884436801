import '../movielists/css/movielists.scss'
import './circuslist.scss'

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getParams } from '../../utilities/location-utilities'
import { ROUTES } from '../navigation/route-constants'
import CONSTANTS from '../movielists/movielist-constants'
import { appHeightChanged } from '../../actions/app-actions'
import { getMovieList, changeSelectedShowtime } from '../../actions/movielist-actions'
import CircusList from './CircusList'

class CircusListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.showtimeClicked = this.showtimeClicked.bind(this);
    }

    componentDidMount() {
        const { actions, events, organizerIds, iframeId } = this.props;
        if (events.length == 0) actions.getMovieList(organizerIds);

            if (window.parent != window) actions.appHeightChanged(iframeId, true);
            else window.scrollTo(0, 0);
    }

    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, showtimes } = this.props;
        const showtime = _.find(showtimes, (showtime) => { return showtime.id == showtimeId && showtime.organizerId == organizerId });
        if (showtime) {
            actions.changeSelectedShowtime(showtime, history.location.pathname).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }

    render() {
        const { appLocale, organizer, events, showtimes, columns, actions } = this.props;

        return (
            <div className="CircusListContainer one-column">
                <CircusList appLocale={appLocale}
                    columns={columns}
                    organizer={organizer}
                    events={events}
                    showtimes={showtimes}
                    showtimeClicked={this.showtimeClicked}
                    appHeightChanged={actions.appHeightChanged}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;

    return {
        organizerIds: state.movielist.organizerIds || getParams().org,
        organizer: state.movielist.organizers[0],
        columns: CONSTANTS.columns.one,
        sortBy: CONSTANTS.sort.time,
        events: state.movielist.movies,
        showtimes: state.movielist.showtimes,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getMovieList, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CircusListContainer)