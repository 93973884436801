import fetch from 'isomorphic-fetch'
import xml2js from '../lib/xml-to-js'
import _ from 'lodash'
import orderflowApi from './orderflow-api'
import * as soapbuilder from './soap-builder'
import API_CONSTANTS from './api-constants'
import extractOrganizer from './ticketgate-extractions/organizer'
import extractAvailableTicketsAndExtraPurchases, { extractExtraPurchases } from './ticketgate-extractions/available-tickets-and-extra-purchases'
import extractFromTransaction from './ticketgate-extractions/transaction'
import { extractSeats } from './ticketgate-extractions/transaction'
import localStorageUtils from '../utilities/localstorage-utilities'

function xmlFetch(method, url, body, callback) {
    return fetch(url, {
        method,
        headers: { 'Content-Type': 'text/xml;charset=UTF-8' },
        credentials: 'include',
        body
    }).then(response => {
        if (response.ok) return response.text();
        else throw response;
    }).then(xml => callback(xml))
}

function logError(method, answer) {
    const body = {
        client: {
            id: 1,
            name: 'OrderFlow.WebApp',
            url: window.location.href
        },
        logLevel: 'error',
        entry: {
            origin: 'TicketGate',
            method: method,
            code: answer._code,
            error: answer._error,
            message: answer._message,
        }
    }

    return orderflowApi.postLog(body);
}

const url = API_CONSTANTS.baseUrl + API_CONSTANTS.ticketgatePath;
let cardholder = 0;
let organizerId = -1;

export function setCardholder(cardId) {
    cardholder = cardId;
}

// ORGANIZER
export function fetchOrganizer(id, siteType, customerEmail) {
    organizerId = id;
    const soap = soapbuilder.getOrganizer(organizerId, siteType, customerEmail);
    return xmlFetch('POST', url, soap, organizerCallback);
}

function organizerCallback(xml) {
    const json = xml2js.parseString(xml);
    const organizer = json.envelope.body.getOrganizerResponse.getOrganizerResult.message.organizer;
    const answer = json.envelope.body.getOrganizerResponse.getOrganizerResult.message.answer;
    if (answer._result === true) return extractOrganizer(organizer);
    else {
        logError('organizerCallback', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// AVAILABLE TICKETS AND EXTRA PURCHASES
export function availableTicketsAndExtraPurchases(showtimeId, cardNumber, discountCode) {
    cardholder = cardNumber;
    const soap = soapbuilder.getAvailableTicketsAndConcession(organizerId, showtimeId, cardholder, discountCode);
    return xmlFetch('POST', url, soap, availableTicketsCallback);
}

function availableTicketsCallback(xml) {
    const json = xml2js.parseString(xml);
    const arrangement = json.envelope.body.getAvailableTicketsAndConcessionResponse.getAvailableTicketsAndConcessionResult.message.arrangement;
    const bonusProfiles = json.envelope.body.getAvailableTicketsAndConcessionResponse.getAvailableTicketsAndConcessionResult.message.bonusProfiles;
    const answer = json.envelope.body.getAvailableTicketsAndConcessionResponse.getAvailableTicketsAndConcessionResult.message.answer;
    if (answer._result === true && answer._error == 0 && answer._code != 46) return extractAvailableTicketsAndExtraPurchases(arrangement, bonusProfiles);
    else {
        logError('AvailableTicketsAndConcessions', answer);
        throw {
            code: answer._code,
            type: answer._error
        };;
    }
}

// BLOCK SEATS
export function blockSeats(showtimeId, transactionId, tickets, vouchers, defaultTickets) {
    const ticketsXml = buildTicketsXml(showtimeId, tickets);
    if (defaultTickets == null) defaultTickets = 0;
    const soap = soapbuilder.blockSeats(transactionId, cardholder, '0.0.0.0', ticketsXml, vouchers, defaultTickets);
    return xmlFetch('POST', url, soap, blockSeatsCallback);
}

function buildTicketsXml(showtimeId, tickets) {
    let ticketsQuantity = '';
    tickets.forEach((ticket) => { ticketsQuantity += '<Ticket No="' + ticket.id + '" Quantity="' + ticket.quantity + '" />'; });
    return soapbuilder.ticketsXml(organizerId, showtimeId, ticketsQuantity);
}

function blockSeatsCallback(xml) {
    const json = xml2js.parseString(xml);
    const transaction = json.envelope.body.blockSeats_v2Response.blockSeats_v2Result.message.transaction;
    const answer = json.envelope.body.blockSeats_v2Response.blockSeats_v2Result.message.answer;
    if (answer._result === true) return extractFromTransaction(transaction);
    else {
        logError('BlockSeats_v2', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// SEATS IMAGE
export function seatsImage() {
    const soap = soapbuilder.seatsImage();
    return xmlFetch('POST', url, soap, seatsImageCallback);
}

function seatsImageCallback(xml) {
    const json = xml2js.parseString(xml);
    const seatsImageResult = json.envelope.body.seatsImageResponse.seatsImageResult;
    if (seatsImageResult == null) logError('SeatsImage', { _message: seatsImageResult });
    return json.envelope.body.seatsImageResponse.seatsImageResult;
}

// MOVE SEATS
export function moveSeats(transactionId, x, y) {
    const soap = soapbuilder.moveSeats(transactionId, x, y);
    return xmlFetch('POST', url, soap, moveSeatsCallback);
}

function moveSeatsCallback(xml) {
    const json = xml2js.parseString(xml);
    const transaction = json.envelope.body.moveSeatsResponse.moveSeatsResult.message.transaction;
    const answer = json.envelope.body.moveSeatsResponse.moveSeatsResult.message.answer;
    if (answer._result === true) return extractFromTransaction(transaction);
    else {
        logError('MoveSeats', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// GET EXTRA PURCHASES
export function getConcessions(showtimeId, cardNumber) {
    cardholder = cardNumber;
    const soap = soapbuilder.getConcessions(organizerId, showtimeId, cardholder);
    return xmlFetch('POST', url, soap, getConcessionsCallback);
}

function getConcessionsCallback(xml) {
    const json = xml2js.parseString(xml);
    const concessions = json.envelope.body.getConcessionResponse.getConcessionResult.message.arrangement.concessions;
    const extraPurchases = concessions ? ((Array.isArray(concessions.item) ? concessions.item : [concessions.item])) : [];
    const answer = json.envelope.body.getConcessionResponse.getConcessionResult.message.answer;
    if (answer._result === true) return { extraPurchases: extractExtraPurchases(extraPurchases, []) };
    else {
        logError('GetConcessions', answer);
        throw {
            code: answer._code,
            type: answer._error
        };;
    }
}

// ADD CONCESSIONS
export function addConcessions(transactionId, showtimeId, extraPurchases) {
    var concessionXml = buildConcessionsXml(showtimeId, extraPurchases);
    var soap = soapbuilder.addConcession(transactionId, cardholder, 0, concessionXml);
    return xmlFetch('POST', url, soap, addConcessionsCallback);
}

function buildConcessionsXml(showtimeId, extraPurchases) {
    let extraPurchasesXml = '';
    extraPurchases.forEach((extra) => { extraPurchasesXml += `<Item No="${extra.id}" Quantity="${extra.quantity}" />`; });
    return soapbuilder.concessionXml(organizerId, showtimeId, extraPurchasesXml);
}

function addConcessionsCallback(xml) {
    const json = xml2js.parseString(xml);
    const transaction = json.envelope.body.addConcessionResponse.addConcessionResult.message.transaction;
    const answer = json.envelope.body.addConcessionResponse.addConcessionResult.message.answer;
    if (answer._result === true) return extractFromTransaction(transaction);
    else {
        logError('AddConcessions', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// ADD BONUS VOUCHERS
export function addVouchers(transactionId, showtimeId, extraPurchases) {
    const voucherXml = buildVouchersXml(showtimeId, extraPurchases);
    const soap = soapbuilder.addVouchers(transactionId, cardholder, voucherXml);
    return xmlFetch('POST', url, soap, addVouchersCallback);
}

function buildVouchersXml(showtimeId, extraPurchases) {
    let extraPurchasesXml = '';
    extraPurchases.forEach((extra) => { extraPurchasesXml += `<Item No="${extra.id}" Quantity="${extra.quantity}" />`; });
    return soapbuilder.voucherXml(organizerId, showtimeId, extraPurchasesXml);
}

function addVouchersCallback(xml) {
    const json = xml2js.parseString(xml);
    const transaction = json.envelope.body.addVoucherResponse.addVoucherResult.message.transaction;
    const answer = json.envelope.body.addVoucherResponse.addVoucherResult.message.answer;
    if (answer._result === true) return extractFromTransaction(transaction);
    else {
        logError('AddVouchers', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// RESERVE
export function reserve(transactionId, phone, name, password, email, newsletters, smsReminder, zipCode, note) {
    const soap = soapbuilder.reserve(transactionId, '', phone, name, password, email, '0.0.0.0', 0, newsletters, smsReminder, zipCode, note);
    return xmlFetch('POST', url, soap, reserveCallback);
}

function reserveCallback(xml) {
    const json = xml2js.parseString(xml);
    const answer = json.envelope.body.reserveResponse.reserveResult.message.answer;
    const transaction = json.envelope.body.reserveResponse.reserveResult.message.transaction;
    if (answer._result === true) return extractFromTransaction(transaction);
    else {
        logError('Reserve', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// PRE BOOK
export function preBook(transactionId, phone, name, password, email, creditCardCode) {
    const soap = soapbuilder.preBook(transactionId, '', phone, name, password, email, cardholder, creditCardCode, 0);
    return xmlFetch('POST', url, soap, preBookCallback);
}

function preBookCallback(xml) {
    const json = xml2js.parseString(xml);
    const message = json.envelope.body.preBookResponse.preBookResult.message;
    const answer = message.answer;
    const dibs = message.dibs;
    if (answer._result === true) {
        const seats = extractSeats(message.transaction.seats);
        return { dibs, seats };
    }
    else {
        logError('PreBook', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// BOOK
export function book(transactionId, phone, name, password, email, reference, orderId, amount, newsletters, paytype, zipCode, note) {
    const soap = soapbuilder.book(transactionId, '', phone, name, password, email, cardholder, reference, orderId, amount, '0.0.0.0', 0, newsletters, zipCode, note);
    return xmlFetch('POST', url + '?paytype=' + paytype, soap, bookCallback);
}

function bookCallback(xml) {
    const json = xml2js.parseString(xml);
    const answer = json.envelope.body.bookResponse.bookResult.message.answer;
    const transaction = json.envelope.body.bookResponse.bookResult.message.transaction;
    if (answer._result === true) return extractFromTransaction(transaction);
    else {
        logError('Book', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// CANCEL RESERVATION
export function cancelReservation(reservationId, phone, password) {
    const soap = soapbuilder.cancelReservation(reservationId, '', phone, password, '0.0.0.0', 0);
    return xmlFetch('POST', url, soap, cancelReservationCallback);
}

function cancelReservationCallback(xml) {
    const json = xml2js.parseString(xml);
    const answer = json.envelope.body.cancelReservationResponse.cancelReservationResult.message.answer;
    if (answer._result === true) return answer._result;
    else {
        logError('CancelReservation', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// ACTIVATE RESERVATION
export function activateReservation(reservationId) {
    const soap = soapbuilder.activateReservation(reservationId);
    return xmlFetch('POST', url, soap, activateReservationCallback);
}

function activateReservationCallback(xml) {
    const json = xml2js.parseString(xml);
    const transaction = json.envelope.body.activateReservationResponse.activateReservationResult.message.transaction;
    const answer = json.envelope.body.activateReservationResponse.activateReservationResult.message.answer;
    if (answer._result === true) return extractFromTransaction(transaction);
    else {
        logError('ActivateReservation', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// GET RESERVATION
export function getReservation(reservationId) {
    const soap = soapbuilder.getReservation(reservationId);
    return xmlFetch('POST', url, soap, getReservationCallback);
}

function getReservationCallback(xml) {
    const json = xml2js.parseString(xml);
    const transaction = json.envelope.body.getReservationInfoResponse.getReservationInfoResult.message.transaction;
    const answer = json.envelope.body.getReservationInfoResponse.getReservationInfoResult.message.answer;
    if (answer._result === true) return extractFromTransaction(transaction);
    else {
        logError('getReservation', answer);
        throw {
            code: answer._code,
            type: answer._error,
            message: answer._message
        };
    }
}

// CANCEL TRANSACTION
export function cancelTransaction(transactionId) {
    const soap = soapbuilder.cancelTransaction(transactionId);
    return xmlFetch('POST', url, soap, cancelTransactionCallback);
}

function cancelTransactionCallback(xml) {
    const json = xml2js.parseString(xml);
    const answer = json.envelope.body.cancelTransactionResponse.cancelTransactionResult.message.answer;
    if (answer._result === true) return answer._result;
    else {
        logError('CancelTransaction', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}

// GET USER
export function getUser(email, password) {
    const soap = soapbuilder.getUser(email, password);
    return xmlFetch('POST', url, soap, getUserCallback);
}

function getUserCallback(xml) {
    const json = xml2js.parseString(xml);
    const user = json.envelope.body.getUserResponse.getUserResult.message.user;
    const answer = json.envelope.body.getUserResponse.getUserResult.message.answer;
    if (answer._result === true) {
        const cookie = localStorageUtils.getItem('customer');

        return {
            clubCardId: user._no,
            customer: {
                name: user.firstName + ' ' + user.lastName,
                phone: user.phone ? user.phone : '',
                email: user.email,
                repeatEmail: user.email
            },
            creditCard: cookie ? cookie.credtCard : null
        }
    }
    else {
        logError('GetUser', answer);
        throw {
            code: answer._code,
            type: answer._error
        };
    }
}