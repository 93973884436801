import React from 'react'
import _ from 'lodash'

export default class Info extends React.Component {
    constructor() {
        super();
        this.state = { show: false };
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        setTimeout(() => { this.setState({ show: true }); }, 0) // css transition workaround

        const body = document.querySelector('body');
        body.classList.add('no-scroll');
    }

    close() {
        const { canClose } = this.props;
        if (canClose) {
            const body = document.querySelector('body');
            body.classList.remove('no-scroll');

            this.setState({ show: false });
        }
    }

    render() {
        const { show } = this.state;
        const { title, text, buttons } = this.props;

        return (
            <div className={`Info overlay ${show ? ' fade-in' : ' fade-out'}`} onClick={this.close}>
                <div className={`overlay-content ${show ? ' slide-down' : ' slide-up'}`}>
                    <h1>{title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: text.replace(/(?:\r\n|\r|\n)/g, '<br />') }} />
                    <div className="buttons">{buttons}</div>
                </div>
            </div>
        )
    }
}