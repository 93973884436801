import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../movielist-constants'
import LOCALE from '../MovieListLocale'
import { setItemClasses } from '../shared'
import AllMoviesListItem from './AllMoviesListItem'

export default class AllMovieList extends React.Component {
    generateMovieListItems(movies) {
        const { posterBanners, appLocale, movieClicked, showtimeClicked, columns, showLocationOnShowtime, showAsBase, appHeightChanged, organizerIds, showExpired, filterId } = this.props;
        let sortedMovies = this.sortMovies(movies);
        let movieItems = [];
        let counter = 0;

        _.forEach(sortedMovies, (movie) => {
            let rowClasses = setItemClasses(counter, columns);

            movieItems.push(<AllMoviesListItem key={movie.id}
                appLocale={appLocale}
                rowClasses={rowClasses}
                columns={columns}
                showAsBase={showAsBase}
                movie={movie}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                showtimeClicked={showtimeClicked}
                showLocationOnShowtime={showLocationOnShowtime}
                appHeightChanged={appHeightChanged}
                organizerIds={organizerIds}
                showExpired={showExpired}
                filterId={filterId}
            />);

            counter++;
        });

        return movieItems;
    }

    sortMovies(movies) {
        const { sortBy, showtimes, showAsBase, selectedDate, removeToday, showExpired } = this.props;

        let sortedMovies = [];
        _.forEach(movies, (movie) => {
            let removeFromList = [];

            movie.validShowtimes = _.filter(showtimes, (showtime) => {
                if ((showtime.movieId == movie.id || (showAsBase && showtime.movieBaseId > 0 && showtime.movieBaseId == movie.baseId))) {
                    const datetime = moment(showtime.dateTime);
                    const now = moment();
                    const isInFuture = datetime > now;

                    const formattedDate = datetime.format('YYYY-MM-DD');
                    const isToday = removeToday && formattedDate === selectedDate;
                    if (isToday && datetime.isAfter(now)) removeFromList.push(movie.id);

                    if (showtime.showInfo.enabled !== true || isToday) return false;
                    else return sortBy == CONSTANTS.sort.alphabet || sortBy == CONSTANTS.sort.time && showExpired || sortBy == CONSTANTS.sort.time && isInFuture
                }
            });

            if (!_.includes(removeFromList, movie.id) && movie.validShowtimes.length > 0) {
                movie.validShowtimes = _.sortBy(movie.validShowtimes, ['dateTime']);
                sortedMovies.push(movie);
            }
        });

        if (sortBy === CONSTANTS.sort.alphabet) sortedMovies = _.sortBy(sortedMovies, ['name']);
        else if (sortBy === CONSTANTS.sort.time)
            sortedMovies.sort((a, b) => { return moment(a.validShowtimes[0].dateTime) - moment(b.validShowtimes[0].dateTime); });

        return sortedMovies;
    }

    render() {
        const { movies, appLocale } = this.props;
        const movieListItems = this.generateMovieListItems(movies);

        return (
            <div className="MovieList">
                {movieListItems.length > 0 ? movieListItems : <h3 className="no-events">{LOCALE[appLocale].noMoviesText}</h3>}
            </div>)
    }
}