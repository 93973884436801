export default {
    types: {
        normal: 'Normal',
        special: 'Special'
    },

    showtimeListType: {
        timesOnly: 'timesOnly',
        dateTime: 'dateTime'
    },
    
    columns: {
        one: 1,
        two: 2,
        five: 5,
        six: 6
    },
    
    sort: {
        alphabet: 'alphabet',
        time: 'time'
    },

    hourWhenDateShifts: 3,

    listTypes: {
        all: 'all',
        allFuture: 'all-future',
        date: 'date',
        future: 'future',
        special: 'special',
        external: 'external'
    }
}