import React from 'react'
import _ from 'lodash'
import SelectedSeats from './SelectedSeats'
import SeatColors from './SeatColors'
import LOCALE from '../OrderLocale'

export default class SeatSelection extends React.Component {
    constructor() {
        super();
        this.selectedSeatsChanged = this.selectedSeatsChanged.bind(this);
    }

    selectedSeatsChanged(e) {
        const { selected, moveSeats, setError, appLocale } = this.props;

        if (selected.tickets.length > 0) {
            setError('');
            const target = e.target;
            const boundingClientRect = target.getBoundingClientRect();
            let x = (e.pageX - boundingClientRect.left) - window.pageXOffset;
            let y = (e.pageY - boundingClientRect.top) - window.pageYOffset;

            if (target.naturalWidth != target.width) {
                x = x * target.naturalWidth / target.width;
                y = y * target.naturalHeight / target.height;
            }
            moveSeats(selected.transactionId, parseInt(x), parseInt(y));
        } else setError(LOCALE[appLocale].chooseSeats);
    }

    render() {
        const { showtime, seatsImage, selected, configuration, error, showReceiptData, receipt, appLocale } = this.props;
        const seatsImageClass = selected.tickets.length === 0 && !selected.vouchers.active ? 'dim' : 'fade-in';
        const errorMessage = error ? <div className="error">{error}</div> : null;
        
        const seats = showReceiptData ? receipt.seatTexts : selected.seatTexts;
        const prefix = selected.seats.length == 0 ? null : (selected.seats.length > 1 ? LOCALE[appLocale].multiSeats : LOCALE[appLocale].oneSeat);

        return (
            <div className="SeatSelection">
                {errorMessage}
                {showtime.hasNumberedSeats ?
                    <div className="seat-container">
                        <img className={seatsImageClass} src={seatsImage} onClick={this.selectedSeatsChanged} />
                        <SelectedSeats appLocale={appLocale} hasNumberedSeats={showtime.hasNumberedSeats} selectedSeats={seats} label={prefix} />
                        <SeatColors configuration={configuration.showReservedAsRed} appLocale={appLocale} />
                    </div>
                    : <h2 className="unnumbered" dangerouslySetInnerHTML={{ __html: LOCALE[appLocale].showtimeUnNumbered }}></h2>
                }
            </div>
        )
    }
}