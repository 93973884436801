import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import Showtime from './Showtime'
import ShowtimeTypeList from './ShowtimeTypeList';

export default class ShowtimeSchedule extends React.Component {
    constructor(props) {
        super(props);
        const schedule = this.extractShowtimeSchedule(props.movie);
        this.state = {
            schedule,
            skip: 0,
            take: 0
        }

        this.nextWeek = this.nextWeek.bind(this)
        this.previousWeek = this.previousWeek.bind(this)
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.movie.id != nextProps.movie.id || this.props.appLocale != nextProps.appLocale)
            this.setState({ schedule: this.extractShowtimeSchedule(nextProps.movie) });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.setState({ take: this.determineTakeFromScreenWidth() });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = _.debounce(() => { this.setState({ take: this.determineTakeFromScreenWidth() }); }, 400)

    determineTakeFromScreenWidth() {
        const schedule = document.querySelector('.schedule');
        const scheduleWidth = Math.max(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth);
        return Math.round(scheduleWidth / 120); //120 being the width wanted for the Showtimes
    }

    nextWeek() {
        const { schedule, skip, take } = this.state;
        if (skip < schedule.length) this.setState({ skip: skip + take });
    }

    previousWeek() {
        const { skip, take } = this.state;
        if (skip > 0) this.setState({ skip: skip - take });
    }

    showtimeTypes = [];
    extractShowtimeSchedule(movie) {
        const { showtimeClicked, showExpired } = this.props;
        let schedule = [];
        _.forOwn(movie.showtimesByDate, (showtimes, dateKey) => {
            const date = moment(dateKey);

            const showtimesOnDate = [];
            _.each(showtimes, (showtime) => {
                if (showtime.showInfo.enabled && showtime.type && !_.find(this.showtimeTypes, showtime.type)) this.showtimeTypes.push(showtime.type);

                const isInFuture = moment(showtime.dateTime) > moment();
                if (showtime.showInfo.enabled === true && (showExpired || isInFuture)) {
                    showtimesOnDate.push(
                        <Showtime key={showtime.id}
                            showtime={showtime}
                            text={moment(showtime.dateTime).format('LT')}
                            showtimeClicked={showtimeClicked}
                            showExpired={showExpired}
                        />);
                }
            });

            if (showtimesOnDate.length > 0) {
                const showtimesOnDay = <div key={dateKey} className="showtimes">
                    <div className="date">{_.capitalize(date.format('ddd DD/MM'))}</div>
                    {showtimesOnDate}
                </div>;
                schedule.push(showtimesOnDay);
            }
        });
        return schedule;
    }

    showtimesOnPage() {
        const { schedule, skip, take } = this.state;
        let showtimesOnPage = _.take(_.drop(schedule, skip), take);

        if (showtimesOnPage.length > 0 && showtimesOnPage.length < take) {
            var emptyElementsNeeded = take - showtimesOnPage.length;
            for (var i = 0; i < emptyElementsNeeded; i++) {
                showtimesOnPage.push(<div key={i} className="showtimes"></div>);
            }
        }
        return showtimesOnPage;
    }

    render() {
        const { schedule, skip, take } = this.state;
        const { isMultiBio, filterId } = this.props;
        const disabled = 'week-nav disabled';
        const prevWeekClasses = skip !== 0 ? 'week-nav' : disabled;
        const nextWeekClasses = skip + take < schedule.length ? 'week-nav' : disabled;
        const showNavs = true; //prevWeekClasses != disabled && nextWeekClasses != disabled;
        const showtimeOnPage = this.showtimesOnPage();

        return (
            <div className="ShowtimeSchedule">
                {showNavs ? <div className={prevWeekClasses} onClick={this.previousWeek}>&#10094;</div> : <div className={prevWeekClasses}></div>}
                <div className="schedule">{showtimeOnPage}</div>
                {showNavs ? <div className={nextWeekClasses} onClick={this.nextWeek}>&#10095;</div> : <div className={nextWeekClasses}></div>}

                {!isMultiBio && !filterId && this.showtimeTypes.length > 0 ? <ShowtimeTypeList types={this.showtimeTypes} /> : null}
            </div>
        )
    }
}