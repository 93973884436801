import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../components/movielists/movielist-constants'
import { ROUTES } from '../components/navigation/route-constants'

import {
    MOVIELIST_REQUEST,
    MOVIELIST_SUCCESS,
    MOVIELIST_FAILED,
    FUTURE_MOVIES_REQUEST,
    FUTURE_MOVIES_SUCCESS,
    FUTURE_MOVIES_FAILED,
    SELECTED_MOVIE_CHANGED,
    SELECTED_SHOWTIME_CHANGED,
    TOGGLE_SORT,
    CHANGE_DATE,
    CHANGE_MONTH
} from '../actions/movielist-actions'

export default function movies(state = {
    isFetching: false,
    organizerIds: '',
    organizers: [],
    movies: [],
    movieBases: [],
    futureMovies: [],
    showtimes: [],
    datesWithShowtimes: [],
    selectedOrganizer: {},
    selectedMovie: {},
    selectedShowtime: {},
    initialDate: moment().format('YYYY-MM-DD'),
    selectedDate: moment().format('YYYY-MM-DD'),
    selectedMonth: moment().month(),
    sort: CONSTANTS.sort.alphabet,
    backTo: ROUTES.PROGRAM,
    showBackInTime: false,
    error: null,
    filterId: null
}, action) {
    switch (action.type) {
        case MOVIELIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null
            }
        case MOVIELIST_SUCCESS:
            const dateTimeNow = moment();
            const initialDate = dateTimeNow.format('YYYY-MM-DD');
            const initialMonth = dateTimeNow.month();
                        
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.succeededAt,
                organizerIds: action.organizerIds,
                organizers: action.organizers,
                movies: action.movies,
                movieBases: action.movieBases,
                showtimes: action.showtimes,
                showtimeTypes: action.showtimeTypes,
                datesWithShowtimes: action.datesWithShowtimes,
                initialDate,
                initialMonth,
                selectedDate: action.selectedDate ? action.selectedDate : initialDate,
                selectedMonth: action.selectedDate ? moment(action.selectedDate).month() : initialMonth,
                sort: action.sort,
                showBackInTime: action.showBackInTime,
                backTo: action.backTo,
                filterId: action.filterId
            }
        case MOVIELIST_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case FUTURE_MOVIES_REQUEST:
            return {
                ...state,
                error: null
            }
        case FUTURE_MOVIES_SUCCESS:
            return {
                ...state,
                lastUpdated: action.succeededAt,
                futureMovies: action.futureMovies,
                error: null
            }
        case FUTURE_MOVIES_FAILED:
            return {
                ...state,
                error: action.error
            }
        case TOGGLE_SORT:
            return {
                ...state,
                sort: action.sort,
            }
        case CHANGE_DATE:
            return {
                ...state,
                selectedDate: action.selectedDate,
            }
        case CHANGE_MONTH:
            return {
                ...state,
                selectedMonth: action.selectedMonth,
            }
        case SELECTED_MOVIE_CHANGED:
            return {
                ...state,
                selectedMovie: action.movie,
                backTo: action.backTo && action.backTo !== state.backTo ? action.backTo : state.backTo
            }
        case SELECTED_SHOWTIME_CHANGED:
            return {
                ...state,
                selectedShowtime: action.selectedShowtime
            }
        default:
            return state
    }
}