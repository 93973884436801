export default {
    da: {
        startOver: 'Start forfra',
        reservationSuccess: 'Reservation gennemført på',
        purchaseSuccess: 'Køb gennemført på',
        name: 'Navn',
        email: 'Email',
        print: 'Print',
        reserve: 'Reserver',
        buy: 'Køb',
        delete: 'Afbestil',
        deleteText: 'Ønsker du at afbestille reservationen?',
        deletedText: 'Din reservation er afbestilt',
        confirm: 'Ja',
        cancel: 'Nej',
        pickUp: 'Afhentes'
    },

    en: {
        startOver: 'Start over',
        reservationSuccess: 'Reservation completed on',
        purchaseSuccess: 'Purchase completed on',
        name: 'Name',
        email: 'Email',
        print: 'Print',
        reserve: 'Reserve',
        buy: 'Buy',
        delete: 'Cancel',
        deleteText: 'Do you wish to cancel your reservation?',
        deletedText: 'Your reservation is cancelled',
        confirm: 'Yes',
        cancel: 'No',
        pickUp: 'Pick up'
    }
}