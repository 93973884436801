export default {
    da: {
        price: 'Pris: ',
        including: 'inkl. gebyr',
        back: '❮  Tilbage',
        buyTickets: 'Køb Billetter',
        soldOut: 'Udsolgt',
        cancelled: 'Aflyst',
    },

    en: {
        price: 'Price: ',
        including: 'incl. fees',
        back: '❮  Back',
        buyTickets: 'Buy Tickets',
        soldOut: 'Sold out',
        cancelled: 'Cancelled',
    }
}