import '../../movielists/css/movielists.scss'

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../../movielists/movielist-constants'
import LOCALE from '../../movielists/MovieListLocale'
import { getEventList, getEvent } from '../../../actions/ticketmaster-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import TicketmasterListItem from './TicketmasterListItem'

class TicketmasterListContainer extends React.Component {
    constructor() {
        super();
        this.eventClicked = this.eventClicked.bind(this);
    }

    componentDidMount() {
        const { promoterIds, actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);

        const queryParameters = getParams();
        actions.getEventList(queryParameters.promoterid ? queryParameters.promoterid : promoterIds);

    }

    eventClicked(id) {
        const { organizerId, actions, history } = this.props;
        actions.getEvent(id).then(() => history.push(`${ROUTES.TICKETMASTER_EVENT}/${id}?org=${organizerId}`));;
    }

    generateEventListItems() {
        const { events, organizerId, appLocale, listType } = this.props;
        let eventItems = [];
        let counter = 0;

        _.forEach(events, (event) => {
            let rowClasses = this.calculateRowPlacement(counter, 'MovieListItem five-columns external');
            if (event.isSoldOut || event.isCancelled) rowClasses += ' disabled';

            eventItems.push(<TicketmasterListItem key={event.id}
                listType={listType}
                appLocale={appLocale}
                rowClasses={rowClasses}
                organizerId={organizerId}
                event={event}
                eventClicked={this.eventClicked}
            />);

            counter++;
        });

        return eventItems;
    }

    calculateRowPlacement(counter, rowClasses) {
        if (counter > 1) {
            for (let i = 2; i <= 7; i++) {
                if (counter % i == 0) rowClasses += ' first-in-row' + i;
            }
        }
        return rowClasses;
    }

    render() {
        const { appLocale } = this.props;
        const eventListItems = this.generateEventListItems();

        return (
            <div className={`ExternalList ${CONSTANTS.listTypes.external} five-columns`}>
                {eventListItems.length > 0 ?
                    <div className="MovieList">{eventListItems}</div> : <h3 className="no-events">{LOCALE[appLocale].noEventsText}</h3>}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;

    return {
        organizerId: state.organizer.id,
        promoterIds: config.ticketmaster.promoterCodes,
        events: state.ticketmaster.events,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getEventList, getEvent, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketmasterListContainer)