import './transactions.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as userActions from '../../../actions/user-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import Login from './TransactionsLogin'
import TransactionList from './TransactionList'
import TransactionReceipt from './TransactionReceipt';

class TransactionsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showReceipt: false,
            transaction: null,
            noEntries: null
        };

        this.submitLogin = this.submitLogin.bind(this);
        this.toggleReceipt = this.toggleReceipt.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    submitLogin(email, password) {
        const { actions } = this.props;
        actions.getUserTransactions(email, password).then((result) => {
            if (result.transactions.length == 0) this.setState({ noEntries: true });
        });
    }

    toggleReceipt(transaction) {
        const show = this.state.showReceipt;
        if (show == true) {
            document.body.classList.remove('no-scroll');
            this.setState({ showReceipt: false });
        }
        else {
            document.body.classList.add('no-scroll');
            this.setState({ showReceipt: true, transaction });
        }
    }

    render() {
        const { transactions, pageHeader, appLocale, customer, organizerType } = this.props;
        const { showReceipt, transaction, noEntries } = this.state;
        const header = pageHeader ? <div className="page-header" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        const login = transactions.length == 0 ? <Login error={noEntries} customer={customer} submit={this.submitLogin} appLocale={appLocale} /> : null;

        return (
            <div className="TransactionsContainer">
                {header}
                {login}
                <TransactionList transactions={transactions} toggleReceipt={this.toggleReceipt} appLocale={appLocale} organizerType={organizerType} />
                {showReceipt ? <TransactionReceipt transaction={transaction} fadeIn={showReceipt} toggleReceipt={this.toggleReceipt} appLocale={appLocale} /> : null}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        transactions: state.user.transactions,
        pageHeader: state.organizer.configuration.pageHeaders.myTickets,
        appLocale: state.app.locale,
        customer: state.customer,
        organizerType: state.organizer.configuration.type,
        iframeId: state.app.iframeId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, userActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsContainer)