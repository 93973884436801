import React from 'react'
import _ from 'lodash'
import moment from 'moment'

export default class MonthNavigator extends React.Component {

    constructor() {
        super();
        this.nextMonth = this.nextMonth.bind(this);
        this.previousMonth = this.previousMonth.bind(this);
    }

    nextMonth() {
        const { monthsWithShowtimes, selectedMonth, changeMonth } = this.props;
        const nextMonth = monthsWithShowtimes[monthsWithShowtimes.indexOf(selectedMonth) + 1];
        if (nextMonth != -1) changeMonth(nextMonth);
    }

    previousMonth() {
        const { monthsWithShowtimes, selectedMonth, changeMonth } = this.props;
        const previousMonth = monthsWithShowtimes[monthsWithShowtimes.indexOf(selectedMonth) - 1];
        if (previousMonth != -1) changeMonth(previousMonth);
    }

    render() {
        const { selectedMonth, monthsWithShowtimes, stickNavToTop } = this.props;
        let previousMonth = null;
        let monthDisplay = null;
        let nextMonth = null;

        previousMonth = monthsWithShowtimes.indexOf(selectedMonth) - 1 > -1 ?
            <span className="date-nav" onClick={this.previousMonth}>&#10094;</span> : <span className="date-nav disabled">&#10094;</span>;

        nextMonth = monthsWithShowtimes.indexOf(selectedMonth) < monthsWithShowtimes.length - 1 ?
            <span className="date-nav" onClick={this.nextMonth}>&#10095;</span> : <span className="date-nav disabled">&#10095;</span>;

        const month = _.capitalize(moment.months()[selectedMonth]);
        const shortMonth = _.capitalize(moment.monthsShort()[selectedMonth]);

        monthDisplay = <div className="date"><span className="month">{month}</span><span className="short-month">{shortMonth}</span></div>;

        return (
            <div className={stickNavToTop ? 'stick-nav-to-top MonthNavigator' : 'MonthNavigator'}>
                {previousMonth}
                {monthDisplay}
                {nextMonth}
            </div>
        );
    }
}