import React from 'react'
import _ from 'lodash'

const Pictures = ({ pictureUrls }) => {
    const images = _.map(pictureUrls, (picture, i) => <img key={i} src={picture} />);

    return (
        <div className="Pictures" >
            {images}
        </div>)
}

export default Pictures