import fetch from 'isomorphic-fetch'
import { getParams } from '../utilities/location-utilities'

export default {
    fetchMovieList,
    fetchMovie,
    fetchUserTransactions,
    fetchNewsletters,
    postNewsletter,
    postLog
}

const baseUrl = window.location.href.indexOf('localhost') != -1 ? 'https://localhost:44390/' : window.location.protocol + '//' + window.location.host + '/api/';

function fetchMovieList(organizerIds, daysBack) {
    const queryParameters = getParams(window.location.href);
    const filterId = queryParameters.filter || null;
    const updateCache = queryParameters.updateCache || false;
    const querystring = `?organizerIds=${organizerIds}${daysBack ? '&daysBack=' + daysBack : ''}${filterId ? '&filterId=' + filterId : ''}${updateCache ? '&updateCache=' + updateCache : ''}`;
    return fetch(baseUrl + `movies${querystring}`).then(response => {
        if (response.ok) return response.json();
        else throw response
    });
}

function fetchMovie(movieId, organizerIds) {
    const url = `movies/${movieId}?organizerIds=${organizerIds}`;
    return fetch(baseUrl + url).then(response => {
        if (response.ok) return response.json();
        else throw response
    });
}

function fetchUserTransactions(emailOrPhone, password) {
    return fetch(`${baseUrl}transactions?emailOrPhone=${emailOrPhone}&password=${password}`).then(response => {
        if (response.ok) return response.json();
        else throw response
    });
}


function fetchNewsletters(organizerId, customerEmail) {
    return fetch(`${baseUrl}newsletters?organizerId=${organizerId}&customerEmail=${customerEmail}`).then(response => {
        if (response.ok) return response.json();
        else throw response
    });
}

function postNewsletter(body) {
    return fetch(`${baseUrl}newsletters`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    });
}

function postLog(body) {
    return fetch(baseUrl + 'logs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    });
}


export function logTrace(method, message, logLevel = 'information') {
    const body = {
        logLevel,
        entry: {
            origin: 'Frontend',
            method,
            message
        },
        client: {
            id: 1,
            name: 'OrderFlow.WebApp',
            url: window.location.href
        }
    }

    postLog(body);
}

export function sendLogSms(message) {
    return fetch(baseUrl + 'logs/send-sms', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(message)
    });
}