export default function error(
    state = {
        code: null,
        type: null,
        message: null,
        close: null,
        show: true
    }, action) {

    if (!action.error) {
        return {
            ...state,
            code: null,
            type: null,
            message: null,
            close: null,
            show: null
        }
    }

    return {
        ...state,
        code: action.error.code,
        type: action.error.type,
        message: action.error.message,
        close: action.error.close,
        show: action.error.show == null ? true : action.error.show // default true, but can be set explicitly to false
    }

    return state;
}