import OrderFlowLocale from '../OrderFlowLocale'

export default {
    da: {
        ...OrderFlowLocale.da,
        extraPurchases: 'Tilkøb',
        price: 'Pris'
    },

    en: {
        ...OrderFlowLocale.en,
        extraPurchases: 'Extras',
        price: 'Price'
    }
}