import fetch from 'isomorphic-fetch'

export default {
    fetchCommercials
}

export const baseUrl = window.location.href.indexOf('localhost') != -1 ? 'http://devadmin.tickets.dk' : 'http://admin.ebillet.dk'

async function fetchCommercials(organizerId) {
    return await fetch(`${baseUrl}/api/linkblocks?organizerId=${organizerId}`).then(response => {
        if (response.ok) return response.json();
        else throw response
    });
}