import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { logTrace } from '../../../api/orderflow-api'
import { resetOrder } from '../../../actions/order-actions'
import { book } from '../../../actions/customer-actions'
import { gaTrackCheckoutStep } from '../../../actions/ga-actions'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'

class DibsContainer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const { checkoutComplete, customer, actions, history, sessionState, showtime, organizerId, transactionId } = this.props;
        actions.gaTrackCheckoutStep(3);

        const querystrings = getParams();

        //new dibs transaction
        if (!querystrings.cancel && !querystrings.orderid && !_.isEmpty(customer.dibs.parameters) && document.dibsform) {
            localStorage.setItem('beforeDibsState', JSON.stringify(sessionState));
            const message = `transactionId: ${transactionId}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('DibsContainer - NEW TRANSACTION', message);
            document.dibsform.submit();
        }

        // cancel dibs transaction
        else if (querystrings.cancel) {
            const message = `transactionId: ${transactionId}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('DibsContainer - CANCEL TRANSACTION', message);
            localStorage.removeItem('beforeDibsState');
            actions.resetOrder();
            window.location.href = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`;
        }

        // complete dibs transaction
        else if (checkoutComplete) {
            const message = `transactionId: ${transactionId}, orderId: ${querystrings.orderid}, dibs transact: ${querystrings.transact}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}, payType: ${querystrings.paytype}`;
            logTrace('DibsContainer - COMPLETED TRANSACTION', message);
            localStorage.removeItem('beforeDibsState');
            history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
        }
    }

    generateInputs(dibs) {
        const { showtime, organizerId } = this.props;
        const callback = window.location.origin + `/dibs/${showtime.movieId}/${showtime.id}?org=${organizerId}`;
        let inputs = _.map(dibs.parameters, function (value, key) { return <input key={key} type="hidden" name={key} value={value ? value : ''} /> });
        inputs.push(<input type="hidden" key="dibsaccepturl" name="accepturl" value={callback} />);
        inputs.push(<input type="hidden" key="decorator" name="decorator" value="responsive" />);
        inputs.push(<input type="hidden" key="fullreply" name="fullreply" value="yes" />);
        return inputs;
    }

    render() {
        const dibs = this.props.customer.dibs;
        const inputs = this.generateInputs(dibs);
        const form = dibs.url && inputs ? <form name="dibsform" method="post" action={dibs.url}>{inputs}</form> : null;
        return (
            <div className="DibsContainer">
                {form}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        checkoutComplete: state.customer.checkoutComplete,

        //for session storage after dibs payment on seperate page
        sessionState: {
            organizer: state.organizer,
            movielist: {
                selectedMovie: { ...state.movielist.selectedMovie, versions: [] },
                selectedShowtime: state.movielist.selectedShowtime
            },
            order: { ...state.order, seatsImage: null },
            customer: state.customer,
            user: state.user,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ book, gaTrackCheckoutStep, resetOrder }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DibsContainer)