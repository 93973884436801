import _ from 'lodash'
import { VOUCHER_TYPES } from '../api-constants';

export default function extractAvailableTicketsAndExtraPurchases(arrangement, bonusProfiles) {
    const availableTickets = arrangement.ticket ? ((Array.isArray(arrangement.ticket) ? arrangement.ticket : [arrangement.ticket])) : [];
    const voucherConstraints = arrangement.availableVouchers ? ((Array.isArray(arrangement.availableVouchers.availableVoucher) ? arrangement.availableVouchers.availableVoucher : [arrangement.availableVouchers.availableVoucher])) : [];
    const extraPurchases = arrangement.concessions ? ((Array.isArray(arrangement.concessions.item) ? arrangement.concessions.item : [arrangement.concessions.item])) : [];
    const clubExtraPurchases = arrangement.bonusVouchers ? ((Array.isArray(arrangement.bonusVouchers.item) ? arrangement.bonusVouchers.item : [arrangement.bonusVouchers.item])) : [];
    const clubCard = bonusProfiles ? ((Array.isArray(bonusProfiles.bonusProfile) ? bonusProfiles.bonusProfile[0] : bonusProfiles.bonusProfile)) : null;

    const tickets = extractAvailableTickets(availableTickets);
    const vouchers = extractvoucherConstraints(voucherConstraints);
    const bioKlubDk = { available: arrangement._bKDRedeemOnLine, image: arrangement._bKDCuponImage };
    return {
        id: arrangement._no,
        bioKlubDk,
        availableTickets: tickets,
        voucherConstraints: vouchers,
        reserveMax: arrangement._resMax,
        buyMax: arrangement._saleMax,
        extraPurchases: extractExtraPurchases(extraPurchases, clubExtraPurchases),
        discountEnabled: arrangement._discountCodesAvailable,
        discountActivated: arrangement._discountCodesActivated,
        clubCard: clubCard ? {
            id: clubCard._no,
            name: clubCard.name,
            customer: clubCard._customer,
            points: clubCard._points
        } : null
    };
}

function extractAvailableTickets(tickets) {
    tickets.sort((a, b) => {
        if (a._default && !b._default) return -1;
        else if (!a._default && b._default) return 1;
        else return 0;
    });

    let availableTickets = _.map(tickets, (ticket) => {
        return {
            id: ticket._no,
            name: ticket.name,
            type: 'quantity',
            defaultQuantity: ticket.defaultValue,
            price: {
                from: ticket.priceFrom ? parseFloat(ticket.priceFrom.replace(',', '.')) : null,
                to: ticket.priceTo ? parseFloat(ticket.priceTo.replace(',', '.')) : null
            },
            points: ticket.bonusPoints,
            canReserve: ticket._res,
            reserveMax: ticket.resMax,
            canBuy: ticket._buy,
            buyMax: ticket.saleMax
        }
    });

    return availableTickets;
}

function extractvoucherConstraints(voucherConstraints) {
    return _.map(voucherConstraints, (voucher) => {
        return {
            name: voucher._name,
            type: voucher._isBKD ? VOUCHER_TYPES.BIOKLUBDK : VOUCHER_TYPES.GIFTCARD,
            prefix: voucher._prefix,
            length: voucher._len,
            image: voucher._imageLink,
        }
    });
}

export function extractExtraPurchases(extras, clubPurchases) {
    let extraPurchases = _.map(extras, (item) => {
        return {
            id: item._no,
            name: item.name,
            image: item.path,
            note: item.note,
            price: item._price,
            buyMax: item.saleMax,
            buyOnly: item._useOnlyOnDenyReservation
        };
    });

    if (clubPurchases.length > 0) {
        _.forEach(clubPurchases, (item) => {
            extraPurchases.push({
                id: item._no,
                name: item.name,
                image: item.path,
                note: item.note,
                points: item.points,
                buyOnly: item._useOnlyOnDenyReservation
            });
        });
    }
    return extraPurchases;
}