import React from 'react'
import _ from 'lodash'
import LOCALE from '../OrderLocale'

export default class SelectedSeats extends React.Component {

    extractRowsAndSeats() {
        const { hasNumberedSeats, selectedSeats, appLocale } = this.props;
        if (hasNumberedSeats) {
            return _.map(selectedSeats, (seatText, i) => {
                return <div key={i}>{seatText}</div>;
            });
        } else return LOCALE[appLocale].seatsUnNumbered;
    }

    render() {
        const { label } = this.props;
        const rowsAndSeats = this.extractRowsAndSeats();
        return (
            <div className="SelectedSeats">
                <span>{label}</span>
                <div className="label">{rowsAndSeats}</div>
            </div>
        )
    }
}