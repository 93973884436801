import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { ROUTES } from '../../navigation/route-constants'
import LOCALE from '../MovieListLocale'
import Showtime from '../../showtime/Showtime'
import Poster from '../../movie/Poster'
import ShowtimeTypeList from '../../showtime/ShowtimeTypeList'

export default class SpecialEventsListItem extends React.Component {
    constructor() {
        super();
        this.movieClicked = this.movieClicked.bind(this);
    }

    movieClicked(e) {
        const { movieClicked } = this.props;
        const dataset = e.target.dataset;
        e.preventDefault();

        const movieId = e.currentTarget.id || dataset.movieId;
        if (e.target.tagName == 'IMG' || e.target.tagName == 'H1' || e.target.tagName == 'INPUT' || _.includes(e.target.classList, 'poster-banner'))
            movieClicked(movieId);
    }

    showtimeTypes = [];
    generateShowtimesInMonth() {
        const { movie, showtimeClicked, appLocale } = this.props;
        this.showtimeTypes = []; //reset it so types don't bleed into months where they don't apply.

        return <div className="showtimes">
            {_.map(movie.validShowtimes, (showtime) => {
                if (showtime.type && !_.find(this.showtimeTypes, showtime.type)) this.showtimeTypes.push(showtime.type);

                const dateTime = moment(showtime.dateTime);
                return <div key={showtime.id} className="special-event">
                    {_.capitalize(dateTime.format(LOCALE[appLocale].shortDateTimeFormat))}

                    <Showtime key={showtime.id}
                        showtime={showtime}
                        text={LOCALE[appLocale].buyTickets}
                        showtimeClicked={showtimeClicked}
                    />
                </div>;
            })}
        </div>
    }

    render() {
        const { movie, rowClasses, appLocale, organizerIds, filterId } = this.props;

        const poster = movie.posters ?
            <a className="poster-container" href={`${window.location.origin}${ROUTES.MOVIE}/${movie.id}?org=${organizerIds}`}>
            <Poster movie={movie} /></a> : null;

        const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;

        return (
            <div className={rowClasses} id={movie.id} onClick={this.movieClicked}>
                {poster}
                <div className="movie-info">
                    <a href={`${window.location.origin}${ROUTES.MOVIE}/${movie.id}?org=${organizerIds}`}><h1>{movieNameLocale}</h1></a>
                    <div className="flex-container">{this.generateShowtimesInMonth()}</div>
                    {!filterId && this.showtimeTypes.length > 0 ? <ShowtimeTypeList types={this.showtimeTypes} includeName={true} /> : null}
                </div>
            </div>
        )
    }
}