import moment from 'moment-timezone'
import 'moment/locale/da'
import _ from 'lodash'
import API_CONSTANTS, { FILTER_TEXTS } from '../api-constants'
import { AVAILABLE_LOCALES, FILTERS } from '../api-constants'
import { getParams } from '../../utilities/location-utilities'

export default function extractOrganizer(organizer) {
    const config = organizer.configuration;
    const smsReminder = config.smsReminder && Array.isArray(config.smsReminder) ? config.smsReminder[0] : config.smsReminder;

    const validCreditCards = organizer.validCreditCards ? organizer.validCreditCards.creditCard : null;
    const creditcards = validCreditCards && Array.isArray(validCreditCards) ? validCreditCards : [validCreditCards];

    const newsGroups = organizer.newsGroups ? organizer.newsGroups.newsGroup : null;
    const newsletters = newsGroups ? (Array.isArray(newsGroups) ? newsGroups : [newsGroups]) : [];

    return {
        id: organizer._no,
        name: organizer._name,
        futureTemplateId: organizer._futureTemplateNo,
        configuration: extractConfigurations(config, smsReminder, newsletters, organizer),
        creditcards: extractCreditCards(creditcards)
    }
}

function extractConfigurations(config, smsReminder, newsletters, organizer) {
    const DEFAULT = API_CONSTANTS.defaultConfiguration;

    const timezone = config.organizer._timeZone || DEFAULT.timezone;
    moment.tz.setDefault(timezone);

    const queryParams = getParams();

    return config ? {
        type: config.organizer._type ? config.organizer._type : DEFAULT.organizerType,
        appMode: config.organizer._appMode || DEFAULT.appMode,
        showReservedAsRed: config.organizer._showReservedAsRed,
        openOrderInNewWindow: config.organizer._openOrderFlowInNewWindow,
        customerPasswordEnabled: config.organizer._customerPasswordEnabled,
        showLocationOnShowtime: config.organizer._showLocationOnEventTime,
        daysBack: config.organizer._showDaysBackInProgram,
        defaultTickets: config.organizer._defaultTickets,
        hasCustomStyles: config.organizer._useCustomStyleSheet || DEFAULT.hasCustomStyles,
        bottomBarAllwaysVisible: config.organizer._bottomBarAllwaysVisible || DEFAULT.bottomBarAllwaysVisible,
        giftCardsEnabled: config.organizer._redeemCuponsOnline || DEFAULT.giftCardsEnabled,
        repeatEmail: config.organizer._confirmEmailInput || DEFAULT.repeatEmail,
        availableLocales: config.organizer._languages ? extractLocales(config.organizer._languages) : [{ id: 'da', name: 'Dansk', flag: '/images/flags/DK.png' }], //default to Danish
        summaryExpanded: config.organizer._summaryExpanded,
        phoneMinLength: organizer.phoneRestrictions.minLength || DEFAULT.phoneMinLength,
        payWithDibs: config.organizer._payWithDIBS || DEFAULT.payWithDibs,
        paymentMethods: config.organizer._reePayMethods,

        texts: config.texts ? _.map(config.texts.text, (text) => {
            return {
                id: text._name,
                da: text._da,
                en: text._en
            }
        }) : {},

        ticketmaster: config.ticketmaster ? {
            promoterCodes: config.ticketmaster._promoterCodes,
            cameFrom: config.ticketmaster._cameFrom,
            brand: config.ticketmaster._brand
        } : {},

        googleAnalytics: config.googleAnalId ? {
            gtmId: config.googleAnalId._gTM,
            uaId: config.googleAnalId._uA
        } : {},

        cookiePolicy: config.cookiePolicy ? {
            title: config.cookiePolicy.title,
            text: config.cookiePolicy.text
        } : DEFAULT.cookiePolicy,

        posterBanners: {
            show3D: config.organizer._show3D,
            showAtmos: config.organizer._showAtmos
        },

        fees: config.fees ? {
            reserveTransaction: +config.fees._reserveTransactionFee.replace(',', '.'),
            reserveTicket: +config.fees._reserveTicketFee.replace(',', '.'),
            buyTransaction: +config.fees._buyTransactionFee.replace(',', '.'),
            buyTicket: +config.fees._buyTicketFee.replace(',', '.')
        } : DEFAULT.fees,

        newsletters: newsletters.length > 0 ? _.map(newsletters, (letter) => {
            return {
                id: letter._no,
                name: letter.name,
                enabled: letter._promptInFlow,
                checked: letter._status
            }
        }) : DEFAULT.newsletters,

        smsReminder: smsReminder ? {
            enabled: smsReminder._enabled,
            checkedByDefault: smsReminder._defaultOn
        } : DEFAULT.smsReminder,

        receiptTexts: config.receiptTexts ? {
            reserve: config.receiptTexts ? config.receiptTexts._reserve : DEFAULT.receiptTexts.reserve,
            buy: config.receiptTexts ? config.receiptTexts._buy : DEFAULT.receiptTexts.buy
        } : DEFAULT.receiptTexts,

        errorTexts: config.errorTexts ? {
            soldOut: config.errorTexts ? config.errorTexts._soldout : DEFAULT.errorTexts.soldOut
        } : DEFAULT.errorTexts,

        gdprTexts: config.gDPRText ? {
            reserve: config.gDPRText ? config.gDPRText._reserve : DEFAULT.gDPRText.reserve,
            buy: config.gDPRText ? config.gDPRText._buy : DEFAULT.gDPRText.buy,
            newsletters: config.gDPRText ? config.gDPRText._newsletter : ''
        } : DEFAULT.gDPRText,

        pageHeaders: config.listHeaders ? extractPageHeaders(config.listHeaders, organizer._name) : DEFAULT.pageHeaders,

        movielists: {
            all: config.eventlist.all ? {
                base: queryParams.base ? queryParams.base == "true" : config.eventlist.all._base,
                columns: queryParams.cols ? parseInt(queryParams.cols) : config.eventlist.all._columns,
                sort: config.eventlist.all._sort,
                showExpired: queryParams.showexpired == "true",
            } : DEFAULT.movielists.all,

            date: config.eventlist.date ? {
                base: queryParams.base ? queryParams.base == "true" : config.eventlist.date._base,
                columns: queryParams.cols ? parseInt(queryParams.cols) : config.eventlist.date._columns,
                sort: config.eventlist.date._sort,
                showAsWeekButtons: queryParams.datebuttons == "true",
                showExpired: queryParams.showexpired == "true",
            } : DEFAULT.movielists.date,

            future: config.futureeventlist.all ? {
                columns: queryParams.cols ? parseInt(queryParams.cols) : config.futureeventlist.all._columns
            } : DEFAULT.movielists.future,

            special: config.specialeventlist ? {
                enabledOnProgram: config.specialeventlist._enabled,
                columns: queryParams.cols ? parseInt(queryParams.cols) : config.specialeventlist.month._columns,
                sort: DEFAULT.movielists.special.sort
            } : DEFAULT.movielists.special
        },

        clubCard: config.bonus ? {
            name: config.bonus._bonusName,
            loginText: DEFAULT.pointsLoginText
        } : null,

        reservationConstraints: config.denyReservation ? extractReservationConstraints(config.denyReservation) : [],

        calendarText: config.programAvailableText ? config.programAvailableText._text : null,
        locationTexts: config.locationText ? extractLocationTexts(config.locationText.text) : null,
        ageCensorIcons: config.censoring ? extractCensorIcons(config.censoring.censor) : null

    } : DEFAULT;
}

function extractPageHeaders(headers, organizerName) {
    const pageHeaders = headers.header ? (Array.isArray(headers.header) ? headers.header : [headers.header]) : null;
    const filterId = parseInt(getParams()).filter || 0;

    const mapped = _.map(pageHeaders, (header) => {
        return {
            type: header._type,
            filter: header._eventTypeNo,
            text: replaceTags(header._text, organizerName, header._eventTypeNo)
        }
    });

    const allMovies = _.find(mapped, (header) => { return header.type == 'allmovies' && header.filter == filterId });
    const allFutureMovies = _.find(mapped, (header) => { return header.type == 'allfuturemovies' && header.filter == filterId });
    const date = _.find(mapped, (header) => { return header.type == 'date' && header.filter == filterId });
    const futureMovies = _.find(mapped, (header) => { return header.type == 'futuremovies' && header.filter == filterId });
    const movie = _.find(mapped, (header) => { return header.type == 'movieinfo' && header.filter == filterId });
    const orderflow = _.find(mapped, (header) => { return header.type == 'flow' && header.filter == filterId });
    const myTickets = _.find(mapped, (header) => { return header.type == 'tickets' && header.filter == filterId });

    return {
        allMovies: allMovies ? allMovies.text : null,
        allFutureMovies: allFutureMovies ? allFutureMovies.text : null,
        date: date ? date.text : null,
        futureMovies: futureMovies ? futureMovies.text : null,
        movie: movie ? movie.text : null,
        orderflow: orderflow ? orderflow.text : null,
        myTickets: myTickets ? myTickets.text : null
    }
}

function replaceTags(headerText, organizerName, filterId) {
    headerText = _.replace(headerText, 'ORG_NAME', organizerName);

    let filterText = FILTER_TEXTS[filterId] + ' ';
    headerText = _.replace(headerText, 'EVENT_TYPE ', filterText);

    return headerText;
}

function extractCreditCards(creditcards) {
    return _.map(creditcards, function (card) {
        return {
            name: card._name,
            type: card._code,
            image: card._path,
            isInternational: card._international
        }
    });
}

function extractLocales(languages) {
    const ids = _.split(languages, (','));
    return _.map(ids, (id) => {
        const match = _.find(AVAILABLE_LOCALES, (locale) => { return locale.id == id });
        if (match) return match;
    });
}

function extractReservationConstraints(constraints) {
    const test = constraints ? (Array.isArray(constraints.location) ? constraints.location : [constraints.location]) : null;
    return _.map(test, (deny) => {
        return {
            location: deny._name,
            area: deny._area,
            row: deny._row,
            seats: deny._seats
        }
    })
}

function extractLocationTexts(texts) {
    return _.map(texts, (text) => {
        return {
            id: text._locationNo,
            text: text._text
        }
    });
}

function extractCensorIcons(censors) {
    return _.map(censors, (censor) => {
        return {
            id: censor._censorShort.toString(),
            text: censor._censor,
            image: censor._picture
        }
    });
}