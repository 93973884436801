import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ShowtimeType from './ShowtimeType'

class ShowtimeTypeList extends React.Component {

    render() {
        const { types } = this.props;

        return (
            <div className="ShowtimeTypeList">
                <hr />
                {_.map(types, (type) => {
                    return <h4 key={type.id} className="showtime-type"><ShowtimeType type={type} includeName={true} /></h4>;
                })}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        showtimeTypes: state.movielist.showtimeTypes
    }
}

export default connect(mapStateToProps)(ShowtimeTypeList)