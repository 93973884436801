import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { isiPhone } from '../../../utilities/location-utilities'
import SelectedSeats from '../order/seatselection/SelectedSeats'
import Info from '../../overlays/Info'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import LOCALE from './ReceiptLocale'
import { ORGANIZER_TYPES } from '../../../api/api-constants';

export default class Reservation extends React.Component {
    timeout = 300000;
    timer = null;

    constructor() {
        super()
        this.state = {
            deactivateButtons: false,
            showDeleteConfirm: false
        }

        this.handleTimeout = this.handleTimeout.bind(this);
        this.activateReservation = this.activateReservation.bind(this);
        this.deleteReservation = this.deleteReservation.bind(this);
        this.deleteConfirmResult = this.deleteConfirmResult.bind(this);
    }

    componentDidMount() {
        this.timer = setTimeout(this.handleTimeout, this.timeout);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleTimeout() {
        this.setState({ deactivateButtons: true });
    }

    activateReservation() {
        const { actions, receipt } = this.props;
        if (receipt.activated) this.navigateAfterActivate();
        else actions.activateReservation(receipt.id).then(() => this.navigateAfterActivate());
    }

    navigateAfterActivate() {
        const { actions, history, showtime, organizerId } = this.props;
        actions.orderTypeChanged(CONSTANTS.orderTypes.buyReservation);
        history.push(`${ROUTES.ORDERFLOW.EXTRA_PURCHASES}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
    }

    deleteReservation() {
        this.setState({ showDeleteConfirm: true });
    }

    deleteConfirmResult(e) {
        if (e.target.id === 'delete') {
            const { actions, receipt } = this.props;
            actions.deleteReservation(receipt.id, receipt.customer.phone, receipt.customer.password).then(() => {
                actions.gaDeleteReservation();
                this.setState({ showDeleteConfirm: false });
            });
        } else this.setState({ showDeleteConfirm: false });
    }

    generateButtons() {
        const { appLocale } = this.props;
        const { deactivateButtons } = this.state;
        const printBtn = !isiPhone() ? <input type="button" className="gray" value={LOCALE[appLocale].print} onClick={window.print} /> : null;
        const buyButton = this.generateBuyButton();
        return <div className="buttons">
            <input type="button" className={!deactivateButtons ? 'red' : 'red disabled'} value={LOCALE[appLocale].delete } onClick={this.deleteReservation} />
            {printBtn}
            {buyButton}
        </div>;
    }

    generateBuyButton() {
        const { showtime, appLocale } = this.props;
        const { deactivateButtons } = this.state;
        const datetimeNow = moment();
        const isInsidePeriod = datetimeNow >= moment(showtime.buyInfo.start) && datetimeNow <= moment(showtime.buyInfo.end);
        const canBuy = showtime.buyInfo.enabled && isInsidePeriod;
        return canBuy ? <input type="button" className={!deactivateButtons ? 'green' : 'green disabled'} value={LOCALE[appLocale].buy} onClick={this.activateReservation} /> : null;
    }

    generateDeleteConfirmOverlay() {
        const { appLocale } = this.props;
        const { showDeleteConfirm } = this.state;
        return showDeleteConfirm ?
            <Info text={LOCALE[appLocale].deleteText} 
                buttons={<div>
                    <input type="button" className="red" id="delete" value={LOCALE[appLocale].confirm} onClick={this.deleteConfirmResult} />
                    <input type="button" className="green" value={LOCALE[appLocale].cancel} onClick={this.deleteConfirmResult} />
                </div>} />
            : null;
    }

    generateDeletedOverlay() {
        const { appLocale } = this.props;
        return <Info text={LOCALE[appLocale].deletedText} />;
    }

    render() {
        const { showtime, receipt, organizerType, reserveText, appLocale } = this.props;
        const qrCode = organizerType == ORGANIZER_TYPES.CIRCUS ? null : 'https://www.ebillet.dk/system/barcode.asmx/QRBarCode?text=' + receipt.customer.phone;
        const buttons = this.generateButtons();
        const info = receipt.deleted ? this.generateDeletedOverlay() : this.generateDeleteConfirmOverlay();

        return (
            <div className="Reservation">
                <h1>{LOCALE[appLocale].reservationSuccess} {receipt.customer.phone}</h1>
                <span dangerouslySetInnerHTML={{ __html: reserveText }}></span>
                <h2>{LOCALE[appLocale].pickUp} : {receipt.pickupInfo || showtime.reserveInfo.pickup}</h2>
                <img src={qrCode} />

                <div className="receipt-info">
                    <SelectedSeats appLocale={appLocale} label={receipt.cinema.locationName} hasNumberedSeats={receipt.hasNumberedSeats} selectedSeats={receipt.seatTexts} />
                    <p className="organizer-info">
                        <span>{receipt.cinema.name}</span><br />
                        {receipt.cinema.address}
                    </p>
                    <p className="customer-info">
                        {LOCALE[appLocale].name}: <span>{receipt.customer.name}</span> <br />
                        {LOCALE[appLocale].email}: <span>{receipt.customer.email}</span>
                    </p>
                </div>
                {buttons}
                {info}
            </div>
        )
    }
}