export default {
    da: {
        backProgram: '❮  Program',
        backAll: '❮  Alle Film',
        backFuture: '❮  Kommende Film',
        backAllFuture: '❮  Fremtidige Film',

        originalTitle: 'Orig. titel: ',
        premiere: 'Premiere: ',
        length: 'Længde: ',
        age: 'Censur: ',
        genre: 'Genre: ',
        actor: 'Skuespiller: ',
        actors: 'Skuespillere: ',
        director: 'Instruktør: ',
        directors: 'Instruktører: '
    },

    en: {
        backProgram: '❮  Program',
        backAll: '❮  All Movies',
        backFuture: '❮  Future Movies',

        originalTitle: 'Orig. title: ',
        premiere: 'Premiere: ',
        length: 'Length: ',
        age: 'Censoring: ',
        genre: 'Genre: ',
        actor: 'Actor',
        actors: 'Actors: ',
        director: 'Director: ',
        directors: 'Directors: '
    }
}