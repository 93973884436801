import OrderFlowLocale from '../OrderFlowLocale'

export default {
    da: {
        ...OrderFlowLocale.da,
        paymentMethod: 'Vælg betalingsmetode',
        giftCard: 'Indløs gavekort  ❯',
        payWithReepay: 'Fortsæt til betaling  ❯',
        creditCardForeign: 'Udenlandsk',
        errorCreditCard: 'Du skal vælge et kreditkort for at fortsætte',
        errorBack: 'Du kan ikke gå tilbage når du har indløst gavekort'
    },

    en: {
        ...OrderFlowLocale.en,
        paymentMethod: 'Choose payment method',
        giftCard: 'Use gift card  ❯',
        payWithReepay: 'Continue to payment  ❯',
        creditCardForeign: 'Foreign',
        errorCreditCard: 'You have to choose a credit card to continue',
        errorBack: 'You can not go back when you have added a gift card voucher'
    }
}