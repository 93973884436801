import fetch from 'isomorphic-fetch'
import xml2js from '../lib/xml-to-js'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../components/movielists/movielist-constants'
import API_CONSTANTS from './api-constants'
import { getParams } from '../utilities/location-utilities'

// GET FUTURE MOVIES 
export default function fetchItems(templateId) {
    const queryParams = getParams();
    const showAllwaysIfEvents = queryParams.showallwaysifevents == "true" || queryParams.ShowAllwaysIfEvents == "TRUE";
    const showIfEvents = showAllwaysIfEvents ? '&ShowAllwaysIfEvents=true' : '';
    if (!templateId) templateId = -1; //fallback

    return fetch(`${API_CONSTANTS.baseUrl}${API_CONSTANTS.exportPath}/GetItems?nTemplateNo=${templateId}${showIfEvents}&REMOTE_HOST=127.0.0.2`,
        {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'text/xml' })
        })
        .then(response => response.text())
        .then(xml => extractItems(xml, showAllwaysIfEvents))
}

function extractItems(xml, showAllwaysIfEvents) {
    const json = xml2js.parseString(xml);
    const itemList = json.itemList;
    const movies = itemList.movies ? (Array.isArray(itemList.movies.movie) ? itemList.movies.movie : [itemList.movies.movie]) : null;
    const items = itemList.items ? (Array.isArray(itemList.items.item) ? itemList.items.item : [itemList.items.item]) : null;

    let futureMovies = [];
    const dateTimeNow = moment(itemList._publishDate);
    _.forEach(items, (item) => {
        const insidePeriod = dateTimeNow >= moment(item.enablePeriod.start) && dateTimeNow <= moment(item.enablePeriod.end);
        if (insidePeriod || showAllwaysIfEvents) {
            const movie = _.find(movies, { '_no': item._movieNo });
            if (movie) {
                movie.info = item.info;
                const organizerId = item._organizerNo;
                const futureMovie = extractMovies(movie, organizerId);
                futureMovie.openingDate = +moment(item.enablePeriod.end);
                futureMovies.push(futureMovie);
            }
        }
    });
    return _.sortBy(futureMovies, ['openingDate']);
}

function extractMovies(movie, organizerId) {
    const posterBaseUrl = 'https://poster.ebillet.dk/';
    return {
        id: movie._no,
        organizerId: organizerId,
        type: movie._no ? CONSTANTS.types.normal : CONSTANTS.types.special,
        name: movie.name,
        description: _.isEmpty(movie.synopsis) ? null : movie.synopsis,
        posters: {
            small: posterBaseUrl + movie.path,
            large: posterBaseUrl + movie.largePath,
            hd: posterBaseUrl + movie.hDPath,
            live: posterBaseUrl + movie.livePoster,
            advertisement: posterBaseUrl + movie.advertisement
        },
        subName: movie.subName,
        originalName: _.isEmpty(movie.originalName) ? null : movie.originalName,
        ageCensoring: _.isEmpty(movie.censoring) ? null : movie.censoring,
        dimension: movie.dimension,
        genre: _.isEmpty(movie.genre) ? null : movie.genre,
        length: !_.isEmpty(movie.length) && movie.length !== '00:00' ? movie.length : null,
        pictures: movie.pictures ? (Array.isArray(movie.pictures.item) ? movie.pictures.item.map(function (picture) { return 'https://ebillet.dk/poster/pictures/' + picture }) : movie.pictures.item) : null,
        actors: movie.actors ? (Array.isArray(movie.actors.item) ? movie.actors.item : [movie.actors.item]) : null,
        directors: movie.directors ? (Array.isArray(movie.directors.item) ? movie.directors.item : [movie.directors.item]) : null,
        trailer: movie.vimeoId ? 'https://player.vimeo.com/video/' + movie.vimeoId : null,
        imdb: _.isEmpty(movie.iMDb) ? null : 'https://www.imdb.com/name/' + movie.iMDb,
        is3D: +movie.dimension === 3,
        isAtmos: +movie.movieFormat & 1 === 1 ? true : false,
        info: movie.info ? movie.info : null
    }
}