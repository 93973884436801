export const ROUTES = {
    TICKETMASTER_ALL_EVENTS: '/ticketmaster-alle-events',
    TICKETMASTER_EVENT: '/ticketmaster-event',

    BILLETTEN_ALL_EVENTS: '/billetten-alle-events',
    BILLETTEN_EVENT: '/billetten-event',

    MULTI_ALL_MOVIES: '/multibio-alle-film',
    MULTI_FUTURE_MOVIES: '/multibio-kommende-film',
    MULTI_PROGRAM: '/multibio-program',
    MULTI_MOVIE: '/multibio-film-info',

    HOME: '/',
    COMMERCIALS: '/reklamer',
    PAGES: '/pages',
    MOVIE: '/film-info',

    PROGRAM_AND_ALL_FUTURE_MOVIES: '/program-og-fremtidige-film',

    ALL_MOVIES: '/alle-film',
    ALL_FUTURE_MOVIES: '/alle-fremtidige-film',
    PROGRAM: '/program',
    FUTURE_MOVIES: '/kommende-film',
    SPECIAL_MOVIES: '/specielle-arrangementer',
    CIRCUS: '/cirkus',

    USER_TRANSACTIONS: '/mine-billetter',
    NEWSLETTERS: '/nyhedsbreve',

    ORDERFLOW: {
        TICKETS: '/billetter',
        EXTRA_PURCHASES: '/tilkøb',
        CUSTOMER: '/kunde-oplysninger',
        BIOKLUB_DK: '/biografklub-danamark',
        PAYMENT: '/betaling',
        GIFTCARDS: '/gavekort',
        DIBS: '/dibs',
        REEPAY: '/reepay',
        RECEIPT: '/kvittering',
        RESERVATION: '/reservation',
    },

    NOT_FOUND: '/findes-ikke'
}