import React from 'react'
import _ from 'lodash'
import { isiPhone } from '../../../utilities/location-utilities'
import SelectedSeats from '../order/seatselection/SelectedSeats'
import LOCALE from './ReceiptLocale'

export default class Purchase extends React.Component {
    render() {
        const { receipt, buyText, appLocale } = this.props;
        const qrCode = 'https://www.ebillet.dk/system/barcode.asmx/QRBarCode?text=' + receipt.orderId;
        const printBtn = !isiPhone() ? <div className="buttons"><input type="button" className="gray" value={LOCALE[appLocale].print} onClick={window.print} /></div> : null;

        return (
            <div className="Purchase">
                <h1>{LOCALE[appLocale].purchaseSuccess} {receipt.customer.phone}</h1>
                <p dangerouslySetInnerHTML={{ __html: buyText }}></p>
                <img src={qrCode} />

                <div className="receipt-info">
                    <SelectedSeats label={receipt.cinema.locationName} hasNumberedSeats={receipt.hasNumberedSeats} selectedSeats={receipt.seatTexts} appLocale={appLocale} />
                    <p className="organizer-info">
                        <span>{receipt.cinema.name}</span><br />
                        {receipt.cinema.address}
                    </p>
                    <p className="customer-info">
                        {LOCALE[appLocale].name}: <span>{receipt.customer.name}</span> <br />
                        {LOCALE[appLocale].email}: <span>{receipt.customer.email}</span>
                    </p>
                </div>
                {printBtn}
            </div>
        )
    }
}