export default {
    da: {
        pickDate: "Vælg dato",
        weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
        weekdaysShort: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
        months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
        sortByAlphabet: 'Sortér alfabetisk på titel',
        sortByTime: 'Sortér efter fremtidige spilletider',
        goToAllMovies: 'Gå til alle film',
        today: 'I dag',
        tomorrow: 'I morgen'
    },

    en: {
        pickDate: "Pick a date",
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'Juli', 'August', 'September', 'October', 'November', 'December'],
        sortByAlphabet: 'Sort by alphabet',
        sortByTime: 'Sort by showtime',
        goToAllMovies: 'Go to all movies',
        today: 'Today',
        tomorrow: 'Tomorrow'
    }
}