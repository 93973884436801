import './extrapurchases.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import CONSTANTS from '../orderflow-constants'
import { ROUTES } from '../../navigation/route-constants'
import ExtraPurchaseList from './ExtraPurchaseList'
import LOCALE from './ExtraPurchaseLocale'

class ExtraPurchaseContainer extends React.Component {
    constructor(props) {
        super(props);
        const { orderType, history, showtime, isFetching, availableExtraPurchases, actions } = props;

        if (!isFetching && (orderType == CONSTANTS.orderTypes.reserve || availableExtraPurchases.length == 0))
            history.replace(`${ROUTES.ORDERFLOW.CUSTOMER}/${showtime.movieId}/${showtime.id}${history.location.search}`);

        actions.calculateTotalPrice();
        this.extraPurchaseQuantityChanged = this.extraPurchaseQuantityChanged.bind(this);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    extraPurchaseQuantityChanged(id, quantity) {
        const { actions, showtime, availableExtraPurchases, selected } = this.props;
        const extra = _.find(availableExtraPurchases, (extraPurchase) => { return id === extraPurchase.id; });

        let selectedExtraPurchases = selected.extraPurchases;
        const existingExtraPurchase = _.find(selectedExtraPurchases, (extraPurchase) => { return id === extraPurchase.id; })

        if (existingExtraPurchase) {
            if (quantity > 0) existingExtraPurchase.quantity = quantity;
            else _.remove(selectedExtraPurchases, (extraPurchase) => { return extraPurchase.id === existingExtraPurchase.id });
        } else selectedExtraPurchases.push({ ...extra, quantity });

        actions.addExtraPurchase(selected.transactionId, showtime.id, selectedExtraPurchases)
    }

    navBack() {
        const { orderType, history, actions } = this.props;
        if (orderType != CONSTANTS.orderTypes.buyReservation) actions.orderTypeChanged('');
        history.goBack();
    }

    navNext() {
        const { organizerId, showtime, history } = this.props;
        history.push(`${ROUTES.ORDERFLOW.CUSTOMER}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
    }

    render() {
        const { isFetching, availableExtraPurchases, selected, clubCard, bottomBarAllwaysVisible, appLocale, extraText } = this.props;
        const extraPurchaseList = availableExtraPurchases.length > 0 ?
            <ExtraPurchaseList appLocale={appLocale}
                availableExtraPurchases={availableExtraPurchases}
                selectedExtraPurchases={selected.extraPurchases}
                quantityChanged={this.extraPurchaseQuantityChanged}
                isFetching={isFetching}
                clubCard={clubCard}
                selectedClubCardPoints={selected.clubCardPoints}
            /> : null;

        return (
            <div className="ExtraPurchaseContainer">
                <h1>{LOCALE[appLocale].extraPurchases}</h1>
                {extraText ? <p className="extra-text">{extraText[appLocale]}</p> : null}

                {extraPurchaseList}

                <div className={bottomBarAllwaysVisible ? 'nav-buttons fixed' : 'nav-buttons'}>
                    <input type="button" className="back gray" onClick={this.navBack} value={LOCALE[appLocale].back} />
                    <input type="button" className="next" onClick={this.navNext} value={LOCALE[appLocale].next} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const availableExtraPurchases = !state.order.canReserve ? state.order.availableExtraPurchases : _.filter(state.order.availableExtraPurchases, (extra) => { return extra.buyOnly === false });
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        customer: state.customer,
        availableExtraPurchases,
        selected: state.order.selected,
        orderType: state.order.orderType,
        isFetching: state.order.isFetching === true,
        clubCard: state.order.clubCard,
        bottomBarAllwaysVisible: state.organizer.configuration.bottomBarAllwaysVisible || false,
        appLocale: state.app.locale,
        canReserve: state.order.canReserve,
        iframeId: state.app.iframeId,
        extraText: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'AddOn_Extra' })
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraPurchaseContainer)