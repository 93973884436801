import './receipt.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import Reservation from './Reservation'
import Purchase from './Purchase'
import * as receiptActions from '../../../actions/receipt-actions'
import * as orderActions from '../../../actions/order-actions'
import * as gaActions from '../../../actions/ga-actions'
import { appHeightChanged } from '../../../actions/app-actions'

class ReceiptContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { completed: true };
        const { receipt, organizerId, showtime, history } = props;
        //prevent going back after wiping receipt in startOver
        if (receipt.transactionId <= 0)
            history.push(`${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);

        props.orderType == CONSTANTS.orderTypes.buy ? props.actions.gaTrackPurchase() : props.actions.gaTrackReservation();
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        const { organizerId, showtime, history } = this.props;
        if (history.action == 'POP' && this.state.completed)
            history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);

    }

    generateReceipt() {
        const { orderType, texts, receipt, customer, showtime, actions, history, organizerId, organizerType, appLocale } = this.props;
        switch (orderType) {
            case CONSTANTS.orderTypes.reserve:
            case CONSTANTS.orderTypes.buyReservation:
                return <Reservation appLocale={appLocale}
                    receipt={receipt}
                    reserveText={texts.reserve}
                    history={history}
                    actions={actions}
                    showtime={showtime}
                    organizerId={organizerId}
                    organizerType={organizerType}
                    customer={customer}
                />
            case CONSTANTS.orderTypes.buy:
                return <Purchase appLocale={appLocale}
                    receipt={receipt}
                    buyText={texts.buy}
                    organizerType={organizerType}
                />;
        }
    }

    render() {
        const receipt = this.generateReceipt();

        return (
            <div className="ReceiptContainer">
                {receipt}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        organizerId: state.organizer.id,
        organizerType: state.organizer.configuration.type,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        receipt: state.receipt,
        texts: state.organizer.configuration.receiptTexts,
        customer: state.customer,
        completed: state.receipt.completed,
        appLocale: state.app.locale,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, receiptActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptContainer)