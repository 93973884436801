import React from 'react'
import LOCALE from '../OrderLocale'

const SeatColors = ({ showReservedAsRed, appLocale }) => {
    return showReservedAsRed ?
        <div className="SeatColors">
            <span className="blue"></span> {LOCALE[appLocale].seatBlue}
            <span className="green"></span> {LOCALE[appLocale].seatGreen}
            <span className="red"></span> {LOCALE[appLocale].seatRed}
        </div>
        :
        <div className="SeatColors">
            <span className="blue"></span> {LOCALE[appLocale].seatBlue}
            <span className="green"></span> {LOCALE[appLocale].seatGreen}
            <span className="white"></span> {LOCALE[appLocale].seatWhite}
            <span className="red"></span> {LOCALE[appLocale].seatRed}
        </div>;    
}

export default SeatColors