import '../../css/movielists.scss'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { ROUTES } from '../../../navigation/route-constants'
import CONSTANTS from '../../movielist-constants'
import { appHeightChanged } from '../../../../actions/app-actions'
import { selectedMovieChanged, changeSelectedShowtime } from '../../../../actions/movielist-actions'
import { getMultiOrgFutureMovies } from '../../../../actions/organizer-actions'
import Multibio_FutureMoviesList from './Multibio_FutureMoviesList'
import { setContainerClasses } from '../../shared'

class Multibio_FutureMoviesContainer extends React.Component {
    constructor() {
        super();

        this.movieClicked = this.movieClicked.bind(this);
        this.showtimeClicked = this.showtimeClicked.bind(this);
    }

    componentDidMount() {
        const { actions, movies, organizerIds, iframeId } = this.props;
        if (movies.length == 0) actions.getMultiOrgFutureMovies(organizerIds);

        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);

    }

    movieClicked(id) {
        const { organizerIds, actions, history, movies } = this.props;
        const movie = _.find(movies, (movie) => { return movie.id == id });
        actions.selectedMovieChanged(movie.id, history.location.pathname).then(() => history.push(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }

    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, showtimes } = this.props;
        const showtime = _.find(showtimes, (showtime) => { return showtime.id == showtimeId && showtime.organizerId == organizerId });
        if (showtime) {
            actions.changeSelectedShowtime(showtime, history.location.pathname).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }

    render() {
        const { appLocale, pageHeader, organizers, movies, showtimes, posterBanners, columns,
            listType, showLocationOnShowtime, actions, organizerIds } = this.props;

        const header = pageHeader ? <div className="page-header full-width" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        let classes = setContainerClasses(listType, columns, true);

        return (
            <div className={classes}>
                <div className="flex-container">{header}</div>
                <Multibio_FutureMoviesList appLocale={appLocale}
                    listType={listType}
                    columns={columns}
                    organizers={organizers}
                    movies={movies}
                    showtimes={showtimes}
                    movieClicked={this.movieClicked}
                    showtimeClicked={this.showtimeClicked}
                    posterBanners={posterBanners}
                    showLocationOnShowtime={showLocationOnShowtime}
                    appHeightChanged={actions.appHeightChanged}
                    organizerIds={organizerIds}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;

    return {
        organizerIds: state.organizer.multibioIds,
        organizers: state.movielist.organizers,
        pageHeader: config.pageHeaders.futureMovies,
        columns: CONSTANTS.columns.one,
        movies: state.movielist.futureMovies,
        listType: CONSTANTS.listTypes.future,
        showtimes: state.movielist.showtimes,
        posterBanners: config.posterBanners,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        selectedDate: state.movielist.selectedDate,
        initialDate: state.movielist.initialDate,
        showLocationOnShowtime: config.showLocationOnShowtime,
        specialMoviesEnabledOnProgram: config.movielists.special.enabledOnProgram,
        showBackInTime: config.daysBack > 0,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getMultiOrgFutureMovies, selectedMovieChanged, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Multibio_FutureMoviesContainer)