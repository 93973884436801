import CONSTANTS from '../components/movielists/movielist-constants'

export const APP_MODES = {
    PROGRAM: 'program',
    MOVIE: 'movie',
    ORDERFLOW: 'orderflow'
}

export const ORGANIZER_TYPES = {
    CINEMA: 'cinema',
    CIRCUS: 'circus'
}

export const AVAILABLE_LOCALES = [
    { id: 'da', name: 'Dansk', flag: '/images/flags/DK.png' },
    { id: 'en', name: 'English', flag: '/images/flags/UK.png' }
];

export const FILTERS = {
    BABYBIO: 1,
    SENIORBIO: 2
}

export const FILTER_TEXTS = {
    1: 'Baby Bio',
    2: 'Seniro Bio'
}

export const SITE_TYPES = {
    WEB: 0,
    APP: 1,
    UMBRACO: 2
}

export const VOUCHER_TYPES = {
    GIFTCARD: 'giftcard',
    TICKETS: 'tickets',
    BIOKLUBDK: 'bioklubdk',
    DISCOUNT: 'rabat'
}

export const AGE_CENSORS = {
    'alle': 'all',
    '7': 'seven',
    '11': 'eleven',
    '15': 'fifteen'
}

export default {
    organizerId: 3,
    baseUrl: extractEnvironmentFromUrl(),
    exportPath: '/systemnative/export.asmx',
    ticketgatePath: '/systemnative/ticketgate.asmx',

    organizerUrls: {
        'localhost:3000': 3,
        'dev.ebillet.dk': 3
    },

    defaultConfiguration: {
        organizerType: ORGANIZER_TYPES.CINEMA,
        appLocale: 'da',
        appMode: APP_MODES.PROGRAM,
        showReservedAsRed: false,
        customerPasswordEnabled: true,
        openOrderFlowInNewWindow: false,
        hasCustomStyles: false,
        timezone: 'Europe/Copenhagen',
        bottomBarAllwaysVisible: false,
        giftCardsEnabled: false,
        repeatEmail: false,
        availableLocales: AVAILABLE_LOCALES,
        phoneMinLength: 8,
        altAllMovies: false,
        payWithDibs: false,

        posterBanners: {
            show3D: false,
            showAtmos: false
        },

        pageHeaders: {
            allMovies: null,
            date: null,
            futureMovies: null,
            movie: null,
            orderflow: null,
            myTickets: null
        },

        movielists: {
            all: {
                base: true,
                columns: CONSTANTS.columns.five,
                sort: CONSTANTS.sort.alphabet
            },
            date: {
                base: true,
                columns: CONSTANTS.columns.five,
                sort: CONSTANTS.sort.time
            },
            future: {
                columns: CONSTANTS.columns.one,
            },
            special: {
                enabledOnProgram: false,
                columns: CONSTANTS.columns.two,
                sort: CONSTANTS.sort.alphabet
            }
        },

        fees: {
            reserveTransaction: 5,
            reserveTicket: 0,
            buyTransaction: 5,
            buyTicket: 0
        },

        newsletters: [],

        smsReminder: {
            enabled: false,
            checkedByDefault: false
        },

        receiptTexts: {
            reserve: 'Oplys dit telefonnummer eller vis denne side når du henter dine billetter.',
            buy: 'Denne side er gyldig som billet.',
        },

        errorText: {
            soldOut: 'Denne forestilling er desværre udsolgt.'
        },

        gdprTexts: {
            reserve: '',
            buy: '',
        },

        pointsLoginText: 'Optjen points på dit køb'
    }
}

function extractEnvironmentFromUrl() {
    const origin = window.location.origin;
    return origin.indexOf('localhost') !== -1 ? 'http://dev.ebillet.dk' : origin;
}