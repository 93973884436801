import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../movielist-constants'
import LOCALE from '../MovieListLocale'
import { isInsideShowPeriod } from '../shared'
import MovieInfo from '../../movie/MovieInfo'
import Showtime from '../../showtime/Showtime'
import Poster from '../../movie/Poster'
import ShowtimeTypeList from '../../showtime/ShowtimeTypeList'

export default class AllMoviesListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxDateCount: 2,
            showMovieInfo: false
        }
        this.movieClicked = this.movieClicked.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showMovieInfo != this.state.showMovieInfo) {
            const { appHeightChanged } = this.props;
            setTimeout(() => { appHeightChanged() }, 500); //Workaround for the duration it takes the DOM to rerender with the expanded element so we get the right height...
        }
    }

    movieClicked(e) {
        const { showMovieInfo } = this.state;
        const { columns, movieClicked } = this.props;
        const dataset = e.target.dataset;
        e.preventDefault();

        if (columns == CONSTANTS.columns.one && dataset.toggleMovieInfo) {
            if (showMovieInfo == false) this.setState({ showMovieInfo: true });
            else this.setState({ showMovieInfo: false });
        } else {
            const movieId = e.currentTarget.id || dataset.movieId;
            if (e.target.tagName == 'IMG' || e.target.tagName == 'H1' || e.target.tagName == 'INPUT' || _.includes(e.target.classList, 'poster-banner'))
                movieClicked(movieId);
        }
    }

    generateShowtimes() {
        const { movie } = this.props;
        const showtimesByDate = _.groupBy(movie.validShowtimes, (showtime) => { return moment(showtime.dateTime).format('ddd DD/MM'); });
        const showtimesPerDate = this.generateShowtimesPerDate(showtimesByDate);
        return { showtimesPerDate };
    }

    showtimeTypes = [];
    generateShowtimesPerDate(showtimesByDate) {
        const { maxDateCount } = this.state;
        const { showLocationOnShowtime, showtimeClicked, columns, showAsBase, showExpired } = this.props;
        const showtimesPerDate = [];
        let counter = 0;

        _.forOwn(showtimesByDate, (showtimes, dateKey) => {
            if (counter < maxDateCount) {
                showtimesPerDate.push(<div key={dateKey} className="showtimes">
                    <div className="date">{_.capitalize(dateKey)}</div>

                    {_.map(showtimes, (showtime) => {
                        if (showtime.type && !_.find(this.showtimeTypes, showtime.type)) this.showtimeTypes.push(showtime.type);

                        const dateTime = moment(showtime.dateTime);
                        return <div key={showtime.id} className="row">
                            {columns == CONSTANTS.columns.one || showLocationOnShowtime ? <div className="location">{showtime.locationName}</div> : ''}
                            <Showtime key={showtime.id}
                                showtime={showtime}
                                text={columns != CONSTANTS.columns.one && showLocationOnShowtime ? `${dateTime.format('LT')} ${showtime.locationName}` : dateTime.format('LT')}
                                showtimeClicked={showtimeClicked}
                                showExpired={showExpired}
                            />

                            {isInsideShowPeriod && columns == CONSTANTS.columns.one ? <div className="type">
                                <div>{showtime.type ? showtime.type.name : ''} {showAsBase ? showtime.movieBaseShowName : null}</div>
                            </div> : null}
                            {columns == CONSTANTS.columns.one ? <div className="info">{showtime.info}</div> : null}
                        </div>
                    })}
                </div>);
                counter++;
            } else return false;
        })

        return showtimesPerDate;
    }

    render() {
        const { maxDateCount, showMovieInfo } = this.state;
        const { movie, rowClasses, appLocale, showAsBase, columns, organizerIds, filterId } = this.props;

        const poster = movie.posters ?
            <a className="poster-container" href={`${window.location.origin}${ROUTES.MOVIE}/${movie.id}?org=${organizerIds}`}>
                <Poster movie={movie} /></a> : null;

        const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;

        const showtimesByDate = _.groupBy(movie.validShowtimes, (showtime) => { return moment(showtime.dateTime).format('ddd DD/MM'); });
        const showtimesPerDate = this.generateShowtimesPerDate(showtimesByDate);

        const moreButton = columns !== CONSTANTS.columns.one && _.keys(showtimesByDate).length > maxDateCount ?
            <div className="read-more"><input type="button" className="more-showtimes" data-movie-id={movie.id} value={LOCALE[appLocale].moreShowtimes} /></div> : <div className="read-more"></div>;

        return (
            <div className={rowClasses} id={movie.id} onClick={this.movieClicked}>
                {poster}

                <div className="movie-info">
                    <a href={`${window.location.origin}${ROUTES.MOVIE}/${movie.id}?org=${organizerIds}`}>
                        <h1 ref={this.titleRef}>{showAsBase ? movie.name : movieNameLocale}</h1></a>
                    <div className="flex-container">{showtimesPerDate}</div>
                    {!filterId && this.showtimeTypes.length > 0 ? <ShowtimeTypeList types={this.showtimeTypes} includeName={true} /> : null}
                    {moreButton}

                    {columns === CONSTANTS.columns.one ? <div className="read-more">
                        <input type="button" className="more-showtimes" data-toggle-movie-info={true} value={LOCALE[appLocale].movieInfo} />
                        <input type="button" className="more-showtimes" data-movie-id={movie.id} value={LOCALE[appLocale].allShowtimes} />

                        {columns === CONSTANTS.columns.one ? <MovieInfo addClass={showMovieInfo ? 'slide-down' : 'slide-up'} movie={movie} showTitle={false} appLocale={appLocale} /> : null}
                    </div> : null}
                </div>
            </div>
        )
    }
}