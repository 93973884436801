import React from 'react'
import pathToRegexp from 'path-to-regexp'
import _ from 'lodash'
import TagManager from 'react-gtm-module'

import { ROUTES } from '../components/navigation/route-constants'
import CONSTANTS from '../components/orderflow/orderflow-constants'
import { APP_MODES, SITE_TYPES } from '../api/api-constants'
import API_CONSTANTS from '../api/api-constants'
import { setCardholder } from '../api/ticketgate-api'
import { getParams } from '../utilities/location-utilities'
import { logTrace, sendLogSms } from '../api/orderflow-api'
import { languageChanged, siteTypeChanged } from './app-actions'
import { getOrganizer } from './organizer-actions'
import * as movieListActions from './movielist-actions'
import { orderTypeChanged, startOrder } from './order-actions'
import { bookFailed } from './customer-actions'
import { getReservationInfo, receiptSuccess } from './receipt-actions'
import { getExtraPurchases } from './order-actions'
import { book } from './customer-actions'

export default function initializeApp() {
    const queryParameters = getParams(window.location.href);

    let rid = queryParameters.rid || queryParameters.reservationid;
    const reservationPath = pathToRegexp(`${ROUTES.ORDERFLOW.RESERVATION}/:reservationId`).exec(window.location.pathname);
    if (reservationPath) rid = reservationPath[1];

    return (dispatch, getState) => {
        //TODO move this to DibsContainer if possible:
        // complete transaction if we came from dibs
        if (queryParameters.orderid && queryParameters.transact) {
            const organizer = getState().organizer;
            checkAndLoadCustomStyles(organizer);
            initGoogleTracking(organizer);
            const customer = getState().customer;
            const transactionId = getState().order.selected.transactionId;
            setCardholder(getState().user.clubCardId || 0);

            const message = `transactionId: ${transactionId}, orderId: ${queryParameters.orderid}, dibs transact: ${queryParameters.transact}, orgId: ${organizer.id}, customer email: ${customer.email}, customer phone: ${customer.phone}, payType: ${queryParameters.paytype}`;
            logTrace('initializeApp - AFTER DIBS ACCEPT URL', message);

            if (transactionId) {
                logTrace('initializeApp - CALLING BOOK', message);
                return dispatch(book(transactionId, customer, queryParameters.transact, queryParameters.orderid, parseInt(queryParameters.amount), queryParameters.paytype));
            }
            else {
                logTrace('initializeApp - NO TRANSACTIONID = NO BOOK', message, "critical");
                sendLogSms({ message: 'NO BOOK! ' + message });

                const { location } = window;
                const path = _.replace(location.pathname, '/dibs', ROUTES.ORDERFLOW.TICKETS);
                dispatch(bookFailed({ //The lack of actually calling book is the failure
                    message: 'Der skete en fejl! Din bestilling gik ikke igennem, prøv igen.',
                    buttons: <input type="button" className="start-over" onClick={() => location.href = `${location.origin}${path}?org=${queryParameters.org}`} value="OK" />
                }));
            }
        } else if (!queryParameters.cancel && queryParameters.invoice) { //Reepay..Can we move this to ReepayContainer?
            const organizer = getState().organizer;
            checkAndLoadCustomStyles(organizer);
            initGoogleTracking(organizer);
            const customer = getState().customer;
            const transactionId = getState().order.selected.transactionId;
            setCardholder(getState().user.clubCardId || 0);

            const message = `transactionId: ${transactionId}, orderId: ${queryParameters.invoice}, reepay id: ${queryParameters.id}, orgId: ${organizer.id}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('initializeApp - AFTER REEPAY ACCEPT URL ', document.URL);

            if (transactionId) {
                logTrace('initializeApp - CALLING BOOK', message);
                return dispatch(book(transactionId, customer, queryParameters.id, queryParameters.invoice, parseInt(queryParameters.amount), 'DK')); //TODO get paytype from reepay and also transaction gebyr?
            }
            else {
                logTrace('initializeApp - NO TRANSACTIONID = NO BOOK', message, "critical");
                sendLogSms({ message: 'NO BOOK! ' + message });

                const { location } = window;
                const path = _.replace(location.pathname, '/reepay', ROUTES.ORDERFLOW.TICKETS);
                dispatch(bookFailed({ //The lack of actually calling book is the failure
                    message: 'Der skete en fejl! Din bestilling gik ikke igennem, prøv igen.',
                    buttons: <input type="button" className="start-over" onClick={() => location.href = `${location.origin}${path}?org=${queryParameters.org}`} value="OK" />
                }));
            }
        }
        //---
        if (rid) return dispatch(initAsReservationOnly(rid, queryParameters.lang));
        else return dispatch(initWith(queryParameters));
    }
}

function initAsReservationOnly(rid, appLocale) {
    const reservationId = checkForReservationId(rid);
    return (dispatch) => {
        return dispatch(getReservationInfo(reservationId)).then((result) => {
            if (!result.error) {
                const movieId = result.transaction.movieId;
                const showtimeId = result.transaction.showtimeId;
                return dispatch(getOrganizer(result.transaction.organizerId)).then((organizer) => {
                    if (appLocale) dispatch(languageChanged(appLocale));

                    checkAndLoadCustomStyles(organizer);
                    initGoogleTracking(organizer);

                    return dispatch(movieListActions.getMovieList(organizer.id)).then((movielist) => {
                        const movie = movielist.movies ? _.find(movielist.movies, (movie) => { return movie.id == movieId }) : null;
                        const showtime = movielist.showtimes ? _.find(movielist.showtimes, (showtime) => { return showtime.id == showtimeId }) : null;
                        if (movie && showtime) {
                            window.history.replaceState(null, null, `${ROUTES.ORDERFLOW.RESERVATION}/${rid}`);
                            dispatch(movieListActions.selectedMovieChanged(movie.id));
                            dispatch(movieListActions.selectedShowtimeChanged(showtime));
                            dispatch(getExtraPurchases(showtime.id)); //separate call for only extra puchases in case user wants to buy
                            result.transaction.id = reservationId;
                            dispatch(receiptSuccess(result.transaction)); //for the reservationId to receipt.id...
                            return dispatch(orderTypeChanged(CONSTANTS.orderTypes.reserve)); //for the reserve fees
                        }
                        return movielist;
                    });
                });
            } else return { error: result.error }
        });
    }
}

function initWith(queryParameters) {
    const organizerIds = queryParameters.org || queryParameters.organizer || API_CONSTANTS.organizerUrls[window.location.host];
    return (dispatch, getState) => {
        const customerEmail = getState().customer.email;
        const siteType = queryParameters.sitetype;

        return dispatch(getOrganizer(organizerIds, siteType, customerEmail)).then((organizer) => {
            if (organizer.error) return organizer;
            if (queryParameters.lang) dispatch(languageChanged(queryParameters.lang));

            dispatch(siteTypeChanged(siteType));
            checkAndLoadCustomStyles(organizer, siteType, queryParameters.design);
            initGoogleTracking(organizer);
            if (queryParameters.newwin) organizer.configuration.openOrderInNewWindow = queryParameters.newwin === 'true';

            //For old drupal sites..REMOVE as soon as drupal sites are dead
            const showtimeId = queryParameters.showtime;
            if (queryParameters.organizer && showtimeId) {
                organizer.configuration.appMode = APP_MODES.ORDERFLOW; // Mutating directly because it's used to determine to show backbuttons...

                return dispatch(movieListActions.getMovieList(organizerIds)).then((movielist) => {
                    const showtime = movielist.showtimes ? _.find(movielist.showtimes, (showtime) => { return showtime.id == showtimeId }) : null;
                    document.body.className = "drupal";
                    window.history.replaceState(null, null, `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`);

                    return dispatch(movieListActions.selectedMovieChanged(showtime.movieId))
                        .then(() => dispatch(movieListActions.selectedShowtimeChanged(showtime)))
                        .then(() => dispatch(startOrder(showtime.id)));
                });
            }
            //---

            return organizer;
        });
    }
}

function initGoogleTracking(organizer) {
    if (organizer.configuration.googleAnalytics && organizer.configuration.googleAnalytics.gtmId) {
        const tagManagerArgs = { gtmId: organizer.configuration.googleAnalytics.gtmId };
        TagManager.initialize(tagManagerArgs);
    }
}

function checkForReservationId(rid) {
    const length = rid.length;
    const checkNumber = +rid.substr(length - 1, length);
    const reservationId = +rid.substr(0, length - 1);
    let value = reservationId;
    let sum = 0;

    while (value) {
        sum += value % 10;
        value = Math.floor(value / 10);
    }

    return sum % 10 === checkNumber ? reservationId : null;
}

function checkAndLoadCustomStyles(organizer, siteType, design) {
    if (siteType == SITE_TYPES.APP) {
        const path = '/css/mobile-app.css';
        let umbracoOverrides = document.createElement('link');
        umbracoOverrides.href = path;
        umbracoOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(umbracoOverrides);
    }
    else if (siteType == SITE_TYPES.UMBRACO) {
        const path = '/css/umbraco.css';
        let umbracoOverrides = document.createElement('link');
        umbracoOverrides.href = path;
        umbracoOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(umbracoOverrides);
    }

    if (organizer.configuration && organizer.configuration.hasCustomStyles) {
        const path = '/custom-organizer-styles/organizer_' + organizer.id + '.css';
        let styleOverrides = document.createElement('link');
        styleOverrides.href = path;
        styleOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(styleOverrides);
    }

    if (design) {
        const path = '/css/designs/design_' + design + '.css';
        let styleOverrides = document.createElement('link');
        styleOverrides.href = path;
        styleOverrides.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(styleOverrides);
    }
}