import OrderFlowLocale from '../OrderFlowLocale'

export default {
    da: {
        ...OrderFlowLocale.da,
        customerInfo: 'Dine oplysninger',
        name: 'Navn',
        phone: 'Telefon',
        email: 'Email',
        repeatEmail: 'Gentag email',
        password: 'Opret kode',
        zipCode: 'Postnr.',
        note: 'Note',
        passwordReserveText: 'Hvis du ønsker at slette din bestilling senere',
        passwordBuyText: 'Koden skal bruges, hvis du ønsker at finde dit køb frem senere',
        rememberMe: 'Husk mig',
        smsReminder: 'Gratis SMS påmindelse',
        acceptConditions: 'Jeg accepterer <a href="https://ebillet.dk/biograf/condition.aspx" target="_blank">købsvilkårene</a> for dette køb',

        errorName: 'Skal være udfyldt',
        errorPhone: 'Skal bestå af minimum INSERT_NUMBER tal',
        errorEmail: 'Skal være en gyldig email',
        errorRepeatEmail: 'De indtastede e-mails er ikke ens',
        errorPassword: 'Må kun indeholde bogstaver og tal',
        errorZipCode: 'Skal være et gyldigt post nummer',
        errorConditionsAccepted: 'Du skal acceptere købsvilkårene for at fortsætte'
    },

    en: {
        ...OrderFlowLocale.en,
        customerInfo: 'Your information',
        name: 'Name',
        phone: 'Phone',
        email: 'Email',
        repeatEmail: 'Repeat email',
        password: 'Add password',
        zipCode: 'Zip code',
        note: 'Note',
        passwordReserveText: 'If you wish to cancel your reservation later',
        passwordBuyText: 'The password is needed if you wish to find your purchase information later',
        rememberMe: 'Remember me',
        smsReminder: 'Free SMS reminder',
        acceptConditions: 'I accept the <a href="https://ebillet.dk/biograf/condition.aspx" target="_blank">purchase agreement</a> for this order',

        errorName: 'Has to be filled out',
        errorPhone: 'Has to be minimum INSERT_NUMBER numbers',
        errorEmail: 'Has to be a valid email',
        errorRepeatEmail: 'The entered emails are not the same',
        errorPassword: 'Can only contain letters and numbers',
        errorZipCode: 'Has to be a valid zip code',
        errorConditionsAccepted: 'You have to accept the purchase agreement to continue'
    }
}