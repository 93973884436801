import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import api from '../../../api/reepay-api'
import { logTrace } from '../../../api/orderflow-api'
import { resetOrder } from '../../../actions/order-actions'
import { preBook, book } from '../../../actions/customer-actions'
import { gaTrackCheckoutStep } from '../../../actions/ga-actions'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import { SITE_TYPES } from '../../../api/api-constants'

class ReepayContainer extends React.Component {
    constructor() {
        super();
        this.state = { error: '' }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { iframeId, siteType, customer, actions, history, sessionState, showtime, organizerId, transactionId, checkoutComplete, origin, paymentMethods } = this.props;
        actions.gaTrackCheckoutStep(3);

        const querystrings = getParams();

        //new reepay transaction
        if (!querystrings.cancel && !querystrings.invoice) {
            localStorage.setItem('beforeDibsState', JSON.stringify(sessionState));
            const message = `transactionId: ${transactionId}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('ReepayContainer - NEW TRANSACTION', message);

            //TODO make an action
            actions.preBook(transactionId, customer).then((result) => {
                if (!result.error) {
                    const preBook = result.dibs.parameters;
                    const type = siteType ? `&sitetype=${siteType}` : '';
                    let callback = window.location.origin + `/reepay/${showtime.movieId}/${showtime.id}?org=${organizerId}&amount=${preBook.amount}&iframeid=${iframeId}${type}`; //Book skal bruge amount..!
                    let acceptUrl = callback + '&accept=true';
                    let cancelUrl = callback + '&cancel=true';

                    if (siteType == SITE_TYPES.UMBRACO && origin) {
                        const base64Url = btoa(callback);
                        acceptUrl = `${origin}/reepay-landing-page/?iframeid=${iframeId}&iframeurl=${base64Url}`;
                        cancelUrl = `${origin}/reepay-landing-page/?cancel=true&iframeid=${iframeId}&iframeurl=${base64Url}`;
                    }

                    const reepayObject = {
                        order: {
                            handle: preBook.orderid,
                            customer: {
                                first_name: customer.name,
                                phone: customer.phone,
                                email: customer.email
                            },
                            amount: preBook.amount,
                            metadata: {
                                showtimeId: showtime.id,
                                movieId: showtime.movieId,
                                organizerId: showtime.organizerId,
                                iframeUrl: callback
                            },
                        },
                        accept_url: acceptUrl,
                        cancel_url: cancelUrl
                    };

                    if (paymentMethods)
                        reepayObject.payment_methods = _.split(paymentMethods, ',');

                    logTrace('REEPAY - SESSION ID REQUEST', JSON.stringify(reepayObject));
                    api.fetchSessionId(btoa(preBook.merchant), reepayObject).then((session) => {
                        logTrace('REEPAY - SESSION ID SUCCESS', session.id);
                        if (siteType == SITE_TYPES.UMBRACO && window.parent != window) { 
                            console.log('[CHILD] Start Reepay! Posting to parent: ');
                            console.log({ iframeId, sessionId: session.id });

                            window.parent.postMessage({
                                iframeId: iframeId,
                                reepay: {
                                    sessionId: session.id
                                }
                            }, '*');
                        } else {
                            new window.Reepay.WindowCheckout(session.id);
                        }
                    });
                }
            });
        }

        // cancel reepay transaction
        if (querystrings.cancel) {
            const message = `transactionId: ${transactionId}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('ReepayContainer - CANCEL TRANSACTION', message);
            localStorage.removeItem('beforeDibsState');
            actions.resetOrder();
            history.push(`${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
        }

        // complete reepay transaction
        else if (checkoutComplete) {
            const message = `transactionId: ${transactionId}, orderId: ${querystrings.invoice}, reepay sessionId: ${querystrings.id}, orgId: ${organizerId}, customer email: ${customer.email}, customer phone: ${customer.phone}`;
            logTrace('ReepayContainer - COMPLETED TRANSACTION', message);
            localStorage.removeItem('beforeDibsState');
            history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
        }
    }

    render() {
        const { error } = this.state;
        return (
            <div className="ReepayContainer">
                <div className="error">{error}</div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        checkoutComplete: state.customer.checkoutComplete,
        iframeId: state.app.iframeId,
        siteType: state.app.siteType,
        origin: state.app.origin,
        paymentMethods: state.organizer.configuration.paymentMethods,

        //for session storage after reepay payment on seperate page
        sessionState: {
            organizer: state.organizer,
            movielist: {
                selectedMovie: { ...state.movielist.selectedMovie, versions: [] },
                selectedShowtime: state.movielist.selectedShowtime
            },
            order: { ...state.order, seatsImage: null },
            customer: state.customer,
            user: state.user
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ preBook, book, gaTrackCheckoutStep, resetOrder }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReepayContainer)