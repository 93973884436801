import React from 'react'
import Player from '@vimeo/player'

export default class Trailer extends React.Component {

    constructor() {
        super();
        this.state = { isPlaying: false }
        this.trailerClicked = this.trailerClicked.bind(this);
        this.resizeIframe = this.resizeIframe.bind(this);
    }
    
    //This is for handling dynamic height when inside an iframe
    resizeIframe(e) {
        const { iframeId } = this.props;
        if (window.parent != window) {
            const app = document.querySelector('.App');
            const pageHeight = Math.max(app.scrollHeight, app.offsetHeight, app.clientHeight) + 300;

            console.log('Trailer clicked!');
            console.log('iframeId: ' + iframeId);
            console.log('setting height: ' + pageHeight);

            window.parent.postMessage({
                iframeId: iframeId,
                iframeHeight: pageHeight
            }, '*');
        }
    }

    trailerClicked(e) {
        const toggledPlay = this.state.isPlaying ? false : true;
        this.setState({ isPlaying: toggledPlay });

        if (!window.innerWidth < 500) {
            const iframe = this.refs.iframeContainer.children[0];
            const player = new Player(iframe);
            player.play();
        }

        setTimeout(() => { this.resizeIframe() }, 500); //Workaround for the duration it takes the DOM to rerender with the expanded element so we get the right height...
    }

    render() {
        const { trailerUrl } = this.props;
        const { isPlaying } = this.state;

        //React can't read the webkit and mox allowfullscreens https://github.com/facebook/react/issues/7848
        //So until this is fixed we'll use dangerouslySetInnerHTML ...
        const iframeContainerClasses = isPlaying ? 'iframe-container is-playing' : 'iframe-container';
        const vimeoPlayer = trailerUrl ? <div className={iframeContainerClasses} ref="iframeContainer" dangerouslySetInnerHTML={{ __html: '<iframe mozallowfullscreen webkitallowfullscreen allowfullscreen allow="autoplay; fullscreen" src="' + trailerUrl + '"></iframe>' }} /> : null;

        const overlay = !isPlaying ? <div className="trailer-overlay" onClick={this.trailerClicked}><span className="icon-play"></span></div> : null;

        return (
            <div className={isPlaying ? 'Trailer is-playing' : 'Trailer'}>
                {overlay}
                {vimeoPlayer}
            </div>)
    }
}