//TODO if < ie 11
import 'raf/polyfill'
import 'babel-polyfill'
import './lib/html5-dataset'
import 'react-app-polyfill/ie9';
//------------

import './styles/normalize.css'
import './components/overlays/overlay.scss'
import React from 'react'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import rootReducer from './reducers/root-reducer'
import { logTrace } from './api/orderflow-api'
import localStorageUtils from './utilities/localstorage-utilities'
import { getParams } from './utilities/location-utilities'
import App from './components/App'
import Spinner from './components/overlays/Spinner'
import Error from './components/overlays/Error'
import initializeApp from './actions/index-actions'

const beforeDibsState = JSON.parse(localStorage.getItem('beforeDibsState'));
if (beforeDibsState) {
    const queryParameters = getParams();
    const transactionId = beforeDibsState.order ? (beforeDibsState.order.selected ? beforeDibsState.order.selected.transactionId : null) : null;
    const customer = beforeDibsState.customer ? beforeDibsState.customer : null;
    const message = `FROM SESSION STATE: transactionId: ${transactionId}, orgId: ${beforeDibsState.organizer ? beforeDibsState.organizer.id : null}, customer email: ${customer ? customer.email : null}, customer phone: ${customer ? customer.phone : null}, FROM QUERY PARAMS: ${queryParameters.transact}, ${queryParameters.orderid}`;
    logTrace('index.js - STARTUP AFTER REEPAY/DIBS', message);
}

const persistedCustomer = localStorageUtils.getItem('customer') || null;
const persistedState = beforeDibsState ? beforeDibsState : persistedCustomer ? { customer: persistedCustomer } : undefined;
localStorage.removeItem('beforeDibsState');

//REDUX DEV TOOLS
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));

render(<Spinner store={store} show={true} />, document.getElementById('root'));
store.dispatch(initializeApp()).then((result) => {
    render(
        <Provider store={store}>
            <Router>
                {!result.error ? <App /> : <Error />}
            </Router>
        </Provider>,
        document.getElementById('root')
    )
});