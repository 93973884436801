import React from 'react'
import moment from 'moment'
import _ from 'lodash'

export default class DayNavigator extends React.Component {

    constructor() {
        super();
        this.nextDay = this.nextDay.bind(this);
        this.previousDay = this.previousDay.bind(this);
    }

    nextDay() {
        const { datesWithShowtimes, selectedDate, changeDate } = this.props;
        const nextDateTime = datesWithShowtimes[datesWithShowtimes.indexOf(selectedDate) + 1];
        if (nextDateTime) changeDate(nextDateTime);

    }

    previousDay() {
        const { datesWithShowtimes, selectedDate, changeDate, initialDate } = this.props;
        const previousDateTime = datesWithShowtimes[datesWithShowtimes.indexOf(selectedDate) - 1] || initialDate;
        if (previousDateTime) changeDate(previousDateTime);
    }

    render() {
        const { toggleCalendar, datesWithShowtimes, selectedDate, showCalendar } = this.props;
        let previousDate = null;
        let nextDate = null;
        let dateDisplay = <div className="date" onClick={toggleCalendar}><span className="icon-calendar"></span><span>Vælg dato</span></div>;
        
        if (selectedDate) {
            if (!showCalendar) {
                previousDate = datesWithShowtimes.indexOf(selectedDate) - 1 > -1 ?
                    <span className="date-nav" onClick={this.previousDay}>&#10094;</span> : <span className="date-nav disabled">&#10094;</span>;

                nextDate = datesWithShowtimes.indexOf(selectedDate) < datesWithShowtimes.length - 1 ?
                    <span className="date-nav" onClick={this.nextDay}>&#10095;</span> : <span className="date-nav disabled">&#10095;</span>;
            }

            const date = moment(selectedDate);

            dateDisplay = <div className="date" onClick={toggleCalendar}>
                <span className="icon-calendar"></span>
                <span className="day">{_.capitalize(date.format('dddd'))},</span><span className="short-day">{_.capitalize(date.format('ddd'))},</span>
                <span className="date-number">{date.format('D.')}</span>
                <span className="month">{date.format('MMMM')}</span>
                <span className="short-month">{date.format('MMM')}</span>
                <span className="year">{date.format('YYYY')}</span>
            </div>;
        }

        return (
            <div className="DayNavigator">
                {previousDate}
                {dateDisplay}
                {nextDate}
            </div>
        );
    }
}