export default {
    da: {
        loading: 'Indlæser...',
        notFound: 'Siden findes ikke.',
        unspecified: 'Der skete en fejl.',
        close: 'Luk',
        errors: {
            16: 'Du har allerede en anden aktiv bestilling i et andet faneblad, færdiggør den der eller start forfra.',
            17: 'Det ønskede antal pladser er desværre ikke ledige til denne forestilling.',
            29: 'Vi kunne ikke kontakte betalingstjenesten. Prøv igen senere.',
            30: 'Du har været for længe om at indtaste dine oplysninger.',
            35: 'Din session er udløbet, da du har været for længe om at gennemføre din bestilling.',
            40: 'Det er ikke muligt at aktivere reservationen, da den er købt eller afbestilt.',
            46: 'Den angivne bruger har ikke noget medlemskab eller det er udløbet.',
            472: 'Det er ikke muligt at reservere/købe til dette arrangement. Tjek at forestillingen ikke allerede er begyndt eller overstået.',
            474: 'Køb og reservering er ikke aktiveret på denne forestilling.',
            475: 'Køb er ikke aktiveret på denne forestilling.',
            476: 'Reservering er ikke aktiveret på denne forestilling.',
            999: 'Denne arrangør er ikke opsat.',
            10001: 'Arrangøren kan ikke kontaktes i øjeblikket. Prøv igen senere eller ring til arrangøren.',
            10004: 'Det er ikke muligt, at kontakte billet-udbyderens billet system. Prøv igen lidt senere.'
        }
    },
    en: {
        loading: 'Loading...',
        notFound: 'Page not found.',
        unspecified: 'An error occured.',
        close: 'close',
        errors: {
            16: 'You already have an active order in another tab, complete is there or start over.',
            17: 'The requested amount of seats unfortunately are not available for this showtime.',
            29: 'We could not contact the payment service. Try again later.',
            30: 'You have been too slow filling in your information.',
            35: 'Your session has expired, you have been too slow filling out your order.',
            40: 'It is not possble to activate the reservation, it has already been purchased or deleted.',
            46: 'This user does not have a membership or it has expired.',
            472: 'It is not possible to reserve/purchase tickets for this showtime. Check that the show has not already begun or is in the past.',
            474: 'Purchasing and reserving is not activated on this showtime.',
            475: 'Purchasing is not activated on this showtime.',
            476: 'Reserving is not activated on this showtime.',
            999: 'This organizer is not configured.',
            10001: 'The organizer can not be contacted right now. Try again later or call directly to the organizer.',
            10004: 'It is not possible to reach the ticket system. Try again later.'
        }
    }
}