import './customer.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import * as validators from '../../../utilities/validation-utilities'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import Customer from './Customer'
import LOCALE from './CustomerLocale'
import { ORGANIZER_TYPES } from '../../../api/api-constants';
import { appHeightChanged } from '../../../actions/app-actions'

class CustomerContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {
                name: '',
                phone: '',
                email: '',
                repeatEmail: '',
                password: '',
                conditionsAccepted: '',
                zipCode: ''
            },
        }

        props.actions.gaTrackCheckoutStep(2);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    navBack() {
        const { customer, history, actions, clubCardId } = this.props;
        if (customer.rememberMe === true && !clubCardId) actions.updateCustomerLocalStorage();
        history.goBack();
    }

    navNext(e) {
        const id = e.target.id;
        const { organizerId, actions, transactionId, customer, showtime, clubCardId, totalPrice, history } = this.props;
        if (customer.rememberMe === true && !clubCardId) actions.updateCustomerLocalStorage();

        if (this.customerIsValid(id) === true) {
            if (id === CONSTANTS.orderTypes.reserve) {
                actions.reserve(transactionId, customer)
                    .then((result) => { if (!result.error) { history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`); } });
            } else if (totalPrice === 0) {
                actions.preBook(transactionId, customer).then((result) => {
                    if (!result.error) {
                        actions.book(transactionId, customer, 'Free', result.dibs.parameters.orderid, totalPrice, null)
                            .then((result) => { if (!result.error) history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`) });
                    }
                });
            } else history.push(`${ROUTES.ORDERFLOW.PAYMENT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
        }
    }

    customerIsValid(orderType) {
        const isReserve = orderType === CONSTANTS.orderTypes.reserve
        const { customer, configuration, appLocale, organizerType } = this.props;
        const nameIsValid = customer.name !== '',
            phoneIsValid = validators.isValidPhone(customer.phone, configuration.phoneMinLength),
            emailIsValid = validators.isValidEmail(customer.email),
            repeatEmailIsValid = configuration.repeatEmail ? validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail : true,
            passwordIsValid = !configuration.customerPasswordEnabled ? true : validators.isValidPassword(customer.password),
            conditionsAccepted = !isReserve ? customer.conditionsAccepted === true : true, //only check for buy
            zipCodeIsValid = organizerType == ORGANIZER_TYPES.CIRCUS ? customer.zipCode !== '' : true; //only for circus

        const errors = {
            name: !nameIsValid ? LOCALE[appLocale].errorName : '',
            phone: !phoneIsValid ? LOCALE[appLocale].errorPhone.replace('INSERT_NUMBER', configuration.phoneMinLength) : '',
            email: !emailIsValid ? LOCALE[appLocale].errorEmail : '',
            repeatEmail: !repeatEmailIsValid ? LOCALE[appLocale].errorRepeatEmail : '',
            password: !passwordIsValid ? LOCALE[appLocale].errorPassword : '',
            conditionsAccepted: !isReserve && !conditionsAccepted ? LOCALE[appLocale].errorConditionsAccepted : '',
            zipCode: !zipCodeIsValid ? LOCALE[appLocale].errorZipCode : ''
        }


        this.setState({ errors });
        return nameIsValid && phoneIsValid && emailIsValid && repeatEmailIsValid && passwordIsValid && conditionsAccepted && zipCodeIsValid;
    }

    render() {
        const { orderType, configuration, customer, actions, clubCardId, appLocale, organizerType } = this.props;
        const { errors } = this.state;


        const nextButton = orderType === CONSTANTS.orderTypes.buy || orderType === CONSTANTS.orderTypes.buyReservation ?
            <input type="button" id="buy" className="next" onClick={this.navNext} value={LOCALE[appLocale].next} /> :
            <input type="button" id="reserve" className="next" onClick={this.navNext} value={LOCALE[appLocale].nextReserve} />;

        const gdprText = orderType !== CONSTANTS.orderTypes.reserve ? <div className="gdpr-text" dangerouslySetInnerHTML={{ __html: configuration.gdprTexts.buy }}></div> :
            <div className="gdpr-text" dangerouslySetInnerHTML={{ __html: configuration.gdprTexts.reserve }}></div>;

        return (
            <div className="CustomerContainer">
                <Customer appLocale={appLocale}
                    customer={customer}
                    actions={actions}
                    configuration={configuration}
                    errors={errors}
                    isReserve={orderType === CONSTANTS.orderTypes.reserve}
                    clubCardId={clubCardId}
                    organizerType={organizerType}
                />

                {gdprText}

                <div className={configuration.bottomBarAllwaysVisible ? 'nav-buttons fixed' : 'nav-buttons'}>
                    <input type="button" className="back gray" onClick={this.navBack} value={LOCALE[appLocale].back} />
                    {nextButton}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    //TODO make this an action like calculateTotalPrice and store in state
    const totalTicketPrice = state.order.selected.totalTicketPrice;
    const totalExtraPurchasesPrice = state.order.selected.totalExtraPurchasesPrice;
    const priceBeforeFees = totalTicketPrice + totalExtraPurchasesPrice;
    const totalFees = state.order.selected.totalFees;
    const totalPrice = priceBeforeFees > 0 ? priceBeforeFees + totalFees : priceBeforeFees;
    //----

    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        configuration: state.organizer.configuration,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        receipt: state.receipt,
        clubCardId: state.user.clubCardId,
        totalPrice,
        appLocale: state.app.locale,
        organizerType: state.organizer.configuration.type,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer)