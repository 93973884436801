import React from 'react'
import _ from 'lodash'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../../orderflow/orderflow-constants'
import LOCALE from './TransactionsLocale'

export default class TransactionReceipt extends React.Component {

    getReceipt() {
        const { transaction } = this.props;
        if (transaction.orderType == CONSTANTS.orderTypes.reserve) {
            return <iframe src={`${ROUTES.ORDERFLOW.RESERVATION}/${transaction.id}`}></iframe>

        } else if (transaction.orderType == CONSTANTS.orderTypes.buy) {
            return <div className="purchaseReceipt" dangerouslySetInnerHTML={{ __html: transaction.purchaseReceipt }} />
        }
    }

    render() {
        const { toggleReceipt, fadeIn, appLocale } = this.props;
        const receipt = this.getReceipt();
        return (
            <div className={fadeIn ? 'TransactionReceipt overlay fade-in' : 'TransactionReceipt overlay fade-out'}>
                <div className="receipt-container">
                    <input type="button" className="close" value={LOCALE[appLocale].close} onClick={toggleReceipt} />
                    {receipt}
                </div>
            </div>
        )
    }
}