import React from 'react'
import _ from 'lodash'
import { withTwoDecimals } from '../../../utilities/validation-utilities'
import LOCALE from './SummaryLocale'

export default class PriceInfo extends React.Component {
    render() {
        const { showWithDecimals, showPriceInfo, ticketPrice, extraPurchasePrice, totalFees, totalPrice, showFees, selected, appLocale } = this.props;

        const ticketPriceAsText = showWithDecimals ? withTwoDecimals(ticketPrice) : ticketPrice;
        const feesAsText = showFees ? (showWithDecimals ? withTwoDecimals(totalFees) : totalFees) : '';
        const extrasPriceAsText = showFees ? (showWithDecimals ? withTwoDecimals(extraPurchasePrice) : extraPurchasePrice) : '';
        const totalPriceAsText = showWithDecimals ? withTwoDecimals(totalPrice) : totalPrice;

        const giftCardAmount = selected.giftCardAmount ? (showWithDecimals ? withTwoDecimals(selected.giftCardAmount) : selected.giftCardAmount) : '';
        const giftCards = giftCardAmount ? <p>{LOCALE[appLocale].giftCard} <span>- {giftCardAmount} {LOCALE[appLocale].currency}</span></p> : null;
        const clubCardPoints = selected.clubCardPoints ? <p>{LOCALE[appLocale].clubCard} <span>{selected.clubCardPoints} points</span></p> : null;

        return (
            <div className={showPriceInfo ? 'PriceInfo' : 'PriceInfo closed'}>
                <p>{LOCALE[appLocale].tickets} <span>{ticketPriceAsText} {LOCALE[appLocale].currency}</span></p>
                <p className={showFees ? '' : 'disabled'}>{LOCALE[appLocale].fees} <span>{feesAsText} {LOCALE[appLocale].currency}</span></p>
                <p className={showFees && selected.extraPurchases.length > 0 ? '' : 'disabled'}>{_.capitalize(LOCALE[appLocale].extraPurchase)} <span>{extrasPriceAsText} {LOCALE[appLocale].currency}</span></p>
                {giftCards}
                <hr />
                <p>{LOCALE[appLocale].totalPrice} <span>{totalPriceAsText} {LOCALE[appLocale].currency}</span></p>
                {clubCardPoints}
            </div>
        )
    }
}