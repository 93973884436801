export default {
    da: {
        next: 'Næste  ❯',
        nextReserve: 'Reserver  ❯',
        nextBuy: 'Køb  ❯',
        back: '❮  Tilbage',

        currency: 'kr.',
        points: 'points'
    },

    en: {
        next: 'Next  ❯',
        nextReserve: 'Reserve  ❯',
        nextBuy: ' Buy  ❯',
        back: '❮  Back',

        currency: 'DKK',
        points: 'points'
    }
}