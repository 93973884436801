import _ from 'lodash'
import {
    AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_REQUEST,
    AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_SUCCESS,
    AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_FAILED,
    BLOCK_SEATS_REQUEST,
    BLOCK_SEATS_SUCCESS,
    BLOCK_SEATS_FAILED,
    CALCULATE_POINTS,
    SEATS_IMAGE_REQUEST,
    SEATS_IMAGE_SUCCESS,
    SEATS_IMAGE_FAILED,
    MOVE_SEATS_REQUEST,
    MOVE_SEATS_SUCCESS,
    MOVE_SEATS_FAILED,
    GET_EXTRA_PURCHASES_REQUEST,
    GET_EXTRA_PURCHASES_SUCCESS,
    GET_EXTRA_PURCHASES_FAILED,
    ADD_EXTRA_PURCHASE_REQUEST,
    ADD_EXTRA_PURCHASE_SUCCESS,
    ADD_EXTRA_PURCHASE_FAILED,
    ORDER_TYPE_CHANGED,
    RESET_ORDER,
    INITIAL_FETCH_DONE,
    TOTAL_PRICE_CHANGED
} from '../actions/order-actions'

const initialState = {
    isFetching: false,
    orderType: '',
    availableTickets: [],
    availableExtraPurchases: [],
    bioKlubDk: { available: false },
    discountEnabled: false,
    discountActivated: false,
    clubCard: null,
    seatsImage: '',
    selected: {
        transactionId: 0,
        tickets: [],
        totalTicketPrice: 0,
        location: '',
        seats: [],
        seatTexts: [],
        vouchers: { active: '', error: '' },
        giftCardAmount: 0,
        extraPurchases: [],
        totalExtraPurchasesPrice: 0,
        totalFees: 0,
        totalPoints: null,
        quantity: 0
    },
    voucherConstraints: [],
    totalPrice: 0,
    canReserve: true,
    error: null,
    initialFetchDone: false,
    showInfoBox: false
}

export default function order(
    state = initialState, action) {
    switch (action.type) {
        case AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.succeededAt,
                availableTickets: action.availableTickets,
                reserveMax: action.reserveMax,
                buyMax: action.buyMax,
                bioKlubDk: action.bioKlubDk,
                availableExtraPurchases: action.extraPurchases,
                clubCard: action.clubCard,
                discountEnabled: action.discountEnabled,
                discountActivated: action.discountActivated,
                voucherConstraints: action.voucherConstraints,
                error: null
            }
        case AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case BLOCK_SEATS_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case BLOCK_SEATS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.suceededAt,
                selected: {
                    ...state.selected,
                    transactionId: action.transactionId,
                    tickets: action.tickets,
                    totalTicketQuantity: action.totalTicketQuantity,
                    totalTicketPrice: action.totalTicketPrice,
                    location: action.location,
                    seats: action.seats,
                    seatTexts: action.seatTexts,
                    vouchers: action.vouchers,
                    totalFees: action.totalFees,
                    giftCardAmount: action.giftCardAmount
                },
                canReserve: action.canReserve,
                error: null,
                isInitialRequest: action.initialRequest
            }
        case BLOCK_SEATS_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case INITIAL_FETCH_DONE:
            return {
                ...state,
                initialFetchDone: true,
                isFetching: false,
            }
        case CALCULATE_POINTS:
            return {
                ...state,
                clubCard: {
                    ...state.clubCard,
                    remainingPoints: action.remainingPoints
                },
                selected: {
                    ...state.selected,
                    clubCardPoints: action.usedPoints
                }
            }
        case SEATS_IMAGE_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case SEATS_IMAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.suceededAt,
                seatsImage: action.seatsImage,
                error: null
            }
        case SEATS_IMAGE_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case MOVE_SEATS_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case MOVE_SEATS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.suceededAt,
                selected: {
                    ...state.selected,
                    tickets: action.tickets,
                    totalTicketPrice: action.totalTicketPrice,
                    seats: action.seats,
                    seatTexts: action.seatTexts,
                    transactionId: action.transactionId,
                },
                canReserve: action.canReserve,
                showInfoBox: action.showInfoBox,
                error: null
            }
        case MOVE_SEATS_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case GET_EXTRA_PURCHASES_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case GET_EXTRA_PURCHASES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.succeededAt,
                availableExtraPurchases: action.extraPurchases,
                error: null
            }
        case GET_EXTRA_PURCHASES_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case ADD_EXTRA_PURCHASE_REQUEST:
            return {
                ...state,
                isFetching: true,
                dataWasUpdated: false,
                lastRequested: action.requestedAt,
                error: null
            }
        case ADD_EXTRA_PURCHASE_SUCCESS:
            const dataWasUpdated = !seatsAreEqual(state.selected.seats, action.seats);
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.suceededAt,
                selected: {
                    ...state.selected,
                    transactionId: action.transactionId,
                    extraPurchases: action.extraPurchases,
                    totalExtraPurchasesPrice: action.totalExtraPurchasesPrice
                },
                error: dataWasUpdated ? {
                    ...state.error,
                    code: 16 //Simulating error response for problem with multiple sessions in ticketgate
                } : null
            }
        case ADD_EXTRA_PURCHASE_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case ORDER_TYPE_CHANGED:
            return {
                ...state,
                orderType: action.orderType,
                selected: {
                    ...state.selected,
                    totalFees: action.totalFees
                }
            }
        case RESET_ORDER:
            return initialState;
        case TOTAL_PRICE_CHANGED:
            return {
                ...state,
                totalPrice: action.totalPrice
            }
        default:
            return state
    }
}

function seatsAreEqual(currentSeats, newSeats) {
    let areEqualSeats = false;
    _.forEach(currentSeats, (current) => {
        _.forEach(newSeats, (newSeat) => {
            areEqualSeats = _.isEqual(current, newSeat);
        });
    });
    return areEqualSeats;
}