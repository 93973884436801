import * as ticketgate from '../api/ticketgate-api'
import { blockSeatsSuccess } from './order-actions'

export const RECEIPT_RESET = 'RECEIPT_RESET'
export const receiptReset = () => {
    return {
        type: RECEIPT_RESET
    }
}

export const RECEIPT_SUCCESS = 'RECEIPT_SUCCESS'
export const receiptSuccess = (receipt) => {
    return {
        type: RECEIPT_SUCCESS,
        suceededAt: Date.now(),
        receipt
    }
}

export const DELETE_RESERVATION_REQUEST = 'DELETE_RESERVATION_REQUEST'
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS'
export const DELETE_RESERVATION_FAILED = 'DELETE_RESERVATION_FAILED'

export function deleteReservation(reservationId, phone, password) {
    return dispatch => {
        dispatch(deleteReservationRequest());
        return ticketgate.cancelReservation(reservationId, phone, password)
            .then(result => dispatch(deleteReservationSuccess(result)), error => dispatch(deleteReservationFailed(error)));
    };
}

const deleteReservationRequest = () => {
    return {
        type: DELETE_RESERVATION_REQUEST,
        requestedAt: Date.now()
    }
}

const deleteReservationSuccess = (result) => {
    return {
        type: DELETE_RESERVATION_SUCCESS,
        suceededAt: Date.now(),
        result
    }
}

const deleteReservationFailed = (error) => {
    return {
        type: DELETE_RESERVATION_FAILED,
        failedAt: Date.now(),
        error: {
            ...error,
            message: 'Din reservation kunne ikke slettes.'
        }
    }
}

export const ACTIVATE_RESERVATION_REQUEST = 'ACTIVATE_RESERVATION_REQUEST'
export const ACTIVATE_RESERVATION_SUCCESS = 'ACTIVATE_RESERVATION_SUCCESS'
export const ACTIVATE_RESERVATION_FAILED = 'ACTIVATE_RESERVATION_FAILED'

export function activateReservation(reservationId) {
    return dispatch => {
        dispatch(activateReservationRequest());
        return ticketgate.activateReservation(reservationId).then(receipt => {
            dispatch(activateReservationSuccess(receipt));
            dispatch(blockSeatsSuccess(receipt));
            receipt.id = reservationId;
            return dispatch(receiptSuccess(receipt));
        }, error => dispatch(activateReservationFailed(error)));
    };
}

const activateReservationRequest = () => {
    return {
        type: ACTIVATE_RESERVATION_REQUEST,
        requestedAt: Date.now()
    }
}

const activateReservationSuccess = (transaction) => {
    return {
        type: ACTIVATE_RESERVATION_SUCCESS,
        suceededAt: Date.now(),
        transaction
    }
}

const activateReservationFailed = (error) => {
    return {
        type: ACTIVATE_RESERVATION_FAILED,
        failedAt: Date.now(),
        error: {
            ...error,
            message: 'Det er ikke muligt at aktivere reservationen, da den er købt eller afbestilt.'
        }
    }
}

export const GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS'
export const GET_RESERVATION_REQUEST = 'GET_RESERVATION_REQUEST'
export const GET_RESERVATION_FAILED = 'GET_RESERVATION_FAILED'

export function getReservationInfo(reservationId) {
    return dispatch => {
        dispatch(getReservationRequest());
        return ticketgate.getReservation(reservationId).then(receipt => {
            return dispatch(getReservationSuccess(receipt));
        }, error => dispatch(getReservationFailed(error)));
    };
}

const getReservationRequest = () => {
    return {
        type: GET_RESERVATION_REQUEST,
        requestedAt: Date.now()
    }
}

const getReservationSuccess = (transaction) => {
    return {
        type: GET_RESERVATION_SUCCESS,
        suceededAt: Date.now(),
        transaction
    }
}

const getReservationFailed = (error) => {
    return {
        type: GET_RESERVATION_FAILED,
        failedAt: Date.now(),
        error
    }
}