import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import Trailer from './Trailer'
import VersionSelector from './VersionSelector'
import ShowtimeSchedule from '../showtime/ShowtimeSchedule'
import MovieInfo from './MovieInfo'
import Pictures from './Pictures'
import Poster from './Poster'

export default class Movie extends React.Component {

    generateShowtimeSchedules() {
        const { movie, showtimeClicked, appLocale, organizers, showExpired, filterId } = this.props;

        let showtimeSchedules = [];
        if (movie.showtimesByOrgId) {
            _.forOwn(movie.showtimesByOrgId, (showtimes, orgId) => {
                if (showtimes.length > 0) {
                    const isMultiBio = organizers.length > 1;
                    const orgName = _.find(organizers, (org) => { return org.id == orgId });
                    const showtimesByDate = _.groupBy(showtimes, (showtime) => { return moment(showtime.dateTime).format('YYYY-MM-DD') });
                    showtimeSchedules.push(
                        <div key={orgId} className="organizer">
                            {isMultiBio && orgName ? <h3>{orgName.name}</h3> : null}
                            <ShowtimeSchedule movie={{ ...movie, showtimesByDate }}
                                showtimeClicked={showtimeClicked}
                                appLocale={appLocale}
                                showExpired={showExpired}
                                isMultiBio={isMultiBio}
                                filterId={filterId}
                            />
                        </div>);
                }
            });
        }

        return showtimeSchedules;
    }

    render() {
        const { movie, movieClicked, appLocale, iframeId } = this.props;
        const trailer = movie.trailer ? <Trailer trailerUrl={movie.trailer} iframeId={iframeId} /> : null;
        const allVersions = movie.versions.length > 1 ? <VersionSelector movie={movie} movieClicked={movieClicked} /> : null;
        const poster = movie.posters ? <Poster movie={movie} /> : null;
        const pictures = movie.pictures ? <Pictures pictureUrls={movie.pictures} /> : null;
        const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;

        return (
            <div className="Movie" >
                {trailer}
                {allVersions}
                <div className="schedules">{this.generateShowtimeSchedules()}</div>
                <div className="info-container" >
                    <h1>{movieNameLocale}</h1>
                    {poster}
                    <MovieInfo appLocale={appLocale} movie={movie} />
                </div>
                {pictures}
            </div>
        );
    }
}