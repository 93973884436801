import '../css/movielists.scss'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../movielist-constants'
import { getParams } from '../../../utilities/location-utilities'
import { appHeightChanged } from '../../../actions/app-actions'
import { getMovieList, selectedMovieChanged, changeSelectedShowtime } from '../../../actions/movielist-actions'
import AllMoviesList from './AllMoviesList'
import { manipulateTitles, setContainerClasses } from '../shared'

class AllMoviesListContainer extends React.Component {
    constructor() {
        super();
        this.movieClicked = this.movieClicked.bind(this);
        this.showtimeClicked = this.showtimeClicked.bind(this);
    }

    componentDidMount() {
        const { actions, movies, organizerIds, iframeId } = this.props;
        if (movies.length == 0) actions.getMovieList(organizerIds);

        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);

        this.manipulateTitles();
    }

    componentDidUpdate() {
        this.manipulateTitles();
    }

    manipulateTitles() {
        const { columns, listType } = this.props
        if (columns != CONSTANTS.columns.one) {
            const movielist = document.querySelector(`.MovieListContainer.${listType}`)
            manipulateTitles(movielist, columns);
        }
    }

    movieClicked(id) {
        const { organizerIds, actions, history, movies } = this.props;
        const movie = _.find(movies, (movie) => { return movie.id == id });
        actions.selectedMovieChanged(movie.id, history.location.pathname).then(() => history.push(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }

    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, showtimes } = this.props;
        const showtime = _.find(showtimes, (showtime) => { return showtime.id == showtimeId && showtime.organizerId == organizerId });
        if (showtime) {
            actions.changeSelectedShowtime(showtime, history.location.pathname).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }

    render() {
        const { appLocale, listType, pageHeader, movies, showAsBase, showtimes, posterBanners, columns, showLocationOnShowtime, actions, organizerIds, sortBy, selectedDate, removeToday, showExpired, filterId } = this.props;
        const header = pageHeader ? <div className="page-header full-width" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        let classes = setContainerClasses(listType, columns);

        return (
            <div className={classes + ' AllForOne'}>
                <div className="flex-container">{header}</div>
                <AllMoviesList appLocale={appLocale}
                    columns={columns}
                    movies={movies}
                    showAsBase={showAsBase}
                    showtimes={showtimes}
                    sortBy={sortBy}
                    movieClicked={this.movieClicked}
                    showtimeClicked={this.showtimeClicked}
                    posterBanners={posterBanners}
                    selectedDate={selectedDate}
                    showLocationOnShowtime={showLocationOnShowtime}
                    appHeightChanged={actions.appHeightChanged}
                    organizerIds={organizerIds}
                    selectedDate={selectedDate}
                    removeToday={removeToday}
                    showExpired={showExpired}
                    filterId={filterId}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;
    const listConfig = config.movielists;

    let movies = listConfig.all.base ? extractMoviesAsBases(state.movielist.movies) : state.movielist.movies;
    let showAsBase = listConfig.all.base;
    const listType = ownProps.listType || CONSTANTS.listTypes.all;
    let pageHeader = listType == CONSTANTS.listTypes.allFuture ? config.pageHeaders.allFutureMovies : config.pageHeaders.allMovies;

    function extractMoviesAsBases(movies) {
        const moviesBases = state.movielist.movieBases;
        const moviesAsBase = [];
        _.each(movies, (movie) => {
            if (movie.baseId) {
                const baseMovie = _.find(moviesBases, (base) => { return base.id == movie.baseId; });
                const alreadyInList = _.some(moviesAsBase, ['baseId', movie.baseId]);
                if (baseMovie && !alreadyInList) moviesAsBase.push({ ...movie, name: baseMovie.name, posters: baseMovie.posters });
            } else moviesAsBase.push(movie);
        });
        return moviesAsBase;
    }

    return {
        organizerIds: state.movielist.organizerIds || getParams().org,
        pageHeader,
        listType,
        movies,
        showAsBase,
        columns: listConfig.all.columns,
        sortBy: listConfig.all.sort,
        showtimes: state.movielist.showtimes,
        posterBanners: config.posterBanners,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        showLocationOnShowtime: config.showLocationOnShowtime,
        selectedDate: state.movielist.selectedDate,
        iframeId: state.app.iframeId,
        removeToday: listType == CONSTANTS.listTypes.allFuture || getParams().removetoday == "true",
        showExpired: config.movielists.all.showExpired,
        filterId: state.movielist.filterId,
        listType
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getMovieList, selectedMovieChanged, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllMoviesListContainer)