import '../external.scss'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import LOCALE from '../ExternalLocale'
import { ROUTES } from '../../navigation/route-constants'
import { getEvent, selectedEventChanged } from '../../../actions/ticketmaster-actions'
import { appHeightChanged } from '../../../actions/app-actions'

class TicketmasterDetailsContainer extends React.Component {
    constructor() {
        super();
        this.showtimeClicked = this.showtimeClicked.bind(this);
        this.navBack = this.navBack.bind(this);
    }

    componentDidMount() {
        const { eventId, event, actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);

        if (!event.id && eventId) actions.getEvent(eventId);
    }

    showtimeClicked() {
        const { event } = this.props;
        window.open(event.ticketsUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes')
    }

    navBack() {
        const { organizerId, history } = this.props;
        history.push(`${ROUTES.TICKETMASTER_ALL_EVENTS}?org=${organizerId}`);
    }

    getSoldOutOrCancelledText() {
        const { event, appLocale } = this.props;
        if (event.isSoldOut) return <span className="cancelled"> - {LOCALE[appLocale].soldOut}</span>;
        else if (event.isCancelled) return <span className="cancelled"> - {LOCALE[appLocale].cancelled}</span>;
    }

    render() {
        const { event, pageHeader, appLocale } = this.props;
        const header = pageHeader ? <div className="page-header" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        const description = event.description ? <p dangerouslySetInnerHTML={{ __html: event.description.replace(/(?:\r\n|\r|\n)/g, '<br />') }} /> : null
        const price = event.price ? <h4>{event.price} {LOCALE[appLocale].including}</h4> : null;
        const venue = event.venue ? <div><strong>{event.venue.name}</strong><br />{event.venue.address}</div> : null;
        const soldOutOrCancelled = this.getSoldOutOrCancelledText();

        return (
            <div className="ExternalEvent">
                {header}
                <div className="top">
                    <div className="Poster"><img src={event.posters.large} /></div>
                    <div className="info">
                        <h1>
                            {event.name}
                            {soldOutOrCancelled}
                        </h1>
                        <h3>{event.dateTime ? _.capitalize(moment(event.dateTime).format('dddd [d.] DD/MM [kl.] LT')) : null}</h3>
                        {price}
                    </div>
                </div>
                <div className="description">
                    {description}
                    {venue}
                </div>
                <div className="nav-buttons">
                    <button className="back gray" onClick={this.navBack}>{LOCALE[appLocale].back}</button>
                    {!event.isSoldOut && !event.isCancelled ? <button className="next" onClick={this.showtimeClicked}>{LOCALE[appLocale].buyTickets}</button> : null}
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const organizer = state.organizer;
    const config = organizer.configuration;

    return {
        organizerId: organizer.id,
        venueIds: config.ticketmaster.promoterCodes,
        pageHeader: config.pageHeaders.event,
        eventId: parseInt(ownProps.match.params.eventId),
        event: state.ticketmaster.selectedEvent,
        appLocale: state.app.locale,
        iframeId: state.app.iframeId
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getEvent, selectedEventChanged, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketmasterDetailsContainer)