import _ from 'lodash'
import * as ticketgate from '../api/ticketgate-api'
import CONSTANTS from '../components/orderflow/orderflow-constants'
import { ROUTES } from '../components/navigation/route-constants'

export const START_ORDER = ' START_ORDER';
export const INITIAL_FETCH_DONE = 'INITIAL_FETCH_DONE'
export function startOrder(showtimeId, clubCardId = 0) {
    return (dispatch, getState) => {
        return dispatch(getAvailableTicketsAndExtraPurchases(showtimeId, clubCardId, ''))
            .then(() => {
                return dispatch(blockSeats(showtimeId, 0, [], '', getState().organizer.configuration.defaultTickets))
                    // call next then if no error from blockseats, otherwise if gets overridden and not shown in ui....
                    .then((result) => !result.error ? dispatch(fetchSeatsImage()) : result)
                    .then((result) => !result.error ? dispatch({ type: INITIAL_FETCH_DONE }) : result)
            });
    }
}

// AVAILABLE TICKETS AND EXTRA PURCHASES
export const AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_REQUEST = 'AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_REQUEST'
export const AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_SUCCESS = 'AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_SUCCESS'
export const AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_FAILED = 'AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_FAILED'

export function getAvailableTicketsAndExtraPurchases(showtimeId, clubCardId = 0, discountCode = '') {
    return (dispatch) => {
        dispatch({
            type: AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_REQUEST,
            requestedAt: Date.now(),
        });

        return ticketgate.availableTicketsAndExtraPurchases(showtimeId, clubCardId, discountCode).then(
            response => dispatch({
                type: AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_SUCCESS,
                succeededAt: Date.now(),
                availableTickets: response.availableTickets,
                reserveMax: response.reserveMax,
                buyMax: response.buyMax,
                bioKlubDk: response.bioKlubDk,
                extraPurchases: response.extraPurchases,
                clubCard: response.clubCard,
                discountEnabled: response.discountEnabled,
                discountActivated: response.discountActivated,
                voucherConstraints: response.voucherConstraints
            }),
            error => dispatch({
                type: AVAILABLE_TICKETS_AND_EXTRA_PURCHASES_FAILED,
                failedAt: Date.now(),
                error
            })
        );
    }
}

// BLOCK SEATS
export const BLOCK_SEATS_REQUEST = 'BLOCK_SEATS_REQUEST'
export const BLOCK_SEATS_SUCCESS = 'BLOCK_SEATS_SUCCESS'
export const BLOCK_SEATS_FAILED = 'BLOCK_SEATS_FAILED'

export function blockSeats(showtimeId, transactionId, tickets, vouchers, defaultTickets) {
    return (dispatch, getState) => {
        dispatch(blockSeatsRequest());

        return ticketgate.blockSeats(showtimeId, transactionId, tickets, vouchers, defaultTickets)
            .then(response => {
                const canReserve = checkReserveConstraints(getState().organizer.configuration.reservationConstraints, response.seats);
                dispatch(blockSeatsSuccess(response, canReserve));
                return dispatch(calculateTotalPrice());
            }, error => {
                if (defaultTickets == 2 && error.code == 17 && error.type != 10001)
                    return dispatch(blockSeats(showtimeId, transactionId, tickets, vouchers, 1));
                else if (defaultTickets == 1 && error.code == 17 && error.type == 68) {
                    error.message = getState().organizer.configuration.errorTexts.soldOut;
                    return dispatch(blockSeatsFailed(error));
                } else return dispatch(blockSeatsFailed(error));
            });
    }
}

const blockSeatsRequest = () => {
    return {
        type: BLOCK_SEATS_REQUEST,
        requestedAt: Date.now(),
    }
}

export const blockSeatsSuccess = (selected, canReserve) => {
    return {
        type: BLOCK_SEATS_SUCCESS,
        succeededAt: Date.now(),
        transactionId: selected.transactionId,
        tickets: selected.tickets,
        totalTicketQuantity: selected.totalTicketQuantity,
        totalTicketPrice: selected.totalTicketPrice,
        location: selected.location,
        seats: selected.seats,
        seatTexts: selected.seatTexts,
        vouchers: selected.vouchers,
        giftCardAmount: _.sumBy(selected.vouchers.active, 'amount'),
        totalFees: selected.totalFees || 0,
        canReserve
    }
}

function checkReserveConstraints(constraints, seats) {
    let canReserve = true;
    if (constraints.length > 0) {
        _.forEach(seats, function (seat) {
            _.forEach(constraints, (constraint) => {
                const location = constraint.location;
                const area = constraint.area;
                const row = constraint.row;
                const splittedSeats = _.split(constraint.seats, ',');
                const containsSeats = _.some(splittedSeats, (splitted) => {
                    return splitted == seat.seat
                });

                if ((location == null || location.toUpperCase() == seat.location.toUpperCase()) &&
                    (area == null || area == seat.area) &&
                    (row == null || row == seat.row) &&
                    (constraint.seats == null || containsSeats)) {
                    canReserve = false;
                    return false;
                }
            });
        });
    }
    return canReserve;
}

const blockSeatsFailed = (error) => {
    return {
        type: BLOCK_SEATS_FAILED,
        failedAt: Date.now(),
        error,
    }
}

// SEAT SELECTION IMAGE
export const SEATS_IMAGE_REQUEST = 'SEATS_IMAGE_REQUEST'
export const SEATS_IMAGE_SUCCESS = 'SEATS_IMAGE_SUCCESS'
export const SEATS_IMAGE_FAILED = 'SEATS_IMAGE_FAILED'

export function fetchSeatsImage() {
    return dispatch => {
        dispatch({
            type: SEATS_IMAGE_REQUEST,
            requestedAt: Date.now(),
        });

        return ticketgate.seatsImage().then(
            response => dispatch({
                type: SEATS_IMAGE_SUCCESS,
                succeededAt: Date.now(),
                seatsImage: response ? 'data:image/gif;base64,' + response : ''
            }),
            error => dispatch({
                type: SEATS_IMAGE_FAILED,
                failedAt: Date.now(),
                error
            }));
    }
}

// MOVE SEATS
export const MOVE_SEATS_REQUEST = 'MOVE_SEATS_REQUEST'
export const MOVE_SEATS_SUCCESS = 'MOVE_SEATS_SUCCESS'
export const MOVE_SEATS_FAILED = 'MOVE_SEATS_FAILED'

export function moveSeats(transactionId, x, y) {
    return (dispatch, getState) => {
        dispatch({
            type: MOVE_SEATS_REQUEST,
            requestedAt: Date.now(),
        });

        return ticketgate.moveSeats(transactionId, x, y).then(
            response => {
                const couldReserve = getState().order.canReserve;
                const canReserve = checkReserveConstraints(getState().organizer.configuration.reservationConstraints, response.seats);
                let showInfoBox = false;
                if (couldReserve && !canReserve && response.extraPurchases.length > 0 || !couldReserve && canReserve && response.extraPurchases.length > 0) {
                    showInfoBox = true; //info boks til kunden at tilkøb er blevet nulstillet
                    dispatch(addExtraPurchase(response.transactionId, response.showtimeId, []));
                }

                dispatch({
                    type: MOVE_SEATS_SUCCESS,
                    succeededAt: Date.now(),
                    seats: response.seats,
                    seatTexts: response.seatTexts,
                    tickets: response.tickets,
                    totalTicketPrice: response.totalTicketPrice,
                    transactionId: response.transactionId,
                    canReserve,
                    showInfoBox
                });

                return dispatch(calculateTotalPrice());
            },
            error => dispatch({
                type: MOVE_SEATS_FAILED,
                failedAt: Date.now(),
                error
            })).then(() => dispatch(fetchSeatsImage()));
    };
}

// GET EXTRA PURCHASES
export const GET_EXTRA_PURCHASES_REQUEST = 'GET_EXTRA_PURCHASES_REQUEST'
export const GET_EXTRA_PURCHASES_SUCCESS = 'GET_EXTRA_PURCHASES_SUCCESS'
export const GET_EXTRA_PURCHASES_FAILED = 'GET_EXTRA_PURCHASES_FAILED'

export function getExtraPurchases(showtimeId, clubCardId = 0) {
    return (dispatch) => {
        dispatch({ type: GET_EXTRA_PURCHASES_REQUEST, requestedAt: Date.now() });

        return ticketgate.getConcessions(showtimeId, clubCardId).then(
            response => dispatch({
                type: GET_EXTRA_PURCHASES_SUCCESS,
                succeededAt: Date.now(),
                extraPurchases: response.extraPurchases
            }),
            error => dispatch({
                type: GET_EXTRA_PURCHASES_FAILED,
                failedAt: Date.now(),
                error
            })
        );
    }
}

// ADD EXTRA PURCHASE
export const ADD_EXTRA_PURCHASE_REQUEST = 'ADD_EXTRA_PURCHASE_REQUEST'
export const ADD_EXTRA_PURCHASE_SUCCESS = 'ADD_EXTRA_PURCHASE_SUCCESS'
export const ADD_EXTRA_PURCHASE_FAILED = 'ADD_EXTRA_PURCHASE_FAILED'

export function addExtraPurchase(transactionId, showtimeId, extraPurchases) {
    return (dispatch, getState) => {
        const priced = [];
        const points = [];
        _.each(extraPurchases, (extra) => {
            if (extra.points != null) points.push(extra);
            else priced.push(extra);
        });

        if (getState().order.clubCard) {
            dispatch(extraPurchaseRequest());
            ticketgate.addVouchers(transactionId, showtimeId, points)
                .then(response => dispatch(extraPurchaseSuccess(response)), error => dispatch(extraPurchaseFailed(error)))
                .then(() => dispatch(calculatePoints()));
        }

        dispatch(extraPurchaseRequest());
        return ticketgate.addConcessions(transactionId, showtimeId, priced)
            .then(response => {
                dispatch(extraPurchaseSuccess(response))
                return dispatch(calculateTotalPrice())
            }, error => dispatch(extraPurchaseFailed(error)));
    }
}

const extraPurchaseRequest = () => {
    return { type: ADD_EXTRA_PURCHASE_REQUEST, requestedAt: Date.now() };
}

const extraPurchaseSuccess = (response) => {
    return {
        type: ADD_EXTRA_PURCHASE_SUCCESS,
        succeededAt: Date.now(),
        transactionId: response.transactionId,
        extraPurchases: response.extraPurchases,
        totalExtraPurchasesPrice: response.totalExtraPurchasesPrice,
        seats: response.seats
    }
}

const extraPurchaseFailed = (error) => {
    return {

        type: ADD_EXTRA_PURCHASE_FAILED,
        failedAt: Date.now(),
        error
    }
};

//HANDLERS
export const TOTAL_PRICE_CHANGED = 'TOTAL_PRICE_CHANGED'
export function calculateTotalPrice() {
    return (dispatch, getState) => {
        const { receipt, order, organizer } = getState();
        const currentPage = window.location.pathname;
        const isTicketsPage = _.startsWith(currentPage, ROUTES.ORDERFLOW.TICKETS);
        const showReceiptData = receipt.transactionId != 0 && (_.startsWith(currentPage, ROUTES.ORDERFLOW.RECEIPT) || _.startsWith(currentPage, ROUTES.ORDERFLOW.RESERVATION));
        const ticketPrice = showReceiptData ? receipt.prices.tickets : order.selected.totalTicketPrice;

        const extraPurchasesActive = !order.canReserve || _.some(order.availableExtraPurchases, (extra) => { return extra.buyOnly === false });
        const includeExtras = !isTicketsPage && order.orderType !== CONSTANTS.orderTypes.reserve && extraPurchasesActive;
        const extraPurchasePrice = includeExtras ? (showReceiptData ? receipt.prices.extras : order.selected.totalExtraPurchasesPrice) : 0;

        const priceBeforeFees = ticketPrice + extraPurchasePrice;
        const totalFees = !isTicketsPage && priceBeforeFees > 0 ? (showReceiptData ? receipt.prices.fees : order.selected.totalFees) : 0;

        const giftCardAmount = order.selected.giftCardAmount;
        let totalPrice = giftCardAmount ? priceBeforeFees + totalFees - giftCardAmount : priceBeforeFees + totalFees;

        return dispatch({ type: TOTAL_PRICE_CHANGED, totalPrice });
    }
}

export const CALCULATE_POINTS = 'CALCULATE_POINTS'
export function calculatePoints() {
    return (dispatch, getState) => {
        const { order } = getState();
        const available = _.concat(order.availableExtraPurchases, order.availableTickets);
        const selected = _.concat(order.selected.extraPurchases, order.selected.tickets);

        let usedPoints = 0;
        _.forEach(selected, (item) => {
            const existing = _.find(available, (available) => { return available.id == item.id });
            if (existing && existing.points) usedPoints += (existing.points * item.quantity);
        });

        return dispatch({
            type: CALCULATE_POINTS,
            usedPoints,
            remainingPoints: order.clubCard ? order.clubCard.points - usedPoints : 0
        })
    };
}

export const RESET_ORDER = 'RESET_ORDER';
export function resetOrder() {
    return (dispatch, getState) => {
        ticketgate.cancelTransaction(getState().order.selected.transactionId);
        return dispatch({ type: RESET_ORDER });
    }
}

export const ORDER_TYPE_CHANGED = 'ORDER_TYPE_CHANGED';
export const orderTypeChanged = (newType) => {
    return (dispatch, getState) => {
        const fees = getState().organizer.configuration.fees;
        const selected = getState().order.selected;
        const totalFees = selected.totalTicketPrice > 0 ? calculateFees(fees, selected.tickets, newType) : 0;

        return dispatch({
            type: ORDER_TYPE_CHANGED,
            orderType: newType,
            totalFees
        });
    }
}

export function ticketQuantityChanged(showtimeId, transactionId, tickets, vouchers) {
    return (dispatch, getState) => {
        dispatch(blockSeatsRequest());
        let activeVouchers = '';
        _.map(vouchers.active, (voucher) => { return activeVouchers += voucher.barCode + ','; });
        return ticketgate.blockSeats(showtimeId, transactionId, tickets, activeVouchers, 0)
            .then(selected => {
                const canReserve = checkReserveConstraints(getState().organizer.configuration.reservationConstraints, selected.seats);
                dispatch(blockSeatsSuccess(selected, canReserve));
                dispatch(fetchSeatsImage());
                if (getState().order.clubCard) dispatch(calculatePoints());
                return dispatch(calculateTotalPrice());
            }, error => dispatch(blockSeatsFailed(error)));
    };
}

export function vouchersChanged(vouchers) {
    return (dispatch, getState) => {
        dispatch(blockSeatsRequest());

        const { transactionId, tickets } = getState().order.selected;
        const showtime = getState().movielist.selectedShowtime;
        const fees = getState().organizer.configuration.fees;
        return ticketgate.blockSeats(showtime.id, transactionId, tickets, vouchers, 0)
            .then(selected => {
                selected.totalFees = calculateFees(fees, tickets, CONSTANTS.orderTypes.buy);
                const canReserve = checkReserveConstraints(getState().organizer.configuration.reservationConstraints, selected.seats);
                dispatch(blockSeatsSuccess(selected, canReserve));
                return dispatch(calculateTotalPrice());
            }, error => dispatch(blockSeatsFailed(error)));
    };
}

function calculateFees(fees, tickets, orderType) {
    let ticketFee = 0;
    _.forEach(tickets, (ticket) => {
        if (orderType === CONSTANTS.orderTypes.reserve)
            ticketFee += ticket.quantity * fees.reserveTicket;
        else if (orderType === CONSTANTS.orderTypes.buy || orderType === CONSTANTS.orderTypes.buyReservation)
            ticketFee += ticket.quantity * fees.buyTicket;
    });

    const transactionFee = orderType === CONSTANTS.orderTypes.reserve ? fees.reserveTransaction : fees.buyTransaction;
    return ticketFee + transactionFee;
}