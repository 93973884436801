export default {
    timeout: 3000,
    ticketType: {
        quantity: 'quantity',
        voucher: 'voucher'
    },

    pages: {
        order: 'order',
        customer: 'customer',
        dibs: 'dibs',
        receipt: 'receipt'
    },

    orderTypes: {
        reserve: 'reserve',
        buy: 'buy',
        buyReservation: 'buyReservation'
    }    
}